import type { SnakeCasedProperties } from 'type-fest';
import type { User } from 'src/types/proto/auth';
import type { ItemActionType, RevisionFlow } from 'src/types/proto/packages';
import type {
  CreateTransactionCreateFlowRequest,
  EmitTransactionEventsRequest,
  GetTransactionFormTagsRequest,
  GetTransactionItemsRequest,
  GetTransactionOverviewRequest,
  GetTransactionsUsersRequest,
  GetTransactionUsersWithAccessRequest,
  IsCompassTransactionResponse,
  SearchTransactionsPreviewRequest,
  SearchTransactionsPreviewResponse,
  TransactionFormRequest,
  TransactionsChoresRequest,
  TransactionsRequest,
  TransactionsTasksRequest,
  SearchTransactionsRequest,
  GetTransactionsForFileReviewPipelineRequest,
  SetTransactionAddressRequest,
  AddTransactionAddressRequest,
  SetTransactionAddressResponse,
  AddTransactionAddressResponse,
} from 'src/types/proto/services/transaction_public_service';
import type { TransactionFormResponse } from 'src/types/proto/transaction_forms';
import type { TransactionSettings } from 'src/types/proto/transaction_settings';
import type {
  DispatchResponse,
  Intent,
  ItemKind,
  Item,
  ItemList,
  Overview,
  TdvFormTagsResponse,
  Transaction,
  TransactionList,
} from 'src/types/proto/transactions';
import type { DeepPartial, FlattenBy, PartlyPartial } from 'src/types/utils';
import { formatToSnakeCase } from 'src/utils/format-data-object';
import AggregatesApi from './aggregates';

export default class Transactions extends AggregatesApi<
  Transaction,
  Intent,
  DispatchResponse,
  ItemKind,
  ItemList,
  Item
> {
  prefix = 'transactions';

  list(params: TransactionsRequest) {
    return this.get<TransactionList>(`/${this.prefix}`, {
      params,
    });
  }

  getTransUrl(transactionId: string | number) {
    return this.getAggregateUrl(transactionId);
  }

  getTransactionUsersWithAccess(
    transactionId: string,
    params: Partial<Omit<GetTransactionUsersWithAccessRequest, 'transactionId'>>
  ) {
    return this.get<User[]>(`${this.getTransUrl(transactionId)}/users`, {
      params: formatToSnakeCase(params),
    });
  }

  getTransactionSettings(transactionId: string) {
    return this.get<TransactionSettings>(
      `${this.getTransUrl(transactionId)}/settings`
    );
  }

  fetchDispatchResponse(transactionId: string, vers: string) {
    return this.get<DispatchResponse>(
      `${this.getTransUrl(transactionId)}/dispatch_response/${vers}`
    );
  }

  fetchSearchPreview(params: Partial<SearchTransactionsPreviewRequest>) {
    return this.get<Partial<SearchTransactionsPreviewResponse>>(
      '/transactions/search_preview',
      {
        params: formatToSnakeCase(params),
      }
    );
  }

  fetchFindUsers(params: GetTransactionsUsersRequest) {
    return this.get<User[]>('/transactions/find_users', {
      params: formatToSnakeCase(params),
    });
  }

  fetchTeam(teamId: string) {
    return this.get(`/teams/${teamId}/members`);
  }

  fetchTasks(params: Partial<TransactionsTasksRequest>) {
    return this.get<ItemList>('/transactions/tasks', {
      params,
    });
  }

  fetchChores(params: Partial<TransactionsChoresRequest>) {
    return this.get<ItemList>('/transactions/chores', {
      params,
    });
  }

  fetchTasksPreview(params: unknown) {
    return this.get<unknown>('/transactions/tasks/preview', {
      params,
    });
  }

  fetchMultiItems({ transactionId, kind, data }: GetTransactionItemsRequest) {
    return this.post<Item[]>(
      `${this.getTransUrl(transactionId)}/multi`,
      data,
      kind
        ? {
            params: {
              kind,
            },
          }
        : undefined
    );
  }

  searchTransactions = (
    q: string,
    params: Omit<SnakeCasedProperties<SearchTransactionsRequest>, 'q'> // FIXME: Handle case transform inside this function
  ) => {
    return this.get<TransactionList>('/transactions/transactions_search', {
      params: {
        q,
        ...params,
      },
    });
  };

  searchFileReviewPipeline = (
    q: string,
    params: Omit<
      SnakeCasedProperties<GetTransactionsForFileReviewPipelineRequest>,
      'q'
    > // FIXME: Handle case transform inside this function
  ) => {
    return this.get('/transactions/file_review_pipeline_search', {
      params: {
        q,
        ...params,
      },
    });
  };

  fetchDefaultTeam = (transactionId: string, partyId: string) => {
    return this.get<unknown>(
      `/transactions/${transactionId}/fetch_default_team/${partyId}`
    );
  };

  flowsTxnCreate = (
    options: DeepPartial<CreateTransactionCreateFlowRequest>
  ) => {
    return this.post<string>('/transactions/flows/txn_create', options);
  };

  getTransactionForms = ({
    transactionId,
    bppId,
    propertyId,
  }: TransactionFormRequest) => {
    return this.get<TransactionFormResponse>(
      `${this.getTransUrl(transactionId)}/forms`,
      {
        params: formatToSnakeCase({
          bppId,
          propertyId,
        }),
      }
    );
  };

  getTransactionOverview = ({
    transactionId,
  }: GetTransactionOverviewRequest) => {
    return this.get<Overview>(`/transactions/${transactionId}/overview`);
  };

  getFormTags({
    transactionId,
    tdvIds,
  }: PartlyPartial<GetTransactionFormTagsRequest, 'transactionId'>) {
    return this.get<TdvFormTagsResponse>(
      `/transactions/${transactionId}/form_tags`,
      {
        params: {
          tdv_ids: tdvIds?.length ? tdvIds : undefined,
        },
      }
    );
  }

  getTxnPckgActionSetupFlow = (
    transactionId: string,
    txnPckgId: string,
    actionType: ItemActionType,
    isObo = false,
    revision = false
  ) => {
    return this.post<string>(
      `${this.getTransUrl(
        transactionId
      )}/packages/${txnPckgId}/actions/${actionType}/setup`,
      {
        is_obo: Boolean(isObo),
        revision: Boolean(revision),
      }
    );
  };

  runTxnPckgAction = (
    transactionId: string,
    txnPckgId: string,
    actionType: ItemActionType,
    actionData: Record<string, any>,
    email: Record<string, any>,
    detached: boolean,
    isObo: boolean
  ) => {
    return this.post<DispatchResponse>(
      `${this.getTransUrl(
        transactionId
      )}/packages/${txnPckgId}/actions/${actionType}/execute`,
      {
        action_data: actionData,
        email,
        detached: Boolean(detached),
        is_obo: Boolean(isObo),
      }
    );
  };

  reviseTxnPckg = (transactionId: string, txnPckgId: string) => {
    return this.post<RevisionFlow>(
      `${this.getTransUrl(transactionId)}/packages/${txnPckgId}/revise`
    );
  };

  emit = (
    transactionId: EmitTransactionEventsRequest['transactionId'] | null,
    events?: EmitTransactionEventsRequest['events']
  ) => {
    if (!events || !events.length) {
      return null;
    }
    const transId = transactionId || 0;
    return this.post<void>(`${this.getTransUrl(transId)}/emit`, {
      events,
    });
  };

  isCompassTransaction = async (transactionId: string): Promise<boolean> => {
    if (!transactionId) {
      return false;
    }
    const { data } = await this.get<IsCompassTransactionResponse>(
      `${this.getTransUrl(transactionId)}/peek_compass`
    );
    return data.result;
  };

  setAddress = ({
    transactionId,
    backRoute,
    successRoute,
  }: FlattenBy<SetTransactionAddressRequest, 'data'>) =>
    this.post<SetTransactionAddressResponse>(
      `${this.getTransUrl(transactionId)}/set_address`,
      {
        back_route: backRoute ?? undefined,
        success_route: successRoute ?? undefined,
      }
    );

  addAddress = ({
    transactionId,
    backRoute,
    successRoute,
    formIds,
    redirectToProperty,
  }: FlattenBy<AddTransactionAddressRequest, 'data'>) =>
    this.post<AddTransactionAddressResponse>(
      `${this.getTransUrl(transactionId)}/add_address`,
      {
        back_route: backRoute ?? undefined,
        success_route: successRoute ?? undefined,
        form_ids: formIds ?? undefined,
        redirect_to_property: redirectToProperty ?? false,
      }
    );
}
