import { action, computed, makeObservable, observable } from 'mobx';
import api from 'src/api';
import type SharedPacket from 'src/api/public/shared-packet';
import logger from 'src/logger';
import {
  publishSharePacket,
  updateSharePacketAccessStatus,
} from 'src/models/transactions/intents';
import SharePacket from 'src/models/transactions/items/share-packet';
import type Transaction from 'src/models/transactions/transaction';
import type { ForwardSharedPacketRequestBody } from 'src/types/proto/services/packet_public_service';
import type { SharedSharePacketViewDocument } from 'src/types/proto/shared_item_view_models';
import type {
  Activity,
  ItemSharePacketAccessStatus,
} from 'src/types/proto/transactions';
import { getFetch } from 'src/utils/get-fetch';
import getLocalStorage from 'src/utils/local-storage';
import type { AppStore } from './app-store';
import type { UIError } from './ui-store';

const { ACTIVITIES_LIMIT } = window.Glide.CONSTANTS;

/**
 * Store for fetching shared packets + downloading & viewing
 * documents in a shared packet
 */
export default class SharedPacketsStore {
  @observable observedSharePacketId: string | null = null;
  parent: AppStore;
  api: SharedPacket;

  constructor(parent: AppStore) {
    makeObservable(this);
    this.parent = parent;
    this.api = this.parent.api.sharedPacket;
    if (!this.transactions) {
      return;
    }
    this.activities.subscribeActivities((activities: Activity[]) => {
      if (!this.observedSharePacketId) {
        return;
      }
      if (
        activities.some((a) =>
          a.itemIds.includes(this.observedSharePacketId as string)
        )
      ) {
        this.getFetchActivitiesView.fetch(this.observedSharePacketId);
      }
    });
  }

  @computed
  get transactions() {
    return this.parent.transactions;
  }

  @computed
  get activities() {
    return this.parent.activities;
  }

  @getFetch({
    getMemoizeKey: ({ uuid }) => uuid,
  })
  async getFetchPacket({
    uuid,
    activity,
  }: {
    uuid: string;
    activity: boolean;
  }) {
    let data;
    let error;
    try {
      data = (await this.api.getPacket(uuid, activity)).data;
    } catch (err) {
      error =
        (err as UIError).code === api.NOT_FOUND
          ? 'Package not found or was removed.'
          : this.parent.ui.getWentWrongMessage(err);

      if ((err as UIError).code === api.NOT_FOUND) {
        try {
          const reqUuid = getLocalStorage()
            .get('sharedItemAccessMap')
            .findKey((v) => v === uuid)
            .value();
          getLocalStorage().unset(`sharedItemAccessMap.${reqUuid}`).write();
        } catch (localStorageErr) {
          logger.error(localStorageErr);
        }
      }
    }

    try {
      getLocalStorage()
        .defaultsDeep({
          sharedItemAccessMap: {},
        })
        .write();

      if (!error) {
        getLocalStorage()
          .get('sharedItemAccessMap')
          .set(data!.sharePacketUuid, uuid)
          .write();
      }
    } catch (err) {
      logger.error(err);
    }

    if (error) {
      throw new Error(error);
    }
    return data;
  }

  @action
  observeSharePacketActivities = (sharePacketId: string) => {
    this.observedSharePacketId = sharePacketId;
  };

  @getFetch({
    getMemoizeKey: (x) => x,
  })
  async getFetchActivitiesView(sharePacketId: string) {
    return (await this.api.getActivitiesView(sharePacketId)).data;
  }

  fetchDiffPreview = async (sharePacketId: string) => {
    return (await this.api.getDiffPreview(sharePacketId)).data;
  };

  @action.bound
  async fetchDocumentURL(documentId: string, uuid: string) {
    const { data } = await this.api.downloadDocumentURL(documentId, uuid);
    return data ? data.url : '';
  }

  @action.bound
  async forwardSharePacket(uuid: string, data: ForwardSharedPacketRequestBody) {
    const { data: responseData } = await this.api.forwardSharePacket(
      uuid,
      data
    );
    return responseData;
  }

  @action.bound
  async downloadDocs(
    docs: SharedSharePacketViewDocument[],
    outputFilename: string,
    shareItemUuid: string | null,
    numberDocuments: boolean
  ) {
    const { features } = this.parent;
    if (shareItemUuid) {
      await this.api.recordDownloadAllForShareItem(shareItemUuid);
    }
    if (features.docsUseProxyZip && shareItemUuid) {
      await this.api.downloadAll(shareItemUuid, outputFilename, true);
    } else {
      await this.api.fileServiceDownloadZip(
        docs.map((d) => ({
          url: d.url,
          name: d.displayName,
        })),
        outputFilename,
        {
          numberDocuments,
        }
      );
    }
  }

  refreshActivities = (sharePacket: SharePacket) => {
    return Promise.all([
      this.getFetchActivitiesView.fetch(sharePacket.id),
      this.parent.activities.fetchActivities({
        transactionId: sharePacket.transId,
        itemId: sharePacket.id,
        offset: 0,
        limit: ACTIVITIES_LIMIT,
      }),
    ]);
  };

  publishSharePacket = async (
    notify: boolean,
    message: string,
    transaction: Transaction,
    sharePacketId: string
  ) => {
    try {
      await this.transactions.dispatch(
        transaction.id,
        publishSharePacket({
          sharePacketId,
          notify,
          message,
        })
      );
      await this.parent.sharedPackets.getFetchActivitiesView.fetch(
        sharePacketId
      );
    } catch (err) {
      this.parent.ui.wentWrong(err);
    }
  };

  updateAccessStatus = async (
    sharePacket: SharePacket,
    accessStatus: ItemSharePacketAccessStatus
  ) => {
    try {
      await this.transactions.dispatch(
        sharePacket.transId,
        updateSharePacketAccessStatus(sharePacket.id, accessStatus)
      );
      if (accessStatus === 'DISABLED') {
        this.parent.ui.toast({
          type: 'info',
          message: 'Disclosure package sharing has been disabled.',
        });
      } else if (accessStatus === 'ENABLED') {
        this.parent.ui.toast({
          type: 'success',
          message: 'Disclosure package sharing has been enabled.',
        });
      }
    } catch (err) {
      this.parent.ui.wentWrong(err);
    }
  };
}
