/* Generated file, please DO NOT edit directly */

/* See jsapp/proto2ts.sh */

/* eslint-disable */
import type {
  FieldsControlPolicyEnum,
  FieldsMergeStrategyEnum,
} from './aggregates';
import type { Team, User, UserFeatures } from './auth';
import type { StateEnum } from './constants';
import type { Contact, ContactSource } from './contacts';
import type { DateComputation, KeyDate } from './dates';
import type {
  Document,
  FillConfig,
  FormFill,
  FormFlowConfig,
  FormStateEnum,
  SignatureDetectionResultUserFeedback,
} from './documents';
import type { DsDocument } from './docusign';
import type {
  EmailAddress,
  EmailEvent,
  EmailMessage,
  EmailMessageEvent,
} from './emails';
import type { Flow } from './flows';
import type { TransactionOrigin } from './globals';
import type { LinkedAccountKind } from './integrations';
import type { InviteRequest } from './invites';
import type { Label } from './labels';
import type { Org } from './orgs';
import type {
  ItemActionAccessLogEntry,
  ItemActionCounterKind,
  ItemActionType,
  PackageKind,
  PackageOcrStatus,
  PackageStatus,
} from './packages';
import type { PermissionEnum } from './permissions';
import type { Address, MarketingContent } from './properties';
import type { Reminder } from './reminders';
import type { Route, RouteLink } from './routes';

export const protobufPackage = 'transactions';

export interface Transaction {
  id: string;
  vers: string;
  meta?: TransactionMeta;
  fields?: { [key: string]: any };
  uuid: string;
  effectiveFrom: number;
  effectiveTo: number;
  createdAt: number;
  permissions: PermissionEnum[];
}

/**
 * TODO change state names only after addressless is stable
 * it will require backfilling json values on db (not actual proto values since those are unchanged)
 */
export const TransactionState = {
  /** INACTIVE - Unused */
  INACTIVE: 'INACTIVE',
  /** INCOMPLETE - If transaction is missing information required to function */
  INCOMPLETE: 'INCOMPLETE',
  /** PROSPECT - Nurturing */
  PROSPECT: 'PROSPECT',
  /** PREPARING - Listing only: Preparing to List */
  PREPARING: 'PREPARING',
  /** PRE_OFFER - Purchase only: Actively Searching */
  PRE_OFFER: 'PRE_OFFER',
  /** ACTIVE - Listing only: Property Listed */
  ACTIVE: 'ACTIVE',
  /** UNDER_CONTRACT - Under contract */
  UNDER_CONTRACT: 'UNDER_CONTRACT',
  /** CLOSED - The transaction is complete, should be immutable. */
  CLOSED: 'CLOSED',
  /** TEMPLATE - Is designated as a template. */
  TEMPLATE: 'TEMPLATE',
  /** BUYER_PROSPECT - Earlier stage of deal - no property yet. */
  BUYER_PROSPECT: 'BUYER_PROSPECT',
  CANCEL_REQUESTED: 'CANCEL_REQUESTED',
  CANCELLED: 'CANCELLED',
  BUYSIDE_OFFER_PLACEHOLDER: 'BUYSIDE_OFFER_PLACEHOLDER',
} as const;

export type TransactionState =
  typeof TransactionState[keyof typeof TransactionState];

export const TransactionSide = {
  UNKNOWN_SIDE: 'UNKNOWN_SIDE',
  SALE: 'SALE',
  PURCHASE: 'PURCHASE',
} as const;

export type TransactionSide =
  typeof TransactionSide[keyof typeof TransactionSide];

export const TransactionTransType = {
  UNKNOWN_TYPE: 'UNKNOWN_TYPE',
  TYPE_SALE: 'TYPE_SALE',
  TYPE_PURCHASE: 'TYPE_PURCHASE',
  TYPE_SALE_LEASE: 'TYPE_SALE_LEASE',
  TYPE_PURCHASE_LEASE: 'TYPE_PURCHASE_LEASE',
} as const;

export type TransactionTransType =
  typeof TransactionTransType[keyof typeof TransactionTransType];

export const TransactionReviewState = {
  PENDING: 'PENDING',
  NEEDS_REVIEW: 'NEEDS_REVIEW',
  REVIEW_IN_PROGRESS: 'REVIEW_IN_PROGRESS',
  /**
   * OUTSTANDING - OUTSTANDING state is set after at least one file review took palce and there is
   *  no more CLIs available for review nor open file reviews in txn. The maning of
   *  this state is that review is done but there is outstanding work to be done by
   *  listing/buyer team.
   * The only exeption is if all CLIs are in its initial state, in which case the
   *  state is PENDING
   */
  OUTSTANDING: 'OUTSTANDING',
  FULLY_APPROVED: 'FULLY_APPROVED',
} as const;

export type TransactionReviewState =
  typeof TransactionReviewState[keyof typeof TransactionReviewState];

export const TransactionTemplateVisibility = {
  PERSONAL: 'PERSONAL',
  TEAMS: 'TEAMS',
  BROKERAGE: 'BROKERAGE',
} as const;

export type TransactionTemplateVisibility =
  typeof TransactionTemplateVisibility[keyof typeof TransactionTemplateVisibility];

export interface TransactionAppliedTemplate {
  templateId: string;
  templateVers: string;
  userId: string;
  appliedAt: number;
}

export interface TransactionCancellationMeta {
  reason: TransactionCancellationMetaReason;
  details: string;
  prevStatus: TransactionState;
  requestedByUserId: string;
  requestedByUser?: User;
  approvedByUserId: string;
  approvedByUser?: User;
  approvedAt: number;
}

/**
 * For these reasons, make sure to add a corresponding
 * label in the transaction constants file.
 */
export const TransactionCancellationMetaReason = {
  NOT_CANCELLED: 'NOT_CANCELLED',
  LISTING_CANCELLATION: 'LISTING_CANCELLATION',
  BUYER_CONTINGENCY_FAILURE: 'BUYER_CONTINGENCY_FAILURE',
  SELLER_CONTINGENCY_FAILURE: 'SELLER_CONTINGENCY_FAILURE',
  OTHER: 'OTHER',
} as const;

export type TransactionCancellationMetaReason =
  typeof TransactionCancellationMetaReason[keyof typeof TransactionCancellationMetaReason];

export interface TransactionMeta {
  title: string;
  state: TransactionState;
  /** The one who created the transaction */
  creatorId: string;
  creator?: User;
  /** The setup flow that created the transaction */
  setupFlowId: string;
  setupFlow?: Flow;
  addressId: string;
  address?: Address;
  secondaryAddressesIds: string[];
  secondaryAddresses: Address[];
  reState: string;
  archived: boolean;
  propertyInfoId: string;
  propertyInfo?: Item;
  listingInfoId: string;
  listingInfo?: Item;
  purchaseInfoId: string;
  purchaseInfo?: Item;
  timelineId: string;
  timeline?: Item;
  coverPhotoId: string;
  applicableFfcIds: string[];
  partiesIds: string[];
  parties: Item[];
  buyerProspectAddressesIds: string[];
  buyerProspectAddresses: Address[];
  glideFields?: TransactionMetaGlideFields;
  /** Teams associated with transaction */
  OBSOLETEAdminTeamId: string;
  agentTeamId: string;
  agentTeam?: Team;
  emailAddressLocalPart: string;
  emailAddress: string;
  checklistBoardId: string;
  ticketBoardId: string;
  voided: boolean;
  forwardEmailAddresses: string[];
  side: TransactionSide;
  /** The party_id who is counted as doing the transaction for the purpose of invoices */
  ownerId: string;
  owner?: Item;
  zfLink?: TransactionMetaZfLink;
  orgId: string;
  org?: Org;
  officeId: string;
  teamIds: string[];
  queryable: string;
  /** 47-48 reserved */
  discPacketId: string;
  customCoverPhotoUrl: string;
  coverPhotoUrl: string;
  coverPhotoUrlSm: string;
  conversionActions: string[];
  appRegistry?: TransactionMetaAppRegistry;
  reviewState: TransactionReviewState;
  reviewStateUserId: string;
  reviewStateUser?: User;
  reviewStateChangedAt: number;
  pendingCliCount: number;
  templateVisibility: TransactionTemplateVisibility;
  appliedTemplates: TransactionAppliedTemplate[];
  pipelineDate: string;
  cancellationMeta?: TransactionCancellationMeta;
  features?: UserFeatures;
  sourceBuyerProspectId: string;
  isLease: boolean;
  template?: TransactionMetaTemplate;
  lastUpdatedFieldsVers: string;
  lastUpdatedFieldsTs: number;
  apiAppIds: string[];
  addresslessTransaction: boolean;
  transactionOrigin: TransactionOrigin;
  tds: Item[];
  btTeamId: string;
  isReferral: boolean;
  dealId: string;
}

export interface TransactionMetaGlideFields {
  isGlideForm: boolean;
  isTest: boolean;
  isFullService: boolean;
  invoiceId: string;
  invoice?: Item;
  isTms: boolean;
}

export interface TransactionMetaZfLink {
  tokenId: string;
  tokenOwnerId: string;
  txnId: string;
  txnName: string;
  tokenEmail: string;
  createdAt: number;
}

export interface TransactionMetaAppRegistry {
  records: TransactionMetaAppRegistryRecord[];
}

export interface TransactionMetaAppRegistryRecord {
  itemId: string;
  guid: string;
  version: number;
}

export interface TransactionMetaTemplate {
  hasOffer: boolean;
}

/**
 * A contact that is associated with the transaction.
 *
 * `Party` objects may be bound to a `User` via `user_id`. Aside
 * from being bound to a user and contact information, a party
 * also has one or more roles specified by the `Role` enum.
 */
export interface Party {
  state: PartyState;
  /**
   * This is used to store the party's contact information
   * when the party is not bound to a user.
   *
   * Refer to the `contact` computed field if you want to access the
   * correct contact information at any given time.
   */
  partyContact?: Contact;
  /**
   * Contact information associated with the party. Will resolve from
   * the `user` contact field if this party is bound to a `user_id` else
   * returns the `party_contact` field.
   */
  contact?: Contact;
  isPotential: boolean;
  roles: PartyRole[];
  loaded: boolean;
  removed: boolean;
  unbound: boolean;
  /** Docusign OAuth Token account email. */
  dsTokenSub: string;
  /** User ID this party is bound to. */
  userId: string;
  user?: User;
  /** The InviteRequest ID that the party user was invited through. */
  inviteId: string;
  invite?: InviteRequest;
  sellerState?: PartySellerState;
  reAgentState?: PartyReAgentState;
  reAssociateState?: PartyReAssociateState;
  /**
   * Date when a client from a compass transaction
   * has accepted the compass terms of service and data sharing
   */
  compassTosAcceptedAt: number;
  clientVisibility: PartyClientVisibility;
  OBSOLETEUserContactId: string;
  OBSOLETEUserContactSource?: ContactSource;
}

export const PartyState = {
  INACTIVE: 'INACTIVE',
  PENDING: 'PENDING',
  INVITED: 'INVITED',
  DECLINED: 'DECLINED',
  SETUP: 'SETUP',
  ACTIVE: 'ACTIVE',
} as const;

export type PartyState = typeof PartyState[keyof typeof PartyState];

export const PartyRole = {
  SELLER: 'SELLER',
  SELLER2: 'SELLER2',
  SELLER3: 'SELLER3',
  SELLER4: 'SELLER4',
  LISTING_AGENT: 'LISTING_AGENT',
  /** LISTING_AGENT2 - CO_LISTING1 */
  LISTING_AGENT2: 'LISTING_AGENT2',
  LISTING_TC: 'LISTING_TC',
  BUYER_AGENT: 'BUYER_AGENT',
  /** BUYER_AGENT2 - CO_BUYING1 */
  BUYER_AGENT2: 'BUYER_AGENT2',
  BUYER_TC: 'BUYER_TC',
  BUYER: 'BUYER',
  BUYER2: 'BUYER2',
  BUYER3: 'BUYER3',
  BUYER4: 'BUYER4',
  TITLE_AGENT: 'TITLE_AGENT',
  ESCROW_AGENT: 'ESCROW_AGENT',
  LOAN_OFFICER: 'LOAN_OFFICER',
  LOAN_PROCESSOR: 'LOAN_PROCESSOR',
  APP_ADMIN: 'APP_ADMIN',
  ASSOCIATE_LISTING_AGENT: 'ASSOCIATE_LISTING_AGENT',
  ASSOCIATE_BUYER_AGENT: 'ASSOCIATE_BUYER_AGENT',
  LISTING_BROKER: 'LISTING_BROKER',
  BUYER_BROKER: 'BUYER_BROKER',
  BUYER_TEAM: 'BUYER_TEAM',
  LISTING_TEAM: 'LISTING_TEAM',
  TITLE_REPRESENTATIVE: 'TITLE_REPRESENTATIVE',
  FILE_REVIEWER: 'FILE_REVIEWER',
  OTHER: 'OTHER',
  CO_LISTING2: 'CO_LISTING2',
  CO_LISTING3: 'CO_LISTING3',
  CO_LISTING4: 'CO_LISTING4',
  CO_LISTING5: 'CO_LISTING5',
  CO_BUYING2: 'CO_BUYING2',
  CO_BUYING3: 'CO_BUYING3',
  CO_BUYING4: 'CO_BUYING4',
  CO_BUYING5: 'CO_BUYING5',
} as const;

export type PartyRole = typeof PartyRole[keyof typeof PartyRole];

export const PartyClientVisibility = {
  UNKNOWN: 'UNKNOWN',
  VISIBLE: 'VISIBLE',
  HIDDEN: 'HIDDEN',
} as const;

export type PartyClientVisibility =
  typeof PartyClientVisibility[keyof typeof PartyClientVisibility];

export interface PartySellerState {
  isSetup: boolean;
}

export interface PartyReAgentState {
  isSetup: boolean;
}

export interface PartyReAssociateState {
  isSetup: boolean;
}

export interface Intent {
  id: string;
  userId: string;
  origin: string;
  clientId: string;
  async: boolean;
  kind: IntentKind;
  create?: IntentCreate;
  load?: IntentLoad;
  deleteItems?: IntentDeleteItems;
  updateTransactionGlideFields?: IntentUpdateTransactionGlideFields;
  updateTransactionDocumentsArchivalStatus?: IntentUpdateTransactionDocumentsArchivalStatus;
  updateTransactionDocumentsNotes?: IntentUpdateTransactionDocumentsNotes;
  /** Parties 150 - 199 */
  inviteParties?: IntentInviteParties;
  inviteComplete?: IntentInviteComplete;
  updatePartyContactDetails?: IntentUpdatePartyContactDetails;
  addAgentTeamMembers?: IntentAddAgentTeamMembers;
  bindParty?: IntentBindParty;
  replaceUser?: IntentReplaceUser;
  partyPatches?: IntentPartyPatches;
  partyRemoves?: IntentPartyRemoves;
  replacePrimaryAgent?: IntentReplacePrimaryAgent;
  acceptCompassTos?: IntentAcceptCompassTos;
  updatePartyContactSource?: IntentUpdatePartyContactSource;
  /** Properties 2100-2199 */
  propertiesCreate?: IntentPropertiesCreate;
  propertySetAsPrimary?: IntentPropertySetAsPrimary;
  propertiesUpdate?: IntentPropertiesUpdate;
  propertyUpdateFields?: IntentPropertyUpdateFields;
  /** Document 1701-1749 */
  createFolder?: IntentCreateFolder;
  reorderFolders?: IntentReorderFolders;
  seeTransactionDocuments?: IntentSeeTransactionDocuments;
  restoreTransactionDocuments?: IntentRestoreTransactionDocuments;
  renameFolder?: IntentRenameFolder;
  renameTransactionDocuments?: IntentRenameTransactionDocuments;
  assignTransactionDocumentsToFolder?: IntentAssignTransactionDocumentsToFolder;
  stampTdv?: IntentStampTdv;
  folderRenames?: IntentFolderRenames;
  transactionDocumentTrashes?: IntentTransactionDocumentTrashes;
  folderCreates?: IntentFolderCreates;
  transactionDocumentUploads?: IntentTransactionDocumentUploads;
  changeDocumentClientVisibility?: IntentChangeDocumentClientVisibility;
  updateArchivalStatus?: IntentUpdateArchivalStatus;
  updateTransactionState?: IntentUpdateTransactionState;
  updateTransactionMeta?: IntentUpdateTransactionMeta;
  updateTransactionInfo?: IntentUpdateTransactionInfo;
  updateTemplateSetting?: IntentUpdateTemplateSetting;
  createTransactionDocumentVersions?: IntentCreateTransactionDocumentVersions;
  fillTransactionDocumentForm?: IntentFillTransactionDocumentForm;
  importForms?: IntentImportForms;
  autoupdateForms?: IntentAutoupdateForms;
  splitOrMergeTdvs?: IntentSplitOrMergeTDVS;
  annotateTdv?: IntentAnnotateTdv;
  getOrCreateFormOutlineFlow?: IntentGetOrCreateFormOutlineFlow;
  restoreOriginalTds?: IntentRestoreOriginalTds;
  copyTds?: IntentCopyTds;
  cleanTransactionDocuments?: IntentCleanTransactionDocuments;
  /** File Review 1800 - 1899 */
  updateFileReview?: IntentUpdateFileReview;
  finishFileReview?: IntentFinishFileReview;
  cancelFileReview?: IntentCancelFileReview;
  setFullyApprovedReviewState?: IntentSetFullyApprovedReviewState;
  /** TransactionTemplate 1901 - 1949 */
  updateTemplateMetadata?: IntentUpdateTemplateMetadata;
  applyTemplates?: IntentApplyTemplates;
  /** Pead 1950 - 1969 */
  createTodaysPeadFlow?: IntentCreateTodaysPeadFlow;
  createPeadFromFlow?: IntentCreatePeadFromFlow;
  setPeadAddresses?: IntentSetPeadAddresses;
  setPeadEntrants?: IntentSetPeadEntrants;
  setPeadFields?: IntentSetPeadFields;
  storePeadGenerationResults?: IntentStorePeadGenerationResults;
  createPeadEsignFlow?: IntentCreatePeadEsignFlow;
  storePeadTdsReplacements?: IntentStorePeadTdsReplacements;
  migratePeadToListing?: IntentMigratePeadToListing;
  uploadPeadForms?: IntentUploadPeadForms;
  transferPeadRequestsFromSourceProspect?: IntentTransferPeadRequestsFromSourceProspect;
  /** Fields 1760 - 1799 */
  setTransactionFields?: IntentSetTransactionFields;
  updateTransactionFields?: IntentUpdateTransactionFields;
  /** Tasks 300-400 */
  createTasksFromTemplate?: IntentCreateTasksFromTemplate;
  reorderTasks?: IntentReorderTasks;
  updateTaskMeta?: IntentUpdateTaskMeta;
  createTasks?: IntentCreateTasks;
  addCalendarAttendees?: IntentAddCalendarAttendees;
  editReminder?: IntentEditReminder;
  sendReminder?: IntentSendReminder;
  completeTask?: IntentCompleteTask;
  skipTask?: IntentSkipTask;
  toggleChecklists?: IntentToggleChecklists;
  checklistItemUpdateIsExempt?: IntentChecklistItemUpdateIsExempt;
  copyChecklistTemplates?: IntentCopyChecklistTemplates;
  checklistItemUpdateRequired?: IntentChecklistItemUpdateRequired;
  createCommsDocument?: IntentCreateCommsDocument;
  /** Task actions 400-600 */
  updateTdTaskAssignments?: IntentUpdateTdTaskAssignments;
  updateTaskAssignedParty?: IntentUpdateTaskAssignedParty;
  mailTds?: IntentMailTds;
  importInboxAttachments?: IntentImportInboxAttachments;
  /** GFP 700-800 */
  createGuidedFormPacket?: IntentCreateGuidedFormPacket;
  getOrCreateOrderNhdFlow?: IntentGetOrCreateOrderNhdFlow;
  declineNhdOrder?: IntentDeclineNhdOrder;
  createNhdOrder?: IntentCreateNhdOrder;
  completeNhdOrder?: IntentCompleteNhdOrder;
  checkNhdReportOutput?: IntentCheckNhdReportOutput;
  createPickNhdProviderFlow?: IntentCreatePickNhdProviderFlow;
  updateNhdOrderStatus?: IntentUpdateNhdOrderStatus;
  cancelNhdOrder?: IntentCancelNhdOrder;
  revertNhdOrderedAt?: IntentRevertNhdOrderedAt;
  resetNhdOrder?: IntentResetNhdOrder;
  createGuidedFormPreview?: IntentCreateGuidedFormPreview;
  createGfpCreationFlow?: IntentCreateGfpCreationFlow;
  resendGfpInvite?: IntentResendGfpInvite;
  updateGfpRecipients?: IntentUpdateGfpRecipients;
  updatePacketCoverSheet?: IntentUpdatePacketCoverSheet;
  updateGuidedFormPacketFlowStatus?: IntentUpdateGuidedFormPacketFlowStatus;
  updateGuidedFormPacketStatus?: IntentUpdateGuidedFormPacketStatus;
  gfpGenerate?: IntentGfpGenerate;
  startReviewGfp?: IntentStartReviewGfp;
  gfpCheckAndRemind?: IntentGfpCheckAndRemind;
  sendGfpNotStartedReminder?: IntentSendGFPNotStartedReminder;
  previewFlowPdf?: IntentPreviewFlowPdf;
  gfpSignAgreement?: IntentGfpSignAgreement;
  gfpStartTutorial?: IntentGfpStartTutorial;
  gfpSetLocked?: IntentGfpSetLocked;
  gfpSubmit?: IntentGfpSubmit;
  gfpStartGenerateFlow?: IntentGfpStartGenerateFlow;
  gfpRecordComment?: IntentGfpRecordComment;
  gfpRemoveGuidedFormPacketFlow?: IntentGfpRemoveGuidedFormPacketFlow;
  gfpCreateAddFormsFlow?: IntentGfpCreateAddFormsFlow;
  gfpAddForms?: IntentGfpAddForms;
  gfpAccessInPerson?: IntentGfpAccessInPerson;
  gfpUploadFlowAttachments?: IntentGfpUploadFlowAttachments;
  gfpGetSubmitFlow?: IntentGfpGetSubmitFlow;
  gfpSendFlagNotification?: IntentGfpSendFlagNotification;
  updateDppOrder?: IntentUpdateDppOrder;
  gfpPremiumUpgrade?: IntentGfpPremiumUpgrade;
  gfpCompleteSurvey?: IntentGfpCompleteSurvey;
  expireDppOrder?: IntentExpireDppOrder;
  gfpPremiumLateBillingCharge?: IntentGfpPremiumLateBillingCharge;
  setGfpfAsReviewed?: IntentSetGfpfAsReviewed;
  setGfpfPreviewPdf?: IntentSetGfpfPreviewPdf;
  updateGfpfFlow?: IntentUpdateGfpfFlow;
  getOrCreateFormBuilderFlow?: IntentGetOrCreateFormBuilderFlow;
  gfpSkipAgreement?: IntentGfpSkipAgreement;
  /** SHARE PACKETS 900-950 */
  createSharePacket?: IntentCreateSharePacket;
  requestSharePacketAccess?: IntentRequestSharePacketAccess;
  inviteToSharePacket?: IntentInviteToSharePacket;
  updateSharePacketLinkShareMode?: IntentUpdateSharePacketLinkShareMode;
  generateCoverSheet?: IntentGenerateCoverSheet;
  sendSharePacketMessage?: IntentSendSharePacketMessage;
  updateSharePacketAccessStatus?: IntentUpdateSharePacketAccessStatus;
  /** SHARED ITEM and SHARED ITEM REQUESTS 950 - 1049 */
  rejectSharedItemRequest?: IntentRejectSharedItemRequest;
  approveSharedItemRequest?: IntentApproveSharedItemRequest;
  revokeSharedItemAccess?: IntentRevokeSharedItemAccess;
  updateSharePacketPublication?: IntentUpdateSharePacketPublication;
  claimSharedItem?: IntentClaimSharedItem;
  /** Labels 800-850 */
  createLabels?: IntentCreateLabels;
  updateLabels?: IntentUpdateLabels;
  applyLabels?: IntentApplyLabels;
  /** Signature Requests 850-899 */
  createSignatureRequest?: IntentCreateSignatureRequest;
  updateSignatureRequest?: IntentUpdateSignatureRequest;
  updateSignatureRequestsArchivalStatus?: IntentUpdateSignatureRequestsArchivalStatus;
  refreshSignatureRequest?: IntentRefreshSignatureRequest;
  refreshSignatureRequestRecipientStatus?: IntentRefreshSignatureRequestRecipientStatus;
  createSignatureRequestFlow?: IntentCreateSignatureRequestFlow;
  voidSignatureRequest?: IntentVoidSignatureRequest;
  resendSignatureRequest?: IntentResendSignatureRequest;
  createOrUpdatePreparingSignatureRequest?: IntentCreateOrUpdatePreparingSignatureRequest;
  duplicateSignatureRequest?: IntentDuplicateSignatureRequest;
  signatureRequestStartRevision?: IntentSignatureRequestStartRevision;
  signatureRequestCancelRevision?: IntentSignatureRequestCancelRevision;
  reviseSignatureRequest?: IntentReviseSignatureRequest;
  /** Timeline 1050 - 1055 */
  updateTimelineTeam?: IntentUpdateTimelineTeam;
  updateTimelineLogo?: IntentOBSOLETEUpdateTimelineLogo;
  updateTimelineSetup?: IntentUpdateTimelineSetup;
  finalizeTimelineSetup?: IntentFinalizeTimelineSetup;
  getOrCreateTimelineSetupFlow?: IntentGetOrCreateTimelineSetupFlow;
  addTimelineDocuments?: IntentAddTimelineDocuments;
  updateTimelineDate?: IntentUpdateTimelineDate;
  sendTimelineMessages?: IntentSendTimelineMessages;
  splitTimelineDocuments?: IntentSplitTimelineDocuments;
  removeTimelineDate?: IntentRemoveTimelineDate;
  detectTimelineForms?: IntentDetectTimelineForms;
  removeTimelineDocuments?: IntentRemoveTimelineDocuments;
  updateKeyDateCompletionStatus?: IntentUpdateKeyDateCompletionStatus;
  sendKeyDateReminder?: IntentSendKeyDateReminders;
  syncTimelineDates?: IntentSyncTimelineDates;
  /** Offers 1056 - 1099 */
  getOrCreateOffer?: IntentGetOrCreateOffer;
  initializePurchaseSideOffer?: IntentInitializePurchaseSideOffer;
  setOfferReadyToSubmit?: IntentSetOfferReadyToSubmit;
  updateOfferArchivalStatus?: IntentUpdateOfferArchivalStatus;
  updateOfferFavoriteStatus?: IntentUpdateOfferFavoriteStatus;
  setOfferNotes?: IntentSetOfferNotes;
  setOffersSortIndices?: IntentSetOffersSortIndices;
  updateOfferSubmissionPreferences?: IntentUpdateOfferSubmissionPreferences;
  setClientOffersSeen?: IntentSetClientOffersSeen;
  detectPackageForms?: IntentDetectPackageForms;
  updateOffersClientWorkspaceConfig?: IntentUpdateOffersClientWorkspaceConfig;
  updatePackageOcrStatus?: IntentUpdatePackageOcrStatus;
  updateOfferSubmittedDate?: IntentUpdateOfferSubmittedDate;
  updateOfferKeyTermsConfig?: ItemTransactionAppOffersKeyTermsConfig;
  updateBuySideKeyTermMeta?: IntentUpdateBuySideKeyTermMeta;
  /** Sent emails tracking */
  recordEmailEvents?: IntentRecordEmailEvents;
  /** Invoice 1250 - 1299 */
  createInvoice?: IntentCreateInvoice;
  updateInvoice?: IntentUpdateInvoice;
  /** System / Misc 2000-2100 */
  backfill?: IntentBackfill;
  backfillScript?: IntentBackfillScript;
  voidTransaction?: IntentVoidTransaction;
  unvoidTransaction?: IntentUnvoidTransaction;
  addActivity?: IntentAddActivity;
  recordComment?: IntentRecordComment;
  initializeBrokerageSettings?: IntentInitializeBrokerageSettings;
  sendEmail?: IntentSendEmail;
  /** Core transaction */
  updateCoverPhoto?: IntentUpdateCoverPhoto;
  updateOrgDetails?: IntentUpdateOrgDetails;
  createLinkZfFlow?: IntentCreateLinkZfFlow;
  clearOrgDetails?: IntentClearOrgDetails;
  /** Flows 1401 - 1500 */
  createScheduleMeetingFlow?: IntentCreateScheduleMeetingFlow;
  /** AVID 1501 - 1550 */
  completeAvid?: IntentCompleteAVID;
  inviteToAvid?: IntentInviteToAvid;
  updateAvidStage?: IntentUpdateAvidStage;
  getOrCreateInitialAvidFlow?: IntentGetOrCreateInitialAVIDFlow;
  createAvidGenerationFlow?: IntentCreateAvidGenerationFlow;
  createAvidFillFlows?: IntentCreateAvidFillFlows;
  inviteAgentToCompleteAvid?: IntentInviteAgentToCompleteAvid;
  createUpdateAvidFillPartyFlow?: IntentCreateUpdateAvidFillPartyFlow;
  /** MISC 1551 - 1560 */
  getOrCreateZfImportDocFlow?: IntentGetOrCreateZfImportDocFlow;
  /** Integrations 1601-1649 */
  setZfLink?: IntentSetZfLink;
  /** Seller Disclosures App 1751-1779 */
  sellerDiscAppSetCurrStep?: IntentSellerDiscAppSetCurrStep;
  sellerDiscAppCreateWfSf?: IntentSellerDiscAppCreateWfSf;
  /** Chores 1970-1989 & 1900 */
  startChore?: IntentStartChore;
  hideChore?: IntentHideChore;
  /** Packages 2071-2150 */
  syncPackageDocuments?: IntentSyncPackageDocuments;
  linkTransactionPackageToPackage?: IntentLinkTransactionPackageToPackage;
  syncPackageAction?: IntentSyncPackageAction;
  startPackageActionFlow?: IntentStartPackageActionFlow;
  createPackagePendingAction?: IntentCreatePackagePendingAction;
  cancelPackagePendingAction?: IntentCancelPackagePendingAction;
  setPackageSetupType?: IntentSetPackageSetupType;
  setPackageCounterKind?: IntentSetPackageCounterKind;
  registerPackageAccessActivity?: IntentRegisterPackageAccessActivity;
  recordPackageAccessed?: IntentRecordPackageAccessed;
  createPackagePendingTds?: IntentCreatePackagePendingTds;
  executePendingAction?: IntentExecutePendingAction;
  updateSubmittedActionData?: IntentUpdateSubmittedActionData;
  emitTransactionPackageEvent?: IntentEmitTransactionPackageEvent;
  voidOfferPackage?: IntentVoidOfferPackage;
  /** Buyer Prospect 2151-2200 */
  createBuyerProspectProperty?: IntentCreateBuyerProspectProperty;
  updateBuyerProspectPropertyFields?: IntentUpdateBuyerProspectPropertyFields;
  /** Property Info Update 2201-2205 */
  uploadPropertyImage?: IntentUploadPropertyImage;
  movePropertyImagesToStorage?: IntentMovePropertyImagesToStorage;
  /** Signature detection 2206-2250 */
  esignDetectionProvideUserFeedback?: IntentEsignDetectionProvideUserFeedback;
  /** OBSOLETED */
  createDiscPackageSuperFlow?: IntentOBSOLETECreateDiscPackageSuperFlow;
  createInviteToAvidFlow?: IntentOBSOLETECreateInviteToAVIDFlow;
  gfpTermsAccepted?: IntentOBSOLETEGfpTermsAccepted;
  prepareTimelineSetup?: IntentOBSOLETEPrepareTimelineSetup;
  completeTimelineSetup?: IntentOBSOLETECompleteTimelineSetup;
  ObsoleteUpdateTransactionDocumentsExemptStatus?: IntentOBSOLETEUpdateTransactionDocumentsExemptStatus;
  ObsoleteDeleteFolders?: IntentOBSOLETEDeleteFolders;
  ObsoleteSubmitGuidedFormPacketFlow?: IntentOBSOLETESubmitGuidedFormPacketFlow;
  ObsoleteStartGuidedFormPacketFlow?: IntentOBSOLETEStartGuidedFormPacketFlow;
  ObsoleteCompleteGuidedFormPacketFlow?: IntentOBSOLETECompleteGuidedFormPacketFlow;
  ObsoleteUpdateGfpPdf?: IntentOBSOLETEUpdateGfpPdf;
  initializeTimelineDates?: IntentOBSOLETEInitializeTimelineDates;
  updateBrokerSyncAccount?: IntentOBSOLETEUpdateBrokerSyncAccount;
  createImportChecklistFlow?: IntentOBSOLETECreateImportChecklistFlow;
  finishImportChecklistFlow?: IntentOBSOLETEFinishImportChecklistFlow;
  reconcileSyncChecklist?: IntentOBSOLETEReconcileSyncChecklist;
  fetchBrokerTransactionList?: IntentOBSOLETEFetchBrokerTransactionList;
  fetchBrokerChecklist?: IntentOBSOLETEFetchBrokerChecklist;
  processTransactionListPullResponse?: IntentOBSOLETEProcessTransactionListPullResponse;
  processChecklistPullResponse?: IntentOBSOLETEProcessChecklistPullResponse;
  processChecklistPushResponse?: IntentOBSOLETEProcessChecklistPushResponse;
  mergeTransaction?: IntentOBSOLETEMergeTransaction;
  publishSharePacket?: IntentPublishSharePacket;
  createTimelineTask?: IntentOBSOLETECreateTimelineTask;
  updateTdTaskExemptStatus?: IntentOBSOLETEUpdateTdTaskExemptStatus;
  /** Teams 1300 - 1350 */
  assignTeam?: IntentOBSOLETEAssignTeam;
  setFieldValue?: IntentOBSOLETESetFieldValue;
  emailEvent?: IntentOBSOLETEEmailEvent;
  resendSharePacketInvite?: IntentOBSOLETEResendSharePacketInvite;
  updateTransactionSettings?: IntentOBSOLETEUpdateTransactionSettings;
  updateOfferStatus?: IntentOBSOLETEUpdateOfferStatus;
  syncOffersToListing?: IntentOBSOLETESyncOffersToListing;
  toggleOffersClientWorkspace?: IntentOBSOLETEToggleOffersClientWorkspace;
  syncOfferFlow?: IntentOBSOLETESyncOfferFlow;
  syncOfferDocuments?: IntentOBSOLETESyncOfferDocuments;
  updatePacketsArchivalStatus?: IntentOBSOLETEUpdatePacketsArchivalStatus;
  getOrCreateOfferFlow?: IntentOBSOLETEGetOrCreateOfferFlow;
  removeParty?: IntentOBSOLETERemoveParty;
  invitePartyContactToTransaction?: IntentOBSOLETEInvitePartyContactToTransaction;
  getOrCreateCommissionAdvanceFlow?: IntentOBSOLETEGetOrCreateCommissionAdvanceFlow;
  createdAt: number;
}

export const IntentKind = {
  CREATE: 'CREATE',
  LOAD: 'LOAD',
  UPDATE_ARCHIVAL_STATUS: 'UPDATE_ARCHIVAL_STATUS',
  UPDATE_TRANSACTION_STATE: 'UPDATE_TRANSACTION_STATE',
  DELETE_ITEMS: 'DELETE_ITEMS',
  /** INVITE_PARTIES - Parties 9000-9099 */
  INVITE_PARTIES: 'INVITE_PARTIES',
  INVITE_COMPLETE: 'INVITE_COMPLETE',
  UPDATE_PARTY_CONTACT_DETAILS: 'UPDATE_PARTY_CONTACT_DETAILS',
  ADD_AGENT_TEAM_MEMBERS: 'ADD_AGENT_TEAM_MEMBERS',
  REPLACE_USER: 'REPLACE_USER',
  BIND_PARTY: 'BIND_PARTY',
  PARTY_PATCHES: 'PARTY_PATCHES',
  PARTY_REMOVES: 'PARTY_REMOVES',
  REPLACE_PRIMARY_AGENT: 'REPLACE_PRIMARY_AGENT',
  ACCEPT_COMPASS_TOS: 'ACCEPT_COMPASS_TOS',
  UPDATE_PARTY_CONTACT_SOURCE: 'UPDATE_PARTY_CONTACT_SOURCE',
  /** UPDATE_TRANSACTION_META - Transaction Meta */
  UPDATE_TRANSACTION_META: 'UPDATE_TRANSACTION_META',
  UPDATE_TRANSACTION_INFO: 'UPDATE_TRANSACTION_INFO',
  UPDATE_TEMPLATE_SETTING: 'UPDATE_TEMPLATE_SETTING',
  CREATE_TRANSACTION_DOCUMENT_VERSIONS: 'CREATE_TRANSACTION_DOCUMENT_VERSIONS',
  UPDATE_TRANSACTION_GLIDE_FIELDS: 'UPDATE_TRANSACTION_GLIDE_FIELDS',
  /** PROPERTIES_CREATE - Properties 6400-6499 */
  PROPERTIES_CREATE: 'PROPERTIES_CREATE',
  PROPERTY_SET_AS_PRIMARY: 'PROPERTY_SET_AS_PRIMARY',
  PROPERTIES_UPDATE: 'PROPERTIES_UPDATE',
  PROPERTY_UPDATE_FIELDS: 'PROPERTY_UPDATE_FIELDS',
  /** CREATE_TASKS_FROM_TEMPLATE - TASKS 100-400 */
  CREATE_TASKS_FROM_TEMPLATE: 'CREATE_TASKS_FROM_TEMPLATE',
  REORDER_TASKS: 'REORDER_TASKS',
  UPDATE_TASK_META: 'UPDATE_TASK_META',
  CREATE_TASKS: 'CREATE_TASKS',
  UPDATE_TD_TASK_ASSIGNMENTS: 'UPDATE_TD_TASK_ASSIGNMENTS',
  UPDATE_TASK_ASSIGNED_PARTY: 'UPDATE_TASK_ASSIGNED_PARTY',
  ADD_CALENDAR_ATTENDEES: 'ADD_CALENDAR_ATTENDEES',
  EDIT_REMINDER: 'EDIT_REMINDER',
  SEND_REMINDER: 'SEND_REMINDER',
  COMPLETE_TASK: 'COMPLETE_TASK',
  SKIP_TASK: 'SKIP_TASK',
  /** TOGGLE_CHECKLISTS - -- Checklists 209 - 300 */
  TOGGLE_CHECKLISTS: 'TOGGLE_CHECKLISTS',
  CHECKLIST_ITEM_UPDATE_IS_EXEMPT: 'CHECKLIST_ITEM_UPDATE_IS_EXEMPT',
  COPY_CHECKLIST_TEMPLATES: 'COPY_CHECKLIST_TEMPLATES',
  CHECKLIST_ITEM_UPDATE_REQUIRED: 'CHECKLIST_ITEM_UPDATE_REQUIRED',
  /** GET_OR_CREATE_ORDER_NHD_FLOW - NHD 401-450 */
  GET_OR_CREATE_ORDER_NHD_FLOW: 'GET_OR_CREATE_ORDER_NHD_FLOW',
  DECLINE_NHD_ORDER: 'DECLINE_NHD_ORDER',
  CREATE_NHD_ORDER: 'CREATE_NHD_ORDER',
  COMPLETE_NHD_ORDER: 'COMPLETE_NHD_ORDER',
  CHECK_NHD_REPORT_OUTPUT: 'CHECK_NHD_REPORT_OUTPUT',
  CREATE_PICK_NHD_PROVIDER_FLOW: 'CREATE_PICK_NHD_PROVIDER_FLOW',
  UPDATE_NHD_ORDER_STATUS: 'UPDATE_NHD_ORDER_STATUS',
  CANCEL_NHD_ORDER: 'CANCEL_NHD_ORDER',
  REVERT_NHD_ORDERED_AT: 'REVERT_NHD_ORDERED_AT',
  RESET_NHD_ORDER: 'RESET_NHD_ORDER',
  /** CREATE_GUIDED_FORM_PACKET - GFP 500-600 */
  CREATE_GUIDED_FORM_PACKET: 'CREATE_GUIDED_FORM_PACKET',
  CREATE_GUIDED_FORM_PREVIEW: 'CREATE_GUIDED_FORM_PREVIEW',
  CREATE_GFP_CREATION_FLOW: 'CREATE_GFP_CREATION_FLOW',
  RESEND_GFP_INVITE: 'RESEND_GFP_INVITE',
  UPDATE_GFP_RECIPIENTS: 'UPDATE_GFP_RECIPIENTS',
  UPDATE_GUIDED_FORM_PACKET_STATUS: 'UPDATE_GUIDED_FORM_PACKET_STATUS',
  UPDATE_GUIDED_FORM_PACKET_FLOW_STATUS:
    'UPDATE_GUIDED_FORM_PACKET_FLOW_STATUS',
  GFP_GENERATE: 'GFP_GENERATE',
  START_REVIEW_GFP: 'START_REVIEW_GFP',
  GFP_CHECK_AND_REMIND: 'GFP_CHECK_AND_REMIND',
  SEND_GFP_NOT_STARTED_REMINDER: 'SEND_GFP_NOT_STARTED_REMINDER',
  PREVIEW_FLOW_PDF: 'PREVIEW_FLOW_PDF',
  GFP_SIGN_AGREEMENT: 'GFP_SIGN_AGREEMENT',
  GFP_START_TUTORIAL: 'GFP_START_TUTORIAL',
  GFP_SET_LOCKED: 'GFP_SET_LOCKED',
  GFP_SUBMIT: 'GFP_SUBMIT',
  GFP_START_GENERATE_FLOW: 'GFP_START_GENERATE_FLOW',
  GFP_RECORD_COMMENT: 'GFP_RECORD_COMMENT',
  GFP_REMOVE_GUIDED_FORM_PACKET_FLOW: 'GFP_REMOVE_GUIDED_FORM_PACKET_FLOW',
  GFP_CREATE_ADD_FORMS_FLOW: 'GFP_CREATE_ADD_FORMS_FLOW',
  GFP_ADD_FORMS: 'GFP_ADD_FORMS',
  GFP_ACCESS_IN_PERSON: 'GFP_ACCESS_IN_PERSON',
  GFP_UPLOAD_FLOW_ATTACHMENTS: 'GFP_UPLOAD_FLOW_ATTACHMENTS',
  GFP_GET_SUBMIT_FLOW: 'GFP_GET_SUBMIT_FLOW',
  GFP_SEND_FLAG_NOTIFICATION: 'GFP_SEND_FLAG_NOTIFICATION',
  UPDATE_DPP_ORDER: 'UPDATE_DPP_ORDER',
  GFP_PREMIUM_UPGRADE: 'GFP_PREMIUM_UPGRADE',
  GFP_COMPLETE_SURVEY: 'GFP_COMPLETE_SURVEY',
  EXPIRE_DPP_ORDER: 'EXPIRE_DPP_ORDER',
  GFP_PREMIUM_LATE_BILLING_CHARGE: 'GFP_PREMIUM_LATE_BILLING_CHARGE',
  SET_GFPF_AS_REVIEWED: 'SET_GFPF_AS_REVIEWED',
  SET_GFPF_PREVIEW_PDF: 'SET_GFPF_PREVIEW_PDF',
  UPDATE_GFPF_FLOW: 'UPDATE_GFPF_FLOW',
  GET_OR_CREATE_FORM_BUILDER_FLOW: 'GET_OR_CREATE_FORM_BUILDER_FLOW',
  GFP_SKIP_AGREEMENT: 'GFP_SKIP_AGREEMENT',
  /** CREATE_SHARE_PACKET - SHARE PACKETS 900 - 950 */
  CREATE_SHARE_PACKET: 'CREATE_SHARE_PACKET',
  REQUEST_SHARE_PACKET_ACCESS: 'REQUEST_SHARE_PACKET_ACCESS',
  INVITE_TO_SHARE_PACKET: 'INVITE_TO_SHARE_PACKET',
  PUBLISH_SHARE_PACKET: 'PUBLISH_SHARE_PACKET',
  UPDATE_PACKET_COVER_SHEET: 'UPDATE_PACKET_COVER_SHEET',
  UPDATE_SHARE_PACKET_LINK_SHARE_MODE: 'UPDATE_SHARE_PACKET_LINK_SHARE_MODE',
  GENERATE_COVER_SHEET: 'GENERATE_COVER_SHEET',
  _OBSOLETE_RESEND_SHARE_PACKET_INVITE: '_OBSOLETE_RESEND_SHARE_PACKET_INVITE',
  SEND_SHARE_PACKET_MESSAGE: 'SEND_SHARE_PACKET_MESSAGE',
  UPDATE_SHARE_PACKET_ACCESS_STATUS: 'UPDATE_SHARE_PACKET_ACCESS_STATUS',
  /** CREATE_LABELS - LABEL 600-650 */
  CREATE_LABELS: 'CREATE_LABELS',
  UPDATE_LABELS: 'UPDATE_LABELS',
  APPLY_LABELS: 'APPLY_LABELS',
  /** RESTORE_TRANSACTION_DOCUMENT - DOCS & FOLDERS 650-900 */
  RESTORE_TRANSACTION_DOCUMENT: 'RESTORE_TRANSACTION_DOCUMENT',
  UPDATE_TRANSACTION_DOCUMENTS_ARCHIVAL_STATUS:
    'UPDATE_TRANSACTION_DOCUMENTS_ARCHIVAL_STATUS',
  UPDATE_TRANSACTION_DOCUMENTS_NOTES: 'UPDATE_TRANSACTION_DOCUMENTS_NOTES',
  CREATE_FOLDER: 'CREATE_FOLDER',
  RENAME_FOLDER: 'RENAME_FOLDER',
  RENAME_TRANSACTION_DOCUMENTS: 'RENAME_TRANSACTION_DOCUMENTS',
  ASSIGN_TRANSACTION_DOCUMENTS_TO_FOLDER:
    'ASSIGN_TRANSACTION_DOCUMENTS_TO_FOLDER',
  REORDER_FOLDERS: 'REORDER_FOLDERS',
  SEE_TRANSACTION_DOCUMENTS: 'SEE_TRANSACTION_DOCUMENTS',
  RESTORE_TRANSACTION_DOCUMENTS: 'RESTORE_TRANSACTION_DOCUMENTS',
  MAIL_TDS: 'MAIL_TDS',
  IMPORT_INBOX_ATTACHMENTS: 'IMPORT_INBOX_ATTACHMENTS',
  STAMP_TDV: 'STAMP_TDV',
  UPLOAD_TRANSACTION_DOCUMENTS_FROM_FORMS_LIBRARY:
    'UPLOAD_TRANSACTION_DOCUMENTS_FROM_FORMS_LIBRARY',
  SPLIT_OR_MERGE_TDVS: 'SPLIT_OR_MERGE_TDVS',
  ANNOTATE_TDV: 'ANNOTATE_TDV',
  GET_OR_CREATE_FORM_OUTLINE_FLOW: 'GET_OR_CREATE_FORM_OUTLINE_FLOW',
  RESTORE_ORIGINAL_TDS: 'RESTORE_ORIGINAL_TDS',
  COPY_TDS: 'COPY_TDS',
  FOLDER_RENAMES: 'FOLDER_RENAMES',
  TRANSACTION_DOCUMENT_TRASHES: 'TRANSACTION_DOCUMENT_TRASHES',
  FOLDER_CREATES: 'FOLDER_CREATES',
  TRANSACTION_DOCUMENT_UPLOADS: 'TRANSACTION_DOCUMENT_UPLOADS',
  CHANGE_DOCUMENT_CLIENT_VISIBILITY: 'CHANGE_DOCUMENT_CLIENT_VISIBILITY',
  /** _OBSOLETE_SET_FIELD_VALUE - Fields 920-949 */
  _OBSOLETE_SET_FIELD_VALUE: '_OBSOLETE_SET_FIELD_VALUE',
  SET_TRANSACTION_FIELDS: 'SET_TRANSACTION_FIELDS',
  UPDATE_TRANSACTION_FIELDS: 'UPDATE_TRANSACTION_FIELDS',
  /** REJECT_SHARED_ITEM_REQUEST - Shared Item and Shared Item Requests 950-1050 */
  REJECT_SHARED_ITEM_REQUEST: 'REJECT_SHARED_ITEM_REQUEST',
  APPROVE_SHARED_ITEM_REQUEST: 'APPROVE_SHARED_ITEM_REQUEST',
  REVOKE_SHARED_ITEM_ACCESS: 'REVOKE_SHARED_ITEM_ACCESS',
  UPDATE_SHARE_PACKET_PUBLICATION: 'UPDATE_SHARE_PACKET_PUBLICATION',
  CLAIM_SHARED_ITEM: 'CLAIM_SHARED_ITEM',
  /** CREATE_SIGNATURE_REQUEST - Signature Requests 1050-1099 */
  CREATE_SIGNATURE_REQUEST: 'CREATE_SIGNATURE_REQUEST',
  UPDATE_SIGNATURE_REQUEST: 'UPDATE_SIGNATURE_REQUEST',
  UPDATE_SIGNATURE_REQUESTS_ARCHIVAL_STATUS:
    'UPDATE_SIGNATURE_REQUESTS_ARCHIVAL_STATUS',
  REFRESH_SIGNATURE_REQUEST: 'REFRESH_SIGNATURE_REQUEST',
  REFRESH_SIGNATURE_REQUEST_RECIPIENT_STATUS:
    'REFRESH_SIGNATURE_REQUEST_RECIPIENT_STATUS',
  CREATE_SIGNATURE_REQUEST_FLOW: 'CREATE_SIGNATURE_REQUEST_FLOW',
  VOID_SIGNATURE_REQUEST: 'VOID_SIGNATURE_REQUEST',
  RESEND_SIGNATURE_REQUEST: 'RESEND_SIGNATURE_REQUEST',
  CREATE_OR_UPDATE_PREPARING_SIGNATURE_REQUEST:
    'CREATE_OR_UPDATE_PREPARING_SIGNATURE_REQUEST',
  DUPLICATE_SIGNATURE_REQUEST: 'DUPLICATE_SIGNATURE_REQUEST',
  SIGNATURE_REQUEST_START_REVISION: 'SIGNATURE_REQUEST_START_REVISION',
  SIGNATURE_REQUEST_CANCEL_REVISION: 'SIGNATURE_REQUEST_CANCEL_REVISION',
  REVISE_SIGNATURE_REQUEST: 'REVISE_SIGNATURE_REQUEST',
  UPDATE_TIMELINE_TEAM: 'UPDATE_TIMELINE_TEAM',
  UPDATE_TIMELINE_SETUP: 'UPDATE_TIMELINE_SETUP',
  CREATE_TIMELINE_TASK: 'CREATE_TIMELINE_TASK',
  FINALIZE_TIMELINE_SETUP: 'FINALIZE_TIMELINE_SETUP',
  GET_OR_CREATE_TIMELINE_SETUP_FLOW: 'GET_OR_CREATE_TIMELINE_SETUP_FLOW',
  ADD_TIMELINE_DOCUMENTS: 'ADD_TIMELINE_DOCUMENTS',
  UPDATE_TIMELINE_DATE: 'UPDATE_TIMELINE_DATE',
  SEND_TIMELINE_MESSAGES: 'SEND_TIMELINE_MESSAGES',
  SPLIT_TIMELINE_DOCUMENTS: 'SPLIT_TIMELINE_DOCUMENTS',
  REMOVE_TIMELINE_DATE: 'REMOVE_TIMELINE_DATE',
  DETECT_TIMELINE_FORMS: 'DETECT_TIMELINE_FORMS',
  REMOVE_TIMELINE_DOCUMENTS: 'REMOVE_TIMELINE_DOCUMENTS',
  UPDATE_KEY_DATE_COMPLETION_STATUS: 'UPDATE_KEY_DATE_COMPLETION_STATUS',
  SEND_KEY_DATE_REMINDER: 'SEND_KEY_DATE_REMINDER',
  SYNC_TIMELINE_DATES: 'SYNC_TIMELINE_DATES',
  /** RECORD_EMAIL_EVENTS - Sent emails 1200 - 1250 */
  RECORD_EMAIL_EVENTS: 'RECORD_EMAIL_EVENTS',
  /** BACKFILL - System / Misc 5000 - 5499 */
  BACKFILL: 'BACKFILL',
  BACKFILL_SCRIPT: 'BACKFILL_SCRIPT',
  VOID_TRANSACTION: 'VOID_TRANSACTION',
  UNVOID_TRANSACTION: 'UNVOID_TRANSACTION',
  ADD_ACTIVITY: 'ADD_ACTIVITY',
  RECORD_COMMENT: 'RECORD_COMMENT',
  START_CHORE: 'START_CHORE',
  INITIALIZE_BROKERAGE_SETTINGS: 'INITIALIZE_BROKERAGE_SETTINGS',
  SEND_EMAIL: 'SEND_EMAIL',
  HIDE_CHORE: 'HIDE_CHORE',
  /** UPDATE_COVER_PHOTO - Core Transaction 3000 - 3999 */
  UPDATE_COVER_PHOTO: 'UPDATE_COVER_PHOTO',
  UPDATE_ORG_DETAILS: 'UPDATE_ORG_DETAILS',
  CLEAR_ORG_DETAILS: 'CLEAR_ORG_DETAILS',
  CREATE_LINK_ZF_FLOW: 'CREATE_LINK_ZF_FLOW',
  /** CREATE_INVOICE - Invoices 4000-4020 */
  CREATE_INVOICE: 'CREATE_INVOICE',
  UPDATE_INVOICE: 'UPDATE_INVOICE',
  /** FILL_TRANSACTION_DOCUMENT_FORM - Forms 5500 - 5999 */
  FILL_TRANSACTION_DOCUMENT_FORM: 'FILL_TRANSACTION_DOCUMENT_FORM',
  IMPORT_FORMS: 'IMPORT_FORMS',
  AUTOUPDATE_FORMS: 'AUTOUPDATE_FORMS',
  CLEAN_TRANSACTION_DOCUMENTS: 'CLEAN_TRANSACTION_DOCUMENTS',
  /** UPDATE_FILE_REVIEW - File Review 6000 - 6099 */
  UPDATE_FILE_REVIEW: 'UPDATE_FILE_REVIEW',
  FINISH_FILE_REVIEW: 'FINISH_FILE_REVIEW',
  CANCEL_FILE_REVIEW: 'CANCEL_FILE_REVIEW',
  SET_FULLY_APPROVED_REVIEW_STATE: 'SET_FULLY_APPROVED_REVIEW_STATE',
  /** UPDATE_TEMPLATE_METADATA - Transaction Templates 6100 - 6149 */
  UPDATE_TEMPLATE_METADATA: 'UPDATE_TEMPLATE_METADATA',
  APPLY_TEMPLATES: 'APPLY_TEMPLATES',
  /** CREATE_TODAYS_PEAD_FLOW - Pead 6150 - 6169 */
  CREATE_TODAYS_PEAD_FLOW: 'CREATE_TODAYS_PEAD_FLOW',
  CREATE_PEAD_FROM_FLOW: 'CREATE_PEAD_FROM_FLOW',
  SET_PEAD_ADDRESSES: 'SET_PEAD_ADDRESSES',
  SET_PEAD_ENTRANTS: 'SET_PEAD_ENTRANTS',
  SET_PEAD_FIELDS: 'SET_PEAD_FIELDS',
  STORE_PEAD_GENERATION_RESULTS: 'STORE_PEAD_GENERATION_RESULTS',
  CREATE_PEAD_ESIGN_FLOW: 'CREATE_PEAD_ESIGN_FLOW',
  STORE_PEAD_TDS_REPLACEMENTS: 'STORE_PEAD_TDS_REPLACEMENTS',
  MIGRATE_PEAD_TO_LISTING: 'MIGRATE_PEAD_TO_LISTING',
  UPLOAD_PEAD_FORMS: 'UPLOAD_PEAD_FORMS',
  TRANSFER_PEAD_REQUESTS_FROM_SOURCE_PROSPECT:
    'TRANSFER_PEAD_REQUESTS_FROM_SOURCE_PROSPECT',
  /** GET_OR_CREATE_OFFER - Offers 6170 - 6199 & 4153 */
  GET_OR_CREATE_OFFER: 'GET_OR_CREATE_OFFER',
  INITIALIZE_PURCHASE_SIDE_OFFER: 'INITIALIZE_PURCHASE_SIDE_OFFER',
  SET_OFFER_READY_TO_SUBMIT: 'SET_OFFER_READY_TO_SUBMIT',
  UPDATE_OFFER_ARCHIVAL_STATUS: 'UPDATE_OFFER_ARCHIVAL_STATUS',
  UPDATE_OFFER_FAVORITE_STATUS: 'UPDATE_OFFER_FAVORITE_STATUS',
  SET_OFFER_NOTES: 'SET_OFFER_NOTES',
  SET_OFFERS_SORT_INDICES: 'SET_OFFERS_SORT_INDICES',
  UPDATE_OFFER_SUBMISSION_PREFERENCES: 'UPDATE_OFFER_SUBMISSION_PREFERENCES',
  SET_CLIENT_OFFERS_SEEN: 'SET_CLIENT_OFFERS_SEEN',
  DETECT_PACKAGE_FORMS: 'DETECT_PACKAGE_FORMS',
  UPDATE_OFFERS_CLIENT_WORKSPACE_CONFIG:
    'UPDATE_OFFERS_CLIENT_WORKSPACE_CONFIG',
  UPDATE_PACKAGE_OCR_STATUS: 'UPDATE_PACKAGE_OCR_STATUS',
  UPDATE_OFFER_SUBMITTED_DATE: 'UPDATE_OFFER_SUBMITTED_DATE',
  UPDATE_OFFER_KEY_TERMS_CONFIG: 'UPDATE_OFFER_KEY_TERMS_CONFIG',
  UPDATE_BUY_SIDE_KEY_TERM_META: 'UPDATE_BUY_SIDE_KEY_TERM_META',
  /** SYNC_PACKAGE_DOCUMENTS - Packages 6220 - 6299 */
  SYNC_PACKAGE_DOCUMENTS: 'SYNC_PACKAGE_DOCUMENTS',
  LINK_TRANSACTION_PACKAGE_TO_PACKAGE: 'LINK_TRANSACTION_PACKAGE_TO_PACKAGE',
  SYNC_PACKAGE_ACTION: 'SYNC_PACKAGE_ACTION',
  START_PACKAGE_ACTION_FLOW: 'START_PACKAGE_ACTION_FLOW',
  CREATE_PACKAGE_PENDING_ACTION: 'CREATE_PACKAGE_PENDING_ACTION',
  CANCEL_PACKAGE_PENDING_ACTION: 'CANCEL_PACKAGE_PENDING_ACTION',
  SET_PACKAGE_SETUP_TYPE: 'SET_PACKAGE_SETUP_TYPE',
  SET_PACKAGE_COUNTER_KIND: 'SET_PACKAGE_COUNTER_KIND',
  REGISTER_PACKAGE_ACCESS_ACTIVITY: 'REGISTER_PACKAGE_ACCESS_ACTIVITY',
  RECORD_PACKAGE_ACCESSED: 'RECORD_PACKAGE_ACCESSED',
  CREATE_PACKAGE_PENDING_TDS: 'CREATE_PACKAGE_PENDING_TDS',
  EXECUTE_PENDING_ACTION: 'EXECUTE_PENDING_ACTION',
  UPDATE_SUBMITTED_ACTION_DATA: 'UPDATE_SUBMITTED_ACTION_DATA',
  EMIT_TRANSACTION_PACKAGE_EVENT: 'EMIT_TRANSACTION_PACKAGE_EVENT',
  VOID_OFFER_PACKAGE: 'VOID_OFFER_PACKAGE',
  /** CREATE_BUYER_PROSPECT_PROPERTY - Buyer Prospect 6300 - 6319 */
  CREATE_BUYER_PROSPECT_PROPERTY: 'CREATE_BUYER_PROSPECT_PROPERTY',
  UPDATE_BUYER_PROSPECT_PROPERTY_FIELDS:
    'UPDATE_BUYER_PROSPECT_PROPERTY_FIELDS',
  /** _OBSOLETE_UPDATE_BROKER_SYNC_ACCOUNT - BROKER SYNC 4100-4200 */
  _OBSOLETE_UPDATE_BROKER_SYNC_ACCOUNT: '_OBSOLETE_UPDATE_BROKER_SYNC_ACCOUNT',
  _OBSOLETE_CREATE_IMPORT_CHECKLIST_FLOW:
    '_OBSOLETE_CREATE_IMPORT_CHECKLIST_FLOW',
  _OBSOLETE_FINISH_IMPORT_CHECKLIST_FLOW:
    '_OBSOLETE_FINISH_IMPORT_CHECKLIST_FLOW',
  _OBSOLETE_RECONCILE_SYNC_CHECKLIST: '_OBSOLETE_RECONCILE_SYNC_CHECKLIST',
  _OBSOLETE_FETCH_BROKER_TRANSACTION_LIST:
    '_OBSOLETE_FETCH_BROKER_TRANSACTION_LIST',
  _OBSOLETE_FETCH_BROKER_CHECKLIST: '_OBSOLETE_FETCH_BROKER_CHECKLIST',
  _OBSOLETE_PROCESS_TRANSACTION_LIST_PULL_RESPONSE:
    '_OBSOLETE_PROCESS_TRANSACTION_LIST_PULL_RESPONSE',
  _OBSOLETE_PROCESS_CHECKLIST_PULL_RESPONSE:
    '_OBSOLETE_PROCESS_CHECKLIST_PULL_RESPONSE',
  _OBSOLETE_PROCESS_CHECKLIST_PUSH_RESPONSE:
    '_OBSOLETE_PROCESS_CHECKLIST_PUSH_RESPONSE',
  /** ASSIGN_TEAM - Teams 4021 - 4050 */
  ASSIGN_TEAM: 'ASSIGN_TEAM',
  /** CREATE_SCHEDULE_MEETING_FLOW - Flows 4051-4151 */
  CREATE_SCHEDULE_MEETING_FLOW: 'CREATE_SCHEDULE_MEETING_FLOW',
  /** _OBSOLETE_CREATE_INVITE_TO_AVID_FLOW - AVID 4151-4175 */
  _OBSOLETE_CREATE_INVITE_TO_AVID_FLOW: '_OBSOLETE_CREATE_INVITE_TO_AVID_FLOW',
  _OBSOLETE_MIGRATE_TO_MULTIUNIT_AVID: '_OBSOLETE_MIGRATE_TO_MULTIUNIT_AVID',
  _OBSOLETE_CREATE_AVID_FLOW: '_OBSOLETE_CREATE_AVID_FLOW',
  INVITE_TO_AVID: 'INVITE_TO_AVID',
  GET_OR_CREATE_INITIAL_AVID_FLOW: 'GET_OR_CREATE_INITIAL_AVID_FLOW',
  CREATE_AVID_FILL_FLOWS: 'CREATE_AVID_FILL_FLOWS',
  UPDATE_AVID_STAGE: 'UPDATE_AVID_STAGE',
  CREATE_AVID_GENERATION_FLOW: 'CREATE_AVID_GENERATION_FLOW',
  COMPLETE_AVID: 'COMPLETE_AVID',
  INVITE_AGENT_TO_COMPLETE_AVID: 'INVITE_AGENT_TO_COMPLETE_AVID',
  CREATE_UPDATE_AVID_FILL_PARTY_FLOW: 'CREATE_UPDATE_AVID_FILL_PARTY_FLOW',
  /** GET_OR_CREATE_ZF_IMPORT_DOC_FLOW - MISC 4176-4200 */
  GET_OR_CREATE_ZF_IMPORT_DOC_FLOW: 'GET_OR_CREATE_ZF_IMPORT_DOC_FLOW',
  CREATE_COMMS_DOCUMENT: 'CREATE_COMMS_DOCUMENT',
  /** SET_ZF_LINK - Integrations 4201-4249 */
  SET_ZF_LINK: 'SET_ZF_LINK',
  /** SELLER_DISC_APP_SET_CURR_STEP - Seller disclosures app 4300 - 4310 */
  SELLER_DISC_APP_SET_CURR_STEP: 'SELLER_DISC_APP_SET_CURR_STEP',
  SELLER_DISC_APP_CREATE_WF_SF: 'SELLER_DISC_APP_CREATE_WF_SF',
  /** UPLOAD_PROPERTY_IMAGE - Property Info Updates 4316-4320 */
  UPLOAD_PROPERTY_IMAGE: 'UPLOAD_PROPERTY_IMAGE',
  MOVE_PROPERTY_IMAGES_TO_STORAGE: 'MOVE_PROPERTY_IMAGES_TO_STORAGE',
  /** ESIGN_DETECTION_PROVIDE_USER_FEEDBACK - Signature Detection 4321-4350 */
  ESIGN_DETECTION_PROVIDE_USER_FEEDBACK:
    'ESIGN_DETECTION_PROVIDE_USER_FEEDBACK',
  /** _OBSOLETE_GFP_TERMS_ACCEPTED - Obsolete intents follow */
  _OBSOLETE_GFP_TERMS_ACCEPTED: '_OBSOLETE_GFP_TERMS_ACCEPTED',
  _OBSOLETE_PREPARE_TIMELINE_SETUP: '_OBSOLETE_PREPARE_TIMELINE_SETUP',
  _OBSOLETE_COMPLETE_TIMELINE_SETUP: '_OBSOLETE_COMPLETE_TIMELINE_SETUP',
  _OBSOLETE_INVITE_PARTIES: '_OBSOLETE_INVITE_PARTIES',
  _OBSOLETE_UPDATE_TRANSACTION_DOCUMENTS_EXEMPT_STATUS:
    '_OBSOLETE_UPDATE_TRANSACTION_DOCUMENTS_EXEMPT_STATUS',
  _OBSOLETE_DELETE_FOLDERS: '_OBSOLETE_DELETE_FOLDERS',
  _OBSOLETE_MAIL_TDS: '_OBSOLETE_MAIL_TDS',
  _OBSOLETE_SUBMIT_GUIDED_FORM_PACKET_FLOW:
    '_OBSOLETE_SUBMIT_GUIDED_FORM_PACKET_FLOW',
  _OBSOLETE_START_GUIDED_FORM_PACKET_FLOW:
    '_OBSOLETE_START_GUIDED_FORM_PACKET_FLOW',
  _OBSOLETE_COMPLETE_GUIDED_FORM_PACKET_FLOW:
    '_OBSOLETE_COMPLETE_GUIDED_FORM_PACKET_FLOW',
  _OBSOLETE_RECOMBINE_TRANSACTION_DOCUMENTS:
    '_OBSOLETE_RECOMBINE_TRANSACTION_DOCUMENTS',
  _OBSOLETE_UPDATE_GFP_PDF: '_OBSOLETE_UPDATE_GFP_PDF',
  _OBSOLETE_INITIALIZE_TIMELINE_DATES: '_OBSOLETE_INITIALIZE_TIMELINE_DATES',
  _OBSOLETE_MERGE_TRANSACTION: '_OBSOLETE_MERGE_TRANSACTION',
  _OBSOLETE_UPDATE_TD_TASK_EXEMPT_STATUS:
    '_OBSOLETE_UPDATE_TD_TASK_EXEMPT_STATUS',
  _OBSOLETE_EMAIL_EVENT: '_OBSOLETE_EMAIL_EVENT',
  _OBSOLETE_CREATE_DISC_PACKAGE_SUPER_FLOW:
    '_OBSOLETE_CREATE_DISC_PACKAGE_SUPER_FLOW',
  _OBSOLETE_UPDATE_TRANSACTION_SETTINGS:
    '_OBSOLETE_UPDATE_TRANSACTION_SETTINGS',
  _OBOLETE_UPDATE_OFFER_STATUS: '_OBOLETE_UPDATE_OFFER_STATUS',
  _OBSOLETE_SYNC_OFFERS_TO_LISTING: '_OBSOLETE_SYNC_OFFERS_TO_LISTING',
  _OBSOLETE_SYNC_OFFER_FLOW: '_OBSOLETE_SYNC_OFFER_FLOW',
  _OBSOLETE_SYNC_OFFER_DOCUMENTS: '_OBSOLETE_SYNC_OFFER_DOCUMENTS',
  _OBSOLETE_UPDATE_PACKETS_ARCHIVAL_STATUS:
    '_OBSOLETE_UPDATE_PACKETS_ARCHIVAL_STATUS',
  _OBSOLETE_TOGGLE_OFFERS_CLIENT_WORKSPACE:
    '_OBSOLETE_TOGGLE_OFFERS_CLIENT_WORKSPACE',
  _OBSOLETE_GET_OR_CREATE_OFFER_FLOW: '_OBSOLETE_GET_OR_CREATE_OFFER_FLOW',
  _OBSOLETE_UPDATE_TIMELINE_LOGO: '_OBSOLETE_UPDATE_TIMELINE_LOGO',
  _OBSOLETE_REMOVE_PARTY: '_OBSOLETE_REMOVE_PARTY',
  _OBSOLETE_INVITE_PARTY_CONTACT_TO_TRANSACTION:
    '_OBSOLETE_INVITE_PARTY_CONTACT_TO_TRANSACTION',
  _OBSOLETE_GET_OR_CREATE_COMMISSION_ADVANCE_FLOW:
    '_OBSOLETE_GET_OR_CREATE_COMMISSION_ADVANCE_FLOW',
} as const;

export type IntentKind = typeof IntentKind[keyof typeof IntentKind];

export interface IntentReplaceUser {
  newUserId: string;
  oldUserId: string;
}

export interface IntentPropertiesCreate {
  properties: IntentPropertiesCreateProperty[];
  setAsPrimary: boolean;
  OBSOLETEProperties: ItemPropertyInfo[];
}

export interface IntentPropertiesCreateProperty {
  propertyInfo?: ItemPropertyInfo;
  fields?: { [key: string]: any };
}

export interface IntentPropertySetAsPrimary {
  propertyInfoId: string;
  transactionDocumentsToDemote: string[];
  updateToState: TransactionState;
}

export interface IntentPropertiesUpdate {
  properties: IntentPropertiesUpdateProperty[];
}

export interface IntentPropertiesUpdateProperty {
  id: string;
  propertyInfo?: ItemPropertyInfo;
}

export interface IntentPropertyUpdateFields {
  fieldValuesByNamespace: { [key: string]: { [key: string]: any } };
  itemIdByNamespace: { [key: string]: string };
  propertyId: string;
}

export interface IntentPropertyUpdateFieldsFieldValuesByNamespaceEntry {
  key: string;
  value?: { [key: string]: any };
}

export interface IntentPropertyUpdateFieldsItemIdByNamespaceEntry {
  key: string;
  value: string;
}

export interface IntentUpdateDppOrder {
  status: ItemDppStatus;
  productId: string;
  paymentType: ItemDppPaymentType;
  stripeSourceId: string;
  orderNum: string;
  disqualifyReason: ItemDppDisqualifyReason;
  orderedAt: number;
}

export interface IntentExpireDppOrder {}

export interface IntentSetGfpfAsReviewed {
  gfpfId: string;
}

export interface IntentSetGfpfPreviewPdf {
  gfpfId: string;
}

export interface IntentAddAgentTeamMembers {
  partyId: string;
}

export interface IntentUpdateOrgDetails {
  txnAccessCutoff: number;
}

export interface IntentClearOrgDetails {}

export interface IntentAddCalendarAttendees {
  attendees: IntentAddCalendarAttendeesAttendee[];
}

export interface IntentAddCalendarAttendeesAttendee {
  itemId: string;
}

export interface IntentOBSOLETEInvitePartyContactToTransaction {
  invites: IntentOBSOLETEInvitePartyContactToTransactionInvitePartyContactRequest[];
}

export interface IntentOBSOLETEInvitePartyContactToTransactionInvitePartyContactRequest {
  partyId: string;
  party?: Party;
  subject: string;
  body: string;
  /**
   * Set this to True to invite someone to the transaction without sending an
   * invite email (TODO: deal w/ security risk here since this allows anyone to make anyone selse agent to their transaction
   * by knowing their email - for now it's restricted to external api usage only)
   * This will add EXISTING_USER restriction so it's only possible if an ACTIVE
   * user owns the email
   */
  suppressInviteEmail: boolean;
  inviteRestrictions: PartyInviteRequestInviteRestriction[];
}

export interface IntentUpdateTdTaskAssignments {
  assignments: IntentUpdateTdTaskAssignmentsAssignment[];
}

export interface IntentUpdateTdTaskAssignmentsAssignment {
  taskId: string;
  addTdsIds: string[];
  removeTdsIds: string[];
}

export interface IntentMailTds {
  tdIds: string[];
  subject: string;
  body: string;
  fromEmail: string;
  to: Recipient[];
  cc: Recipient[];
  bcc: Recipient[];
  senderContact?: Contact;
  externalEmail?: IntentMailTdsExternalEmail;
}

export interface IntentMailTdsExternalEmail {
  app: string;
  tokenId: string;
}

export interface IntentImportInboxAttachments {
  imports: IntentImportInboxAttachmentsImport[];
}

export interface IntentImportInboxAttachmentsImport {
  inboxAttachmentId: string;
  unsignedBaseTdId: string;
}

export interface IntentOBSOLETEUpdateTdTaskExemptStatus {
  updates: IntentOBSOLETEUpdateTdTaskExemptStatusUpdate[];
}

export interface IntentOBSOLETEUpdateTdTaskExemptStatusUpdate {
  taskId: string;
  exempt: boolean;
}

export interface IntentUpdateTaskAssignedParty {
  taskId: string;
  partyId: string;
  teamMembershipId: string;
}

export interface IntentCreateTasksFromTemplate {
  templateJson: string;
  parentId: string;
  clearAll: boolean;
}

export interface IntentCreateTasks {
  newTasks: IntentCreateTasksNewTask[];
}

export interface IntentCreateTasksNewTask {
  task?: ItemTask;
  parentId: string;
  title: string;
  clientId: string;
  orderIndex: number;
  children: IntentCreateTasksNewTask[];
}

export interface IntentCompleteTask {
  taskId: string;
}

export interface IntentSkipTask {
  taskId: string;
}

export interface IntentToggleChecklists {
  toggles: IntentToggleChecklistsToggle[];
  addRelatedForms: boolean;
}

export interface IntentToggleChecklistsToggle {
  /** Doesn't specify version */
  itemId: string;
  /** Specifies exact version */
  id: string;
  op: IntentToggleChecklistsToggleOp;
}

export const IntentToggleChecklistsToggleOp = {
  UNKNOWN: 'UNKNOWN',
  APPLY: 'APPLY',
  UNAPPLY: 'UNAPPLY',
  UPGRADE: 'UPGRADE',
} as const;

export type IntentToggleChecklistsToggleOp =
  typeof IntentToggleChecklistsToggleOp[keyof typeof IntentToggleChecklistsToggleOp];

export interface IntentChecklistItemUpdateIsExempt {
  updates: IntentChecklistItemUpdateIsExemptUpdateIsExempt[];
}

export interface IntentChecklistItemUpdateIsExemptUpdateIsExempt {
  taskId: string;
  isExempt: boolean;
}

export interface IntentCopyChecklistTemplates {
  ids: string[];
}

export interface IntentChecklistItemUpdateRequired {
  updates: IntentChecklistItemUpdateRequiredUpdateRequired[];
}

export interface IntentChecklistItemUpdateRequiredUpdateRequired {
  taskId: string;
  required: ChecklistItemRequiredEnum;
}

export interface IntentReorderTasks {
  reorderings: IntentReorderTasksNewTaskIndex[];
}

export interface IntentReorderTasksNewTaskIndex {
  taskId: string;
  orderIndex: number;
  parentId: string;
}

export interface IntentUpdateTaskMeta {
  updates: Item[];
}

/** Create the transaction */
export interface IntentCreate {
  addressId: string;
  setupFlowId: string;
  state: TransactionState;
  creatorRoles: PartyRole[];
  title: string;
  isTest: boolean;
  addTeamMembers: boolean;
  reState: string;
  OBSOLETEAdminTeamId: string;
  template?: IntentCreateCreateTemplate;
  /** If txn is being created from an existing Buyer Prospect, indicate ID here */
  fromBuyerProspect: string;
  cloneFromTransaction?: IntentCreateCloneFromTransaction;
  /**
   * If creating purchase side txn for offer submission, then indicate listing
   * txn ID here
   */
  submitOfferToTransaction: string;
  agentContact?: Contact;
  isLease: boolean;
  /** Used if txn is being created from an existing Buyer Prospect Property */
  buyerProspectPropertyId: string;
  invites: PartyInviteRequest[];
  apiAppId: string;
  creator?: IntentCreateCreator;
  teamId: string;
  isReferral: boolean;
  dealId: string;
}

export interface IntentCreateCreateTemplate {
  orgId: string;
  metadata?: IntentUpdateTemplateMetadata;
}

export interface IntentCreateCloneFromTransaction {
  id: string;
  includeParts: IntentApplyTemplatesPart[];
  archiveOriginal: boolean;
}

export interface IntentCreateCreator {
  userContactId: string;
  userContactSource?: ContactSource;
}

export interface IntentInviteParties {
  invites: PartyInviteRequest[];
}

/** When the user loads the transaction detail page */
export interface IntentLoad {
  page: ItemLoadSummaryPage;
}

/** When the a user accepts an invite. */
export interface IntentInviteComplete {
  invite?: InviteRequest;
}

/** Update archived status */
export interface IntentUpdateArchivalStatus {
  archived: boolean;
}

/** Updates the transaction.meta.state field. */
export interface IntentUpdateTransactionState {
  state: TransactionState;
  cancellationMeta?: TransactionCancellationMeta;
}

export interface IntentUpdateTransactionDocumentArchivalStatus {
  transactionDocumentId: string;
  archived: boolean;
}

/** Allows archiving of a TD. */
export interface IntentUpdateTransactionDocumentsArchivalStatus {
  updates: IntentUpdateTransactionDocumentArchivalStatus[];
}

/** Allows marking of a TD as exempt or non-exempt. */
export interface IntentOBSOLETEUpdateTransactionDocumentsExemptStatus {
  updates: IntentOBSOLETEUpdateTransactionDocumentsExemptStatusExemptStatus[];
}

export interface IntentOBSOLETEUpdateTransactionDocumentsExemptStatusExemptStatus {
  transactionDocumentId: string;
  exempt: boolean;
}

export interface IntentUpdateTransactionDocumentsNotes {
  updates: IntentUpdateTransactionDocumentsNotesNoteUpdate[];
}

export interface IntentUpdateTransactionDocumentsNotesNoteUpdate {
  transactionDocumentId: string;
  notes: string;
}

/** Stamp Transaction document version */
export interface IntentStampTdv {
  stamps: IntentStampTdvStamp[];
}

export interface IntentStampTdvStamp {
  receivedLine: string;
  kind: IntentStampTdvStampKind;
  x: number;
  y: number;
  tdvId: string;
}

export const IntentStampTdvStampKind = {
  UNKNOWN: 'UNKNOWN',
  SELLER: 'SELLER',
  BUYER: 'BUYER',
} as const;

export type IntentStampTdvStampKind =
  typeof IntentStampTdvStampKind[keyof typeof IntentStampTdvStampKind];

/** Annotate a Transaction Document Version */
export interface IntentAnnotateTdv {
  tdvId: string;
  referenceId: string;
}

export interface IntentGetOrCreateFormOutlineFlow {
  tdvId: string;
}

/** Adds an image url to a Property Info Item */
export interface IntentUploadPropertyImage {
  url: string;
  /**
   * property_item_id can be a PropertyInfo item id or a BuyerProspectProperty
   * if none is provided, the default PropertyInfo for the txn will be used
   */
  propertyItemId: string;
}

/** Migrates external images to s3 and updates the Property Info */
export interface IntentMovePropertyImagesToStorage {
  /**
   * property_item_ids can be PropertyInfo or BuyerProspectProperty item ids
   * if none is provided, the default PropertyInfo for the txn will be used
   */
  propertyItemIds: string[];
}

export interface IntentRestoreOriginalTds {
  restores: IntentRestoreOriginalTdsRestore[];
}

export interface IntentRestoreOriginalTdsRestore {
  tdId: string;
  keepCurrent: boolean;
}

export interface IntentCopyTds {
  folderId: string;
  transactionDocuments?: IntentCopyTdsTransactionDocuments;
  targetFolderId: string;
  targetNewFolder?: IntentCopyTdsTargetNewFolder;
}

export interface IntentCopyTdsTargetNewFolder {
  title: string;
  folderKind: ItemFolderKind;
}

export interface IntentCopyTdsTransactionDocuments {
  ids: string[];
}

/**
 * Bulk creates transaction document versions.
 * Supports a varienty of different sources.
 */
export interface IntentCreateTransactionDocumentVersions {
  newVersions: IntentCreateTransactionDocumentVersionsNewVersion[];
  isFromDocumentsPage: boolean;
}

export interface IntentCreateTransactionDocumentVersionsNewVersion {
  transactionDocumentId: string;
  newTransactionDocument?: IntentCreateTransactionDocumentVersionsNewVersionNewTransactionDocument;
  source?: ItemTransactionDocumentVersionSource;
  syncedZfDocuments: ItemTransactionDocumentVersionSyncedZfDocument[];
  replace: boolean;
  replaceMode: IntentCreateTransactionDocumentVersionsNewVersionReplaceMode;
  muteActivity: boolean;
}

export const IntentCreateTransactionDocumentVersionsNewVersionReplaceMode = {
  NO_REPLACE: 'NO_REPLACE',
  FOLDER_SCOPE: 'FOLDER_SCOPE',
  GLOBAL: 'GLOBAL',
  TRASH_ORIGINAL: 'TRASH_ORIGINAL',
} as const;

export type IntentCreateTransactionDocumentVersionsNewVersionReplaceMode =
  typeof IntentCreateTransactionDocumentVersionsNewVersionReplaceMode[keyof typeof IntentCreateTransactionDocumentVersionsNewVersionReplaceMode];

export interface IntentCreateTransactionDocumentVersionsNewVersionNewTransactionDocument {
  folderId: string;
  title: string;
  taskId: string;
  kind: ItemTransactionDocumentKind;
  addAtTop: boolean;
  notes: string;
  replacesTdId: string;
  moveOriginalToTrash: boolean;
  origin: ItemTransactionDocumentOrigin;
}

export interface IntentSplitOrMergeTDVS {
  newVersions: IntentCreateTransactionDocumentVersionsNewVersion[];
  deleteOriginalDocuments: boolean;
}

/** Set Field value */
export interface IntentOBSOLETESetFieldValue {}

export interface IntentFillTransactionDocumentForm {
  transactionDocumentVersionId: string;
  fieldValuesByNamespace: { [key: string]: { [key: string]: any } };
  fieldIdsToUnlink: string[];
  fieldIdsToLink: string[];
  debounceActivities: boolean;
}

export interface IntentFillTransactionDocumentFormFieldValuesByNamespaceEntry {
  key: string;
  value?: { [key: string]: any };
}

export interface IntentCleanTransactionDocuments {
  transactionDocumentIds: string[];
  forceClean: boolean;
}

export interface IntentImportForms {
  imports: IntentImportFormsImport[];
  folderId: string;
  taskId: string;
  packageId: string;
  trigger: string;
  clearContents: boolean;
  origin: ItemTransactionDocumentOrigin;
}

export interface IntentImportFormsImport {
  formId: string;
  sourceLibraryId: string;
  fieldValues?: { [key: string]: any };
  title: string;
  /** List with the IDs of all unlinked fields (includes all values from system_unlinked_field_ids) */
  unlinkedFieldIds: string[];
  /** List with the IDs of any field that was auto unlinked by the system */
  systemUnlinkedFieldIds: string[];
  sourceTdId: string;
}

export interface IntentAutoupdateForms {}

export interface IntentUpdateFileReview {
  fileReviewId: string;
  reviewedChecklistItems: IntentUpdateFileReviewReviewedChecklistItem[];
  /**
   * If materialize_changes is set to True, all added reviewed_checklist_items will
   *  impact its changes to underlying CLIs immediately. If not set, changes will be
   *  impacted once the FileReview is finished or if a future update does set this
   *  flag to True
   */
  materializeChanges: boolean;
  finishReview: boolean;
  /**
   * set_fully_approved_review_state is only applicable is finish_review is set to
   *  True
   */
  setFullyApprovedReviewState: boolean;
}

export interface IntentUpdateFileReviewReviewedChecklistItem {
  checklistItemId: string;
  op?: ItemFileReviewOp;
}

export interface IntentFinishFileReview {
  fileReviewId: string;
  setFullyApprovedReviewState: boolean;
  fileReviewComment: string;
}

export interface IntentCancelFileReview {
  fileReviewId: string;
  revertChanges: boolean;
}

export interface IntentSetFullyApprovedReviewState {
  undo: boolean;
}

export interface IntentUpdateTemplateMetadata {
  /** DEPRECATED */
  side: TransactionSide;
  title: string;
  visibility: TransactionTemplateVisibility;
  teamIds: string[];
  isLease: boolean;
  propertyInfoId: string;
  creatorId: string;
}

export interface IntentApplyTemplates {
  templateIds: string[];
  applyToTemplate: boolean;
  applyFromNonTemplates: boolean;
  includeParts: IntentApplyTemplatesPart[];
  excludeParts: IntentApplyTemplatesPart[];
  noActivity: boolean;
  includeTransactionFields: boolean;
  autobindUserParties: boolean;
  sourcePropertyInfoId: string;
  targetPropertyInfoIds: string[];
}

export const IntentApplyTemplatesPart = {
  NONE: 'NONE',
  PARTIES: 'PARTIES',
  DETAILS: 'DETAILS',
  DOCUMENTS: 'DOCUMENTS',
  OFFER: 'OFFER',
} as const;

export type IntentApplyTemplatesPart =
  typeof IntentApplyTemplatesPart[keyof typeof IntentApplyTemplatesPart];

/** Pead ---------------------------- */
export interface IntentCreateTodaysPeadFlow {
  addresses: ItemPeadAddress[];
  isFromLink: boolean;
}

export interface IntentCreatePeadFromFlow {
  flowId: string;
  createTask: boolean;
}

export interface IntentSetPeadAddresses {
  peadId: string;
  addresses: ItemPeadAddress[];
}

export interface IntentSetPeadEntrants {
  peadId: string;
  entrants: ItemPeadEntrant[];
  splitEntrants: boolean;
}

export interface IntentSetPeadFields {
  peadId: string;
  fields?: ItemPeadFields;
}

export interface IntentStorePeadGenerationResults {
  peadId: string;
  generatedAddresses: IntentStorePeadGenerationResultsGeneratedAddress[];
}

export interface IntentStorePeadGenerationResultsGeneratedAddress {
  addressId: string;
  documentIds: string[];
  filenames: string[];
}

export interface IntentCreatePeadEsignFlow {
  peadId: string;
  mobileApp: boolean;
}

export interface IntentStorePeadTdsReplacements {
  peadId: string;
  tdIds: string[];
}

export interface IntentMigratePeadToListing {
  sourceTxnId: string;
  uuids: string[];
}

export interface IntentUploadPeadForms {
  peadId: string;
  addressForms: IntentUploadPeadFormsAddressForms[];
}

export interface IntentUploadPeadFormsAddressForms {
  addressId: string;
  uploads: ItemTransactionDocumentVersionSourceUpload[];
}

export interface IntentTransferPeadRequestsFromSourceProspect {
  archiveProspect: boolean;
}

/** Create the setup `Flow` for `SignatureRequest`. */
export interface IntentCreateSignatureRequestFlow {
  transactionDocumentsIds: string[];
  tdvIds: string[];
  recipients: Recipient[];
  back?: RouteLink;
  callbackPath: string;
  hideMoveOriginalField: boolean;
  disableEvents: boolean;
  /** These fields are deprecated and ignored: */
  replaceMode: IntentCreateSignatureRequestFlowReplaceMode;
  defaultReplace: boolean;
  destFolderId: string;
  transactionPackageId: string;
}

/** Whether or not to replace source tds with new versions. */
export const IntentCreateSignatureRequestFlowReplaceMode = {
  /** DEFAULT - User is allowed to choose (defaults to `default_replace`) */
  DEFAULT: 'DEFAULT',
  /** ALWAYS - Always replace source tds with new versions. */
  ALWAYS: 'ALWAYS',
  /** NEVER - Never replace source tds. Create new TDs instead. */
  NEVER: 'NEVER',
} as const;

export type IntentCreateSignatureRequestFlowReplaceMode =
  typeof IntentCreateSignatureRequestFlowReplaceMode[keyof typeof IntentCreateSignatureRequestFlowReplaceMode];

export interface IntentCreateScheduleMeetingFlow {
  meetingType: string;
  taskId: string;
}

export interface IntentCreateOrUpdatePreparingSignatureRequest {
  signatureRequestId: string;
  flowId: string;
  transactionPackage?: IntentCreateSignatureRequestTransactionPackage;
}

/** Create a signature request */
export interface IntentCreateSignatureRequest {
  transactionDocumentIds: string[];
  recipients: Recipient[];
  /** Map <RecipientKey, RecipientInfo> to configure recipient-specific values */
  recipientsInfo: { [key: string]: IntentCreateSignatureRequestRecipientInfo };
  callbackPath: string;
  tokenId: string;
  subject: string;
  body: string;
  /** Whether or not to replace source tds with new versions. */
  replace: boolean;
  autoTab: boolean;
  dsDefault: boolean;
  moveOriginalToTrash: boolean;
  destFolderId: string;
  disableEvents: boolean;
  skipPreparation: boolean;
  provider: ItemSignatureRequestProvider;
  signatureRequestId: string;
  transactionPackage?: IntentCreateSignatureRequestTransactionPackage;
  /**
   * Whether or not the flow is being executed in the embedded app.
   *
   * @deprecated
   */
  embedded: boolean;
  gfpId: string;
  /** Make documents visible on client dashboard after signing is completed */
  makeDocumentsVisibleInCd: boolean;
}

export const IntentCreateSignatureRequestPlaceholderRole = {
  UNKNOWN_PLACEHOLDER: 'UNKNOWN_PLACEHOLDER',
  PLACEHOLDER1: 'PLACEHOLDER1',
  PLACEHOLDER2: 'PLACEHOLDER2',
  PLACEHOLDER3: 'PLACEHOLDER3',
  PLACEHOLDER4: 'PLACEHOLDER4',
  PLACEHOLDER5: 'PLACEHOLDER5',
  PLACEHOLDER6: 'PLACEHOLDER6',
} as const;

export type IntentCreateSignatureRequestPlaceholderRole =
  typeof IntentCreateSignatureRequestPlaceholderRole[keyof typeof IntentCreateSignatureRequestPlaceholderRole];

export interface IntentCreateSignatureRequestRecipientInfo {
  placeholderRoles: IntentCreateSignatureRequestPlaceholderRole[];
  /**
   * If a list of TD IDs is informed here, then the recipient will oly be
   * targeted to that subset of TDs.
   * If empty, then default behaviour is expected
   */
  tdIds: string[];
}

export interface IntentCreateSignatureRequestTransactionPackage {
  id: string;
  action: ItemActionType;
  message: string;
}

export interface IntentCreateSignatureRequestRecipientsInfoEntry {
  key: string;
  value?: IntentCreateSignatureRequestRecipientInfo;
}

/** Allows archiving of signature requests */
export interface IntentUpdateSignatureRequestsArchivalStatus {
  updates: IntentUpdateSignatureRequestsArchivalStatusArchivalStatus[];
}

export interface IntentUpdateSignatureRequestsArchivalStatusArchivalStatus {
  signatureRequestId: string;
  archived: boolean;
}

/** Revise a signature request */
export interface IntentReviseSignatureRequest {
  signatureRequestId: string;
  transactionDocumentIds: string[];
  recipients: Recipient[];
  recipientsInfo: { [key: string]: IntentCreateSignatureRequestRecipientInfo };
  subject: string;
  body: string;
  lockedSignerKeys: string[];
  makeDocumentsVisibleInCd: boolean;
}

export interface IntentReviseSignatureRequestRecipientsInfoEntry {
  key: string;
  value?: IntentCreateSignatureRequestRecipientInfo;
}

/** Create a folder */
export interface IntentCreateFolder {
  title: string;
  aggregateItemId: string;
}

/** Delete folders */
export interface IntentOBSOLETEDeleteFolders {
  folderIds: string[];
}

export interface IntentFolderToReorder {
  folderId: string;
  orderIndex: number;
}

/** Reorder Folders */
export interface IntentReorderFolders {
  folders: IntentFolderToReorder[];
}

export interface IntentSeeTransactionDocuments {
  tdvIds: string[];
}

export interface IntentRestoreTransactionDocuments {
  restores: IntentRestoreTransactionDocumentsRestore[];
}

export interface IntentRestoreTransactionDocumentsRestore {
  tdId: string;
  restoreToFolderId: string;
}

/** Rename a folder */
export interface IntentRenameFolder {
  folderId: string;
  title: string;
}

export interface IntentFolderRenames {
  renames: IntentRenameFolder[];
}

export interface IntentFolderCreates {
  creates: IntentCreateFolder[];
}

export interface IntentTransactionDocumentTrashes {
  transactionDocumentIds: string[];
}

export interface IntentRenameTransactionDocument {
  transactionDocumentId: string;
  title: string;
}

export interface IntentTransactionDocumentUploads {
  uploads: IntentTransactionDocumentUploadsUpload[];
}

export interface IntentTransactionDocumentUploadsUpload {
  documentId: string;
  folderId: string;
}

export interface IntentChangeDocumentClientVisibility {
  tdIds: string[];
  visible: boolean;
  notifyClients: boolean;
  excludedEmails: string[];
  noActivityLog: boolean;
  action: IntentChangeDocumentClientVisibilityAction;
}

export const IntentChangeDocumentClientVisibilityAction = {
  USER_TOGGLE: 'USER_TOGGLE',
  DOCUMENT_DELETION: 'DOCUMENT_DELETION',
  SIGNATURE_REQUEST_COMPLETION: 'SIGNATURE_REQUEST_COMPLETION',
  SIGNATURE_REQUEST_DELETION: 'SIGNATURE_REQUEST_DELETION',
} as const;

export type IntentChangeDocumentClientVisibilityAction =
  typeof IntentChangeDocumentClientVisibilityAction[keyof typeof IntentChangeDocumentClientVisibilityAction];

export interface IntentEditReminder {
  reminder?: Reminder;
  taskReminderId: string;
  taskId: string;
}

export interface IntentSendReminder {
  taskReminderId: string;
}

export interface IntentRenameTransactionDocuments {
  requestedUpdates: IntentRenameTransactionDocument[];
}

/** Assign transaction documents to a folder */
export interface IntentAssignTransactionDocumentsToFolder {
  assignments: IntentAssignTransactionDocumentsToFolderFolderAssignment[];
  allowNamespaceChange: boolean;
}

export interface IntentAssignTransactionDocumentsToFolderFolderAssignment {
  folderId: string;
  tdId: string;
  index: number;
}

/** Update a signature request */
export interface IntentUpdateSignatureRequest {
  signatureRequestId: string;
  status: ItemSignatureRequestStatus;
  emailSubject: string;
  completedAt: number;
  statusTimeGenTs: string;
  dsRecipients: ItemSignatureRequestDSRecipient[];
  dsDocs: DsDocument[];
}

/**
 * Refresh a signature request via REST api or force status to manually update it
 * without consulting API.
 */
export interface IntentRefreshSignatureRequest {
  signatureRequestId: string;
  envelopeId: string;
  forceStatus: ItemSignatureRequestStatus;
  /**
   * The signing envelope will signal this aggregate if a revision
   * was committed using this flag.
   * It's only purpose as of this writing is to create the proper
   * activity kind once a revision is closed by either cancelling it
   * or submitting it.
   */
  revisionCommitted: boolean;
}

export interface IntentRefreshSignatureRequestRecipientStatus {
  envelopeId: string;
}

export interface IntentVoidSignatureRequest {
  signatureRequestId: string;
  voidReason: string;
}

export interface IntentResendSignatureRequest {
  signatureRequestId: string;
}

export interface IntentDuplicateSignatureRequest {
  signatureRequestId: string;
}

export interface IntentSignatureRequestStartRevision {
  signatureRequestId: string;
}

export interface IntentSignatureRequestCancelRevision {
  signatureRequestId: string;
}

/** Updates the `role` and `contact` fields of a Party. */
export interface IntentUpdatePartyContactDetails {
  partyId: string;
  contact?: Contact;
  roles: PartyRole[];
  ignoreContactSuggestion: boolean;
  ObsoleteParty?: Item;
  promoteToPrimaryAgent: boolean;
  teamId: string;
  clientVisibility: PartyClientVisibility;
}

export interface IntentUpdatePartyContactSource {
  partyId: string;
  contactSource?: ContactSource;
}

export interface IntentPartyPatch {
  partyId: string;
  contact?: Contact;
  roles: PartyRole[];
  mask?: string[];
  teamId: string;
  clientVisibility: PartyClientVisibility;
}

export interface IntentPartyPatches {
  patches: IntentPartyPatch[];
}

export interface IntentReplacePrimaryAgent {
  /** party id of the new agent */
  partyId: string;
}

export interface IntentAcceptCompassTos {
  inviteUuid: string;
  accept: boolean;
  updateInvite: boolean;
}

/** Binds a `Party` to a `User`. */
export interface IntentBindParty {
  partyId: string;
  userId: string;
}

export interface IntentUpdateTransactionMeta {
  data?: TransactionMeta;
}

/** Update the transaction info objects. */
export interface IntentUpdateTransactionInfo {
  propertyInfo?: Item;
  listingInfo?: Item;
  purchaseInfo?: Item;
  fieldValues?: { [key: string]: any };
}

export interface IntentUpdateCoverPhoto {
  url: string;
}

export interface IntentGetOrCreateTimelineSetupFlow {
  mode: IntentGetOrCreateTimelineSetupFlowMode;
}

export const IntentGetOrCreateTimelineSetupFlowMode = {
  SET_UP: 'SET_UP',
  SHARE: 'SHARE',
  SYNC: 'SYNC',
} as const;

export type IntentGetOrCreateTimelineSetupFlowMode =
  typeof IntentGetOrCreateTimelineSetupFlowMode[keyof typeof IntentGetOrCreateTimelineSetupFlowMode];

export interface IntentAddTimelineDocuments {
  fileUploads: ItemTransactionDocumentVersionSourceUpload[];
  tdvIds: string[];
  dsDocs: ItemTransactionDocumentVersionSourceDocusign[];
  dsSub: string;
}

export interface IntentRemoveTimelineDocuments {
  tdvId: string;
}

export interface IntentUpdateTimelineDate {
  keyDates: KeyDate[];
}

export interface IntentUpdateKeyDateCompletionStatus {
  uuid: string;
  isComplete: boolean;
}

export interface IntentSendTimelineMessages {
  message?: IntentSendTimelineMessagesMessage;
}

export interface IntentSendTimelineMessagesMessage {
  recipients: Contact[];
  cc: Contact[];
  bcc: Contact[];
  subject: string;
  body: string;
  includedKeyDates: string[];
  includeParties: boolean;
  includedPartyIds: string[];
  includeDates: boolean;
  attachedTdIds: string[];
  senderContact?: Contact;
  externalEmail?: IntentSendTimelineMessagesMessageExternalEmail;
  isPreview: boolean;
  fromEmail: string;
}

export interface IntentSendTimelineMessagesMessageExternalEmail {
  app: string;
  tokenId: string;
}

export interface IntentSendKeyDateReminders {
  keyDateUuid: string;
}

export interface IntentSplitTimelineDocuments {
  splits: ItemTransactionDocumentVersionSourceSplit[];
}

export interface IntentRemoveTimelineDate {
  uuid: string;
}

export interface IntentDetectTimelineForms {}

export interface IntentSyncTimelineDates {
  calendarSync?: ItemTimelineCalendarSync;
}

export interface IntentUpdateTimelineTeam {
  team: Recipient[];
}

export interface IntentOBSOLETEUpdateTimelineLogo {
  logoUrl: string;
}

export interface IntentUpdateTimelineSetup {
  setupStatus: ItemTimelineSetupStatus;
}

export interface IntentFinalizeTimelineSetup {
  invitees: Recipient[];
}

export interface IntentOBSOLETEPrepareTimelineSetup {}

export interface IntentOBSOLETECompleteTimelineSetup {}

export interface IntentOBSOLETEInitializeTimelineDates {}

export interface IntentUpdateTemplateSetting {
  status: ItemTemplateSettingStatus;
  title: string;
}

export interface IntentOBSOLETEMergeTransaction {}

export interface IntentOBSOLETERemoveParty {
  partyId: string;
}

export interface IntentPartyRemoves {
  removes: IntentPartyRemovesRemove[];
}

export interface IntentPartyRemovesRemove {
  partyId: string;
}

export interface IntentCreateLinkZfFlow {
  back?: Route;
}

export interface IntentGetOrCreateOrderNhdFlow {
  taskId: string;
}

export interface IntentCreatePickNhdProviderFlow {
  setupPartyId: string;
  setupMessage: string;
}

export interface IntentDeclineNhdOrder {
  taskId: string;
  reason: string;
}

export interface IntentCreateNhdOrder {
  nhdId: string;
  reportType: ItemNhdReportType;
  provider: ItemNhdProvider;
  vendorCode: string;
  promoVersion: number;
  orderDetails?: ItemNhdOrderDetails;
}

export interface IntentCompleteNhdOrder {
  nhdOrderId: string;
  docUrls: string[];
}

export interface IntentCheckNhdReportOutput {
  nhdId: string;
}

export interface IntentUpdateNhdOrderStatus {
  nhdId: string;
}

export interface IntentCancelNhdOrder {}

export interface IntentResetNhdOrder {
  nhdId: string;
}

export interface IntentRevertNhdOrderedAt {
  nhdId: string;
}

export interface IntentGetOrCreateZfImportDocFlow {
  taskId: string;
}

export interface IntentCreateGuidedFormPacket {
  title: string;
  formFlowsIds: string[];
  emailSubject: string;
  emailBody: string;
  flowId: string;
  isDebug: boolean;
  recipients: Recipient[];
  creationMode: number;
  senderPartyId: string;
  isDemo: boolean;
  taskId: string;
  dppAgentComment: string;
  gfpEndTestType: string;
  psd?: ItemGuidedFormPacketPsd;
  psdAgentComment: string;
  fillAndSignConfig?: FillAndSignConfig;
}

export interface IntentOBSOLETEUpdateGfpPdf {
  gfpfId: string;
  documentId: string;
  statusVersion: string;
}

export interface IntentOBSOLETEUpdatePacketsArchivalStatus {
  updates: IntentOBSOLETEUpdatePacketsArchivalStatusArchivalStatus[];
}

export interface IntentOBSOLETEUpdatePacketsArchivalStatusArchivalStatus {
  packetId: string;
  archived: boolean;
}

export interface IntentUpdatePacketCoverSheet {
  coverSheet?: ItemCoverSheet;
  escrow?: ItemPurchaseInfoEscrow;
  brokerLogoUrl: string;
}

/** Create a Guided Form Preview */
export interface IntentCreateGuidedFormPreview {
  formFlowConfigId: string;
}

export interface IntentCreateGfpCreationFlow {
  back: IntentCreateGfpCreationFlowBackMode;
  taskId: string;
  ffcIds: string[];
  redirectToRoute: string;
}

export const IntentCreateGfpCreationFlowBackMode = {
  TRANSACTION: 'TRANSACTION',
  TICKETS: 'TICKETS',
  TRANSACTION_HOME: 'TRANSACTION_HOME',
  SERVICES: 'SERVICES',
} as const;

export type IntentCreateGfpCreationFlowBackMode =
  typeof IntentCreateGfpCreationFlowBackMode[keyof typeof IntentCreateGfpCreationFlowBackMode];

export interface IntentGetOrCreateInitialAVIDFlow {
  taskId: string;
}

export interface IntentCreateAvidGenerationFlow {
  taskId: string;
}

export interface IntentCreateUpdateAvidFillPartyFlow {
  taskId: string;
}

export interface IntentUpdateAvidStage {
  stage: ItemTaskAvidStage;
  taskId: string;
  avidId: string;
  flowId: string;
  status: ItemAvidFlowStatus;
}

export interface IntentCreateAvidFillFlows {
  fillRole: PartyRole;
  taskId: string;
  completionMode: string;
  isMultiUnit: boolean;
  unitDetails: IntentCreateAvidFillFlowsUnitDetail[];
  superFlowDetails?: ItemAvidSuperFlowDetails;
  agentInviteMessage?: ItemAvidInviteMessage;
}

export interface IntentCreateAvidFillFlowsUnitDetail {
  number: string;
  documentUrl: string;
  formTag: string;
}

export interface IntentGetOrCreateOffer {
  offerId: string;
  formIds: string[];
  tdIds: string[];
  setupFlowId: string;
  pendingActionSetupType: ItemTransactionPackagePendingActionSetupType;
  syncedToListingSideTxnId: string;
  propertyInfoId: string;
}

export interface IntentInitializePurchaseSideOffer {
  syncedToListingSideTxnId: string;
  propertyInfoId: string;
}

export interface IntentSetOfferReadyToSubmit {
  offerId: string;
}

export interface IntentUpdateOfferArchivalStatus {
  offerId: string;
  archived: boolean;
}

export interface IntentUpdateOfferFavoriteStatus {
  offerId: string;
  favorite: boolean;
}

export interface IntentSetOfferNotes {
  offerId: string;
  privateNotes: string;
  publicNotes: string;
}

export interface IntentSetOffersSortIndices {
  offersSortIndices: IntentSetOffersSortIndicesOfferSortIndex[];
}

export interface IntentSetOffersSortIndicesOfferSortIndex {
  offerId: string;
  sortIndex: number;
}

export interface IntentUpdateOfferSubmissionPreferences {
  submissionPreferences?: ItemTransactionAppOffersSubmissionPreferences;
}

export interface IntentUpdateOffersClientWorkspaceConfig {
  enabled: boolean;
  hideBuyersInfo: boolean;
  sendInviteToExistingSellers: boolean;
  newSellers: string[];
  message: string;
}

export interface IntentSetClientOffersSeen {
  offerIds: string[];
}

export interface IntentDetectPackageForms {
  packageId: string;
  flowId: string;
}

export interface IntentUpdatePackageOcrStatus {
  packageId: string;
  ocrStatus: PackageOcrStatus;
}

export interface IntentUpdateOfferSubmittedDate {
  offerId: string;
  submittedTs: number;
}

export interface IntentUpdateBuySideKeyTermMeta {
  offerId: string;
  keyTerms: ItemOfferKeyTerm[];
}

export interface IntentCompleteAVID {
  flowId: string;
  flowVers: string;
  zfTokenId: string;
  zfTxnId: string;
  generateFlowId: string;
  avidId: string;
}

export interface IntentInviteToAvid {
  flowId: string;
  subject: string;
  message: string;
  partyId: string;
  taskId: string;
  isTcInvite: boolean;
  isResend: boolean;
}

export interface IntentInviteAgentToCompleteAvid {
  taskId: string;
  agentInviteMessage?: ItemAvidInviteMessage;
  fillRole: PartyRole;
  isAuto: boolean;
}

export interface IntentUpdateGuidedFormPacketFlowStatus {
  gfpfId: string;
  status: ItemGuidedFormPacketFlowStatus;
  flowVers: string;
}

export interface IntentUpdateGuidedFormPacketStatus {
  gfpId: string;
  status: ItemGuidedFormPacketStatus;
  vers: string;
  documentIdByGfpfId: { [key: string]: string };
  mergedDocumentId: string;
  skipEmail: boolean;
}

export interface IntentUpdateGuidedFormPacketStatusDocumentIdByGfpfIdEntry {
  key: string;
  value: string;
}

/** Sends out the GFP submitted emails with PDF attachments. */
export interface IntentGfpGenerate {
  gfpId: string;
  vers: string;
  zfTxnId: string;
  zfTokenId: string;
  reviewFlowId: string;
  skipGfpfIds: string[];
  isIncomplete: boolean;
  didSubmit: boolean;
  parallel: boolean;
  mode: IntentGfpGenerateMode;
  generateFlowId: string;
  ObsoleteCertificate: boolean;
  ObsoleteForceGfpfIds: string[];
}

export const IntentGfpGenerateMode = {
  MANUAL: 'MANUAL',
  AUTO_ON_SUBMIT: 'AUTO_ON_SUBMIT',
} as const;

export type IntentGfpGenerateMode =
  typeof IntentGfpGenerateMode[keyof typeof IntentGfpGenerateMode];

/** Creates the GFP review flow and chore. */
export interface IntentStartReviewGfp {
  gfpId: string;
  vers: string;
  createChore: boolean;
  skipEmail: boolean;
  allowIncomplete: boolean;
  didSubmit: boolean;
}

/**
 * Submits a GFP.
 *
 * Following this, forms still need to be reviewed and then PDFs generated
 * (ie. via ZipForm).
 */
export interface IntentGfpSubmit {
  gfpId: string;
  skipEmail: boolean;
  skipChore: boolean;
  allowAuto: boolean;
}

/** Creates a flow for the purposes of generating a GFP. */
export interface IntentGfpStartGenerateFlow {
  gfpId: string;
  allowIncomplete: boolean;
}

/**
 * Send an email notifying the agent of flags
 * at a time other than .
 */
export interface IntentGfpSendFlagNotification {
  gfpId: string;
}

/**
 * Intent that deletes items.
 *
 * We have one intent to delete all items so that we can
 * have a proper chokepoint for this dangerous operation.
 */
export interface IntentDeleteItems {
  /** A list of item ids to delete. */
  ids: string[];
}

/** Resend invite emails to recipients of a GuidedFormPacket. */
export interface IntentResendGfpInvite {
  /** The `GuidedFormPacket` item id. */
  gfpId: string;
  partyId: string;
}

/** Updates the recipients of a GuidedFormPacket. */
export interface IntentUpdateGfpRecipients {
  /** The `GuidedFormPacket` item id. */
  gfpId: string;
  recipients: Recipient[];
  ObsoleteCc: EmailAddress[];
}

export interface IntentGfpCheckAndRemind {
  /** The `GuidedFormPacket` item id. */
  gfpId: string;
}

/**
 * Reminder the creator of GFP packet
 * that client has not yet started filling out the
 * form
 */
export interface IntentSendGFPNotStartedReminder {
  /** The `GuidedFormPacket` item id. */
  gfpId: string;
}

/** Records fact that party signed the GFP tutorial legal doc/T&Cs. */
export interface IntentGfpSignAgreement {
  gfpId: string;
  signature?: SignatureRecord;
  flowId: string;
  tags: string[];
  /**
   * Set this to true to NOOP if there already exists a signature record
   * matching the doc name, version and user.
   */
  dedupeDoc: boolean;
}

/** Records fact that party signed the GFP tutorial legal doc/T&Cs. */
export interface IntentGfpSkipAgreement {
  gfpId: string;
  notValidateTos: boolean;
}

/** Creates a tutorial flow for GFP. */
export interface IntentGfpStartTutorial {
  gfpId: string;
}

export interface IntentGfpTermsAccepted {
  packetId: string;
  didAcceptTerms: boolean;
}

export interface IntentPreviewFlowPdf {
  flowId: string;
  isAgent: boolean;
}

export interface IntentGfpSetLocked {
  gfpId: string;
  locked: boolean;
}

/** Resend invite emails to recipients of a GuidedFormPacket. */
export interface IntentGfpRecordComment {
  /** The `GuidedFormPacket` item id. */
  addCommentIds: string[];
  gfpId: string;
  notify: boolean;
  clear: boolean;
  removeCommentIds: string[];
  lastCommentNotifiedVers: string;
}

export interface IntentGfpRemoveGuidedFormPacketFlow {
  gfpfId: string;
}

export interface IntentGfpCreateAddFormsFlow {
  gfpId: string;
  ffcIds: string[];
}

export interface IntentGfpAddForms {
  gfpId: string;
  formFlowIds: string[];
}

export interface IntentGfpAccessInPerson {
  gfpId: string;
}

export interface IntentGfpUploadFlowAttachments {
  newAttachments: ItemTransactionDocumentVersionSourceUpload[];
  attachmentIds: string[];
  gfpfId: string;
  fieldId: string;
}

export interface IntentGfpGetSubmitFlow {
  gfpId: string;
}

export interface IntentGfpPremiumUpgrade {
  gfpId: string;
  stripeSourceId: string;
  dppOrderNum: string;
  inFlow: boolean;
  trigger: string;
  payLater: boolean;
}

export interface IntentGfpCompleteSurvey {
  gfpId: string;
  satisfaction: string;
  usefulness: string;
  extra: string;
  declineReason: string;
}

export interface IntentGfpPremiumLateBillingCharge {
  gfpId: string;
  stripeSourceId: string;
}

export interface IntentUpdateGfpfFlow {
  gfpfId: string;
  flowId: string;
}

export interface IntentGetOrCreateFormBuilderFlow {
  tdId: string;
}

/** Create multiple labels. */
export interface IntentCreateLabels {
  labels: ItemItemLabel[];
}

/** Update multiple labels. */
export interface IntentUpdateLabels {
  labels: Item[];
}

/** Add or remove labels from items. */
export interface IntentApplyLabels {
  updates: IntentApplyLabelsUpdate[];
}

export interface IntentApplyLabelsUpdate {
  /** The item for which we want to add/remove labels. */
  itemId: string;
  addLabelsIds: string[];
  removeLabelsIds: string[];
}

export interface IntentCreateSharePacket {}

export interface IntentPublishSharePacket {
  notifyParties: boolean;
  sharePacketId: string;
  message: string;
}

export interface IntentGenerateCoverSheet {
  vers: string;
  generateLatest: boolean;
}

export interface IntentUpdateSharePacketPublication {
  publicationId: string;
  allDocumentsZipId: string;
  allDocumentsPdfId: string;
}

/** Request access to a share packet. */
export interface IntentRequestSharePacketAccess {
  packetId: string;
  accessType: ItemSharePacketAccessTypeAccessType;
  applicantFirstName: string;
  applicantLastName: string;
  applicantEmail: string;
  applicantPhone: string;
}

/** A transaction party can directly share a share packet. */
export interface IntentInviteToSharePacket {
  packetId: string;
  accessType: ItemSharePacketAccessTypeAccessType;
  /**
   * If the packet is being forwarded by a person with whom
   * it has already been shared.
   */
  forwarderUserId: string;
  isAnonymized: boolean;
  recipients: Recipient[];
  message: string;
}

export interface IntentSendSharePacketMessage {
  sharedItemIds: string[];
  subject: string;
  body: string;
  sharePacketId: string;
  fromPartyId: string;
}

export interface IntentUpdateSharePacketAccessStatus {
  sharePacketId: string;
  accessStatus: ItemSharePacketAccessStatus;
}

export interface IntentRejectSharedItemRequest {
  requestId: string;
}

export interface IntentApproveSharedItemRequest {
  requestId: string;
}

export interface IntentRevokeSharedItemAccess {
  /** Deprecated */
  sharedItemId: string;
  sharedItemIds: string[];
  reason: string;
}

export interface IntentClaimSharedItem {
  sharedItemId: string;
  userId: string;
}

export interface IntentBackfill {
  itemBackfills: IntentBackfillItemBackfill[];
  message: string;
}

export interface IntentBackfillItemBackfill {
  item?: Item;
  associateEdges: ItemEdge[];
  disassociateEdges: ItemEdge[];
}

export interface IntentBackfillScript {
  message: string;
}

export interface IntentVoidTransaction {
  message: string;
}

export interface IntentUnvoidTransaction {
  message: string;
}

export interface IntentUpdateSharePacketLinkShareMode {
  sharePacketId: string;
  linkShareMode: ItemSharePacketLinkShareMode;
  notifyVisitorAccess: boolean;
}

export interface IntentRecordEmailEvents {
  events: EmailEvent[];
}

export interface IntentOBSOLETEEmailEvent {
  eventId: string;
  event?: EmailMessageEvent;
  messageId: string;
  message?: EmailMessage;
  previous?: IntentOBSOLETEEmailEventMessageStatus;
}

export interface IntentOBSOLETEEmailEventMessageStatus {
  status: string;
  statusAt: number;
  eventId: string;
}

export interface IntentOBSOLETESubmitGuidedFormPacketFlow {
  gfpfId: string;
}

export interface IntentOBSOLETEStartGuidedFormPacketFlow {
  gfpfId: string;
}

export interface IntentOBSOLETECompleteGuidedFormPacketFlow {
  gfpfId: string;
  documentId: string;
  flowVers: string;
  data?: { [key: string]: any };
}

export interface IntentUpdateTransactionGlideFields {
  glideFields?: TransactionMetaGlideFields;
}

export interface IntentSetTransactionFields {
  fieldValues?: { [key: string]: any };
  namespace: string;
  itemIds: string[];
  mergeStrategies: { [key: string]: FieldsMergeStrategyEnum };
  controlTimestamps?: { [key: string]: any };
  controlPolicy: FieldsControlPolicyEnum;
}

export interface IntentSetTransactionFieldsMergeStrategiesEntry {
  key: string;
  value: FieldsMergeStrategyEnum;
}

export interface IntentUpdateTransactionFields {
  fieldValuesByNamespace: { [key: string]: { [key: string]: any } };
  itemIdByNamespace: { [key: string]: string };
}

export interface IntentUpdateTransactionFieldsFieldValuesByNamespaceEntry {
  key: string;
  value?: { [key: string]: any };
}

export interface IntentUpdateTransactionFieldsItemIdByNamespaceEntry {
  key: string;
  value: string;
}

export interface IntentCreateInvoice {
  invoice?: ItemInvoice;
}

export interface IntentUpdateInvoice {
  invoice?: Item;
}

export interface IntentSetZfLink {
  tokenId: string;
  txnId: string;
  /**
   * allow_no_txn can be set to link a ZF account with the txn, without linking it
   * to any specific ZF txn
   */
  allowNoTxn: boolean;
}

export interface IntentAddActivity {
  activity?: Activity;
}

export interface IntentRecordComment {
  itemId: string;
  notify: boolean;
  clientId: string;
  inviteSubject: string;
  inviteBody: string;
  message: string;
}

export interface IntentInitializeBrokerageSettings {}

export interface IntentSendEmail {
  fromPartyId: string;
  to: EmailAddress[];
  subject: string;
  body: string;
}

export interface IntentSellerDiscAppSetCurrStep {
  currentStepId: string;
}

/** Gets or creates a Super Flow for the Workflows step. */
export interface IntentSellerDiscAppCreateWfSf {}

export interface IntentStartChore {
  choreId: string;
}

export interface IntentHideChore {
  choreId: string;
  userId: string;
}

export interface IntentOBSOLETEGetOrCreateCommissionAdvanceFlow {
  taskId: string;
}

export interface IntentCreateCommsDocument {
  /**
   * This is a ripple from the way the activity log filter works. An empty
   * party_ids array is a valid filter and basically means "show nothing" instead
   * of "dont filter".
   */
  allParties: boolean;
  partyIds: string[];
  taskId: string;
}

export interface IntentSendSsoReminder {
  provider: LinkedAccountKind;
}

export interface IntentSyncPackageDocuments {
  packageId: string;
  documents: IntentSyncPackageDocumentsDocument[];
}

export interface IntentSyncPackageDocumentsDocument {
  tdId: string;
  fileUpload?: ItemTransactionDocumentVersionSourceUpload;
  data?: { [key: string]: any };
  title: string;
}

export interface IntentLinkTransactionPackageToPackage {
  linkages: IntentLinkTransactionPackageToPackageLinkage[];
  DeprecatedPackageId: string;
  DeprecatedTransactionPackageId: string;
  DeprecatedForcePackageSideId: string;
  DeprecatedSync: boolean;
  DeprecatedMigrateActivitiesFromOtherSide: boolean;
}

export interface IntentLinkTransactionPackageToPackageLinkage {
  packageId: string;
  transactionPackageId: string;
  migratePackage?: IntentLinkTransactionPackageToPackageLinkageMigratePackage;
}

export interface IntentLinkTransactionPackageToPackageLinkageMigratePackage {
  packageSideId: string;
  /**
   * Set to True to sync transaction package with latest package action
   * right after linkage
   */
  sync: boolean;
  /**
   * Set to True to migrate package access activities that ocurred before
   * this side's transaction package was created. The activities are
   * migrated from the originating side's transaction package activities.
   */
  migrateActivitiesFromOtherSide: boolean;
}

export interface IntentVoidOfferPackage {
  offerPackageId: string;
  reason: string;
}

export interface IntentSyncPackageAction {
  actionId: string;
  selfSide: boolean;
  notify: boolean;
  tdPdMap?: { [key: string]: any };
  detachedPackageStatus: PackageStatus;
  muteActivities: boolean;
}

export interface IntentStartPackageActionFlow {
  packageId: string;
  actionType: ItemActionType;
  skipWarnings: boolean;
  revision: boolean;
  skipNotifications: boolean;
  keyTermsOnly: boolean;
  ocrFields?: { [key: string]: any };
}

export interface IntentCreatePackagePendingAction {
  transactionPackageId: string;
  actionType: ItemActionType;
  data?: { [key: string]: any };
  detached: boolean;
  revision: boolean;
  setUuid: boolean;
  actionSetupFlowId: string;
  isObo: boolean;
  documents: IntentSyncPackageDocumentsDocument[];
  replaceTdIds: string[];
}

export interface IntentCancelPackagePendingAction {
  transactionPackageId: string;
}

export interface IntentSetPackageSetupType {
  transactionPackageId: string;
  setupType: ItemTransactionPackagePendingActionSetupType;
  setupFlowId: string;
}

export interface IntentSetPackageCounterKind {
  transactionPackageId: string;
  counterKind: ItemActionCounterKind;
}

export interface IntentRegisterPackageAccessActivity {
  transactionPackageId: string;
  actionType: ItemActionType;
  accessLogEntry?: ItemActionAccessLogEntry;
}

export interface IntentRecordPackageAccessed {
  transactionPackageIds: string[];
}

export interface IntentCreatePackagePendingTds {
  transactionPackageId: string;
  tdIds: string[];
}

export interface IntentExecutePendingAction {
  transactionPackageId: string;
  data?: { [key: string]: any };
  skipNotifications: boolean;
}

export interface IntentUpdateSubmittedActionData {
  transactionPackageId: string;
  messageSent: boolean;
  messageFailed: boolean;
  messageFailedDetails: string;
  messageId: string;
  actionId: string;
  emailApp: string;
  recipientsStatus: {
    [key: string]: ItemTransactionPackageSubmittedActionRecipientStatus;
  };
  actionType: ItemActionType;
  counterKind: ItemActionCounterKind;
  recordActivity: boolean;
  messageFailedCode: number;
}

export interface IntentUpdateSubmittedActionDataRecipientsStatusEntry {
  key: string;
  value?: ItemTransactionPackageSubmittedActionRecipientStatus;
}

export interface IntentEmitTransactionPackageEvent {
  event: string;
  actionType: ItemActionType;
  actionId: string;
  transactionPackageId: string;
}

export interface IntentCreateBuyerProspectProperty {
  addressId: string;
}

export interface IntentUpdateBuyerProspectPropertyFields {
  buyerProspectPropertyId: string;
  fieldValues?: { [key: string]: any };
}

/** Esign Detection ----------------------------------------------- */
export interface IntentEsignDetectionProvideUserFeedback {
  documentId: string;
  zoneFeedbacks: IntentEsignDetectionProvideUserFeedbackZoneFeedback[];
}

export interface IntentEsignDetectionProvideUserFeedbackZoneFeedback {
  zoneId: string;
  feedback: SignatureDetectionResultUserFeedback;
}

export interface IntentOBSOLETEUpdateBrokerSyncAccount {}

export interface IntentOBSOLETECreateImportChecklistFlow {}

export interface IntentOBSOLETECreateTimelineTask {
  back?: RouteLink;
}

export interface IntentOBSOLETEFinishImportChecklistFlow {}

export interface IntentOBSOLETEReconcileSyncChecklist {}

export interface IntentOBSOLETEFetchBrokerTransactionList {}

export interface IntentOBSOLETEFetchBrokerChecklist {}

export interface IntentOBSOLETEProcessTransactionListPullResponse {}

export interface IntentOBSOLETEProcessChecklistPullResponse {}

export interface IntentOBSOLETEProcessChecklistPushResponse {}

export interface IntentOBSOLETEAssignTeam {
  teamId: string;
}

export interface IntentOBSOLETEGfpTermsAccepted {
  packetId: string;
  didAcceptTerms: boolean;
}

export interface IntentOBSOLETECreateInviteToAVIDFlow {}

export interface IntentOBSOLETEResendSharePacketInvite {
  sharedItemId: string;
}

export interface IntentOBSOLETECreateDiscPackageSuperFlow {
  firstFlow: IntentOBSOLETECreateDiscPackageSuperFlowFirstFlow;
}

export const IntentOBSOLETECreateDiscPackageSuperFlowFirstFlow = {
  DEFAULT: 'DEFAULT',
  AVID: 'AVID',
} as const;

export type IntentOBSOLETECreateDiscPackageSuperFlowFirstFlow =
  typeof IntentOBSOLETECreateDiscPackageSuperFlowFirstFlow[keyof typeof IntentOBSOLETECreateDiscPackageSuperFlowFirstFlow];

export interface IntentOBSOLETEToggleOffersClientWorkspace {
  enabled: boolean;
  sendInviteToExistingSellers: boolean;
  message: string;
}

export interface IntentOBSOLETEUpdateTransactionSettings {
  forwardEmailAddresses: string[];
  title: string;
  isTest: boolean;
  ownerId: string;
}

export interface IntentOBSOLETESyncOffersToListing {
  sourceTransactionId: string;
  sourceOfferIds: string[];
  ObsoleteSourceOfferIds: string;
}

export interface IntentOBSOLETEUpdateOfferStatus {
  offerId: string;
  status: ItemOfferStatus;
}

export interface IntentOBSOLETESyncOfferFlow {
  offerId: string;
  flowId: string;
  offerData?: { [key: string]: any };
}

export interface IntentOBSOLETESyncOfferDocuments {
  offerId: string;
  flowId: string;
  fileUploads: ItemTransactionDocumentVersionSourceUpload[];
}

export interface IntentOBSOLETEGetOrCreateOfferFlow {
  offerId: string;
}

export interface PartyInviteRequest {
  OBSOLETEUserContactId: string;
  OBSOLETEUserContactSource?: ContactSource;
  invite: boolean;
  subject: string;
  body: string;
  /**
   * Set this to True to invite someone to the transaction without sending an invite email
   * This will add BROKERAGE_ONLY restriction so it's only possible if an ACTIVE
   * user who is a member of the same brokerage the tranasction belongs to owns the email. It will
   * always fail if the tranasction does not belong to a brokerage
   */
  suppressInviteEmail: boolean;
  inviteRestrictions: PartyInviteRequestInviteRestriction[];
  party?: Party;
  contact?: Contact;
  partyId: string;
  roles: PartyRole[];
  /**
   * Set unbound to create the party item without adding the party to the transaction.
   * Setting this overrides "bool invite = 6"'s value (cannot invite if unbound)
   */
  unbound: boolean;
  linkedItems?: PartyInviteRequestLinkedItems;
  promoteToPrimaryAgent: boolean;
  teamId: string;
  clientVisibility: PartyClientVisibility;
}

export const PartyInviteRequestInviteRestriction = {
  NONE: 'NONE',
  /**
   * EXISTING_USER - Set this restriction to only allow inviting existing
   * ACTIVE user (requires a value on contact.email)
   */
  EXISTING_USER: 'EXISTING_USER',
  /**
   * BROKERAGE_ONLY - Set this restriction to only allow inviting existing
   * ACTIVE user that's a member of the brokerage the transcation
   * belongs to (requires a value on contact.email)
   * It will always fail if the tranasction does not belong to a brokerage
   */
  BROKERAGE_ONLY: 'BROKERAGE_ONLY',
} as const;

export type PartyInviteRequestInviteRestriction =
  typeof PartyInviteRequestInviteRestriction[keyof typeof PartyInviteRequestInviteRestriction];

export interface PartyInviteRequestLinkedItems {
  transactionPackage: string[];
  buyerProspectProperty: string[];
  propertyInfo: string[];
}

export interface Recipient {
  kind: RecipientKind;
  party?: RecipientPartyRecipient;
  userContact?: RecipientUserContactRecipient;
  newContact?: RecipientNewContactRecipient;
  key: string;
  recipientRole: string;
  order: number;
}

export const RecipientKind = {
  UNKNOWN: 'UNKNOWN',
  PARTY: 'PARTY',
  USER_CONTACT: 'USER_CONTACT',
  NEW_CONTACT: 'NEW_CONTACT',
} as const;

export type RecipientKind = typeof RecipientKind[keyof typeof RecipientKind];

export interface RecipientPartyRecipient {
  contact?: Contact;
  roles: PartyRole[];
  id: string;
  vers: string;
}

export interface RecipientUserContactRecipient {
  contact?: Contact;
  /**
   * We've started to decouple recipients from parties with the 'OTHER' role.
   * If more are added we may want to create a Recipient.Role instead
   */
  roles: PartyRole[];
  userContactId: string;
  userContactSource?: ContactSource;
  userId: string;
}

export interface RecipientNewContactRecipient {
  contact?: Contact;
  roles: PartyRole[];
}

export interface ItemEdge {
  kind: ItemEdgeKind;
  item1Id: string;
  item1?: Item;
  item2Id: string;
  item2?: Item;
  data?: { [key: string]: any };
  taskAssignedTd?: ItemEdgeTaskAssignedTd;
  fileReviewHasChecklistItem?: ItemEdgeFileReviewHasChecklistItem;
  tdHasOriginalTd?: ItemEdgeTdHasOriginalTd;
  propertyHasFolder?: ItemEdgePropertyHasFolder;
  propertyCreatedTd?: ItemEdgePropertyCreatedTd;
  createdAt: number;
}

export const ItemEdgeKind = {
  /** IN_MILESTONE - task -> milestone */
  IN_MILESTONE: 'IN_MILESTONE',
  /** HAS_TASK - milestone -> task */
  HAS_TASK: 'HAS_TASK',
  /** TDV_HAS_SIGNATURE_REQUEST - transaction document version -> signature request */
  TDV_HAS_SIGNATURE_REQUEST: 'TDV_HAS_SIGNATURE_REQUEST',
  /** FOLDER_HAS_DOCUMENT - folder -> transaction document */
  FOLDER_HAS_DOCUMENT: 'FOLDER_HAS_DOCUMENT',
  /** FOLDER_ONE_WAY_SYNC - source folder -> target folder */
  FOLDER_ONE_WAY_SYNC: 'FOLDER_ONE_WAY_SYNC',
  /** POSTS_DATES_TO_TIMELINE - any -> timeline */
  POSTS_DATES_TO_TIMELINE: 'POSTS_DATES_TO_TIMELINE',
  /** TD_HAS_TDV - transaction_document -> transaction_document_version */
  TD_HAS_TDV: 'TD_HAS_TDV',
  /** MERGE_ITEM - transaction merge -> any */
  MERGE_ITEM: 'MERGE_ITEM',
  /** PACKET_HAS_CHILD - packet -> child item (ie. gfp, sig request etc.) */
  PACKET_HAS_CHILD: 'PACKET_HAS_CHILD',
  /** PACKET_HAS_FOLDER - packet -> folder */
  PACKET_HAS_FOLDER: 'PACKET_HAS_FOLDER',
  /** GFP_HAS_GFPF - Guided form packet -> guided form packet form */
  GFP_HAS_GFPF: 'GFP_HAS_GFPF',
  /** ITEM_SHARED_AS - Item -> SharedItem */
  ITEM_SHARED_AS: 'ITEM_SHARED_AS',
  /** ITEM_HAS_SHARE_REQUESTS - Item -> SharedItemRequest */
  ITEM_HAS_SHARE_REQUESTS: 'ITEM_HAS_SHARE_REQUESTS',
  /** SHARE_PACKET_PUBLICATION_HAS_TDV - SharePacketVersion -> TDV */
  SHARE_PACKET_PUBLICATION_HAS_TDV: 'SHARE_PACKET_PUBLICATION_HAS_TDV',
  /** SHARE_PACKET_HAS_PUBLICATION - SharePacket -> SharePacketPublication */
  SHARE_PACKET_HAS_PUBLICATION: 'SHARE_PACKET_HAS_PUBLICATION',
  /** SHARE_PACKET_HAS_FOLDER - share packet -> folder */
  SHARE_PACKET_HAS_FOLDER: 'SHARE_PACKET_HAS_FOLDER',
  CHORE_OBSERVES_ITEM: 'CHORE_OBSERVES_ITEM',
  TIMELINE_HAS_DATE: 'TIMELINE_HAS_DATE',
  /** TASK_HAS_TASK - task -> task */
  TASK_HAS_TASK: 'TASK_HAS_TASK',
  /** ITEM_HAS_LABEL - item -> label */
  ITEM_HAS_LABEL: 'ITEM_HAS_LABEL',
  /** TASK_ASSIGNED_TD - task -> td */
  TASK_ASSIGNED_TD: 'TASK_ASSIGNED_TD',
  /** TASK_DATE_DEPENDENCY - task -> task */
  TASK_DATE_DEPENDENCY: 'TASK_DATE_DEPENDENCY',
  /** PURCHASE_INFO_HAS_KEY_DATE - purchase info -> task */
  PURCHASE_INFO_HAS_KEY_DATE: 'PURCHASE_INFO_HAS_KEY_DATE',
  /** TASK_ASSIGNED_PARTY - task -> party */
  TASK_ASSIGNED_PARTY: 'TASK_ASSIGNED_PARTY',
  /** TASK_DEPENDENCY - task -> task */
  TASK_DEPENDENCY: 'TASK_DEPENDENCY',
  /** TASK_FOLLOWER - task -> follower */
  TASK_FOLLOWER: 'TASK_FOLLOWER',
  /** CALENDAR_ATTENDEE - item -> party */
  CALENDAR_ATTENDEE: 'CALENDAR_ATTENDEE',
  /** TASK_HAS_MEMBER - task -> item */
  TASK_HAS_MEMBER: 'TASK_HAS_MEMBER',
  /** INVOICE_GENERATED_TD - - Invoice 200-219 ======================================= */
  INVOICE_GENERATED_TD: 'INVOICE_GENERATED_TD',
  /** INVOICE_HAS_TASK - invoice -> invoice task */
  INVOICE_HAS_TASK: 'INVOICE_HAS_TASK',
  /** TD_HAS_SIGNATURE_REQUEST - transaction document version -> signature request */
  TD_HAS_SIGNATURE_REQUEST: 'TD_HAS_SIGNATURE_REQUEST',
  /** TD_HAS_ORIGINAL_TD - transaction_document -> transaction_document */
  TD_HAS_ORIGINAL_TD: 'TD_HAS_ORIGINAL_TD',
  /** ITEM_HAS_FIELD_VALUE - - FIELDS 320-339 ======================================= */
  ITEM_HAS_FIELD_VALUE: 'ITEM_HAS_FIELD_VALUE',
  /** FILE_REVIEW_HAS_CHECKLIST_ITEM - - FILE_REVIEW 340-349 ================================== */
  FILE_REVIEW_HAS_CHECKLIST_ITEM: 'FILE_REVIEW_HAS_CHECKLIST_ITEM',
  /** PEAD_HAS_TD - - PEAD 350-354 ================================== */
  PEAD_HAS_TD: 'PEAD_HAS_TD',
  PEAD_HAS_SIGNATURE_REQUEST: 'PEAD_HAS_SIGNATURE_REQUEST',
  /** OFFER_HAS_FOLDER - - OFFERS 355-364 ================================== */
  OFFER_HAS_FOLDER: 'OFFER_HAS_FOLDER',
  OFFER_HAS_TDV: 'OFFER_HAS_TDV',
  /** PACKAGE_HAS_FOLDER - - PACAKGES 365-379 ================================ */
  PACKAGE_HAS_FOLDER: 'PACKAGE_HAS_FOLDER',
  PACKAGE_HAS_TD: 'PACKAGE_HAS_TD',
  PACKAGE_HAS_PARTY: 'PACKAGE_HAS_PARTY',
  PACKAGE_HAS_SIGNATURE_REQUEST: 'PACKAGE_HAS_SIGNATURE_REQUEST',
  PACKAGE_PENDING_ACTION_HAS_TD: 'PACKAGE_PENDING_ACTION_HAS_TD',
  PACKAGE_PENDING_ACTION_HAS_SIGNATURE_REQUEST:
    'PACKAGE_PENDING_ACTION_HAS_SIGNATURE_REQUEST',
  /** BPP_HAS_FOLDER - - BUYER PROSPECT PROPERTY 380-399 ================= */
  BPP_HAS_FOLDER: 'BPP_HAS_FOLDER',
  BPP_HAS_TD: 'BPP_HAS_TD',
  BPP_HAS_PARTY: 'BPP_HAS_PARTY',
  /** PROPERTY_HAS_PARTY - - PROPERTY_INFO 411-439 =========================== */
  PROPERTY_HAS_PARTY: 'PROPERTY_HAS_PARTY',
  PROPERTY_HAS_FOLDER: 'PROPERTY_HAS_FOLDER',
  PROPERTY_HAS_TD: 'PROPERTY_HAS_TD',
  PROPERTY_CREATED_TD: 'PROPERTY_CREATED_TD',
  PROPERTY_HAS_TRANSACTION_PACKAGE: 'PROPERTY_HAS_TRANSACTION_PACKAGE',
  PROPERTY_HAS_TIMELINE: 'PROPERTY_HAS_TIMELINE',
  /** SIGNATURE_REQUEST_GENERATED_TD - SR -> TD */
  SIGNATURE_REQUEST_GENERATED_TD: 'SIGNATURE_REQUEST_GENERATED_TD',
  SIGNATURE_REQUEST_GENERATED_TDV: 'SIGNATURE_REQUEST_GENERATED_TDV',
  SIGNATURE_REQUEST_HAS_DUPLICATE: 'SIGNATURE_REQUEST_HAS_DUPLICATE',
  _OBSOLETE_DOCUMENT_HAS_SIGNATURE_REQUEST:
    '_OBSOLETE_DOCUMENT_HAS_SIGNATURE_REQUEST',
  _OBSOLETE_PARTY_HAS_SIGNATURE_REQUEST:
    '_OBSOLETE_PARTY_HAS_SIGNATURE_REQUEST',
  _OBSOLETE_PARTY_CREATED_PACKET: '_OBSOLETE_PARTY_CREATED_PACKET',
  _PARTY_INVITED_TO_PACKET: '_PARTY_INVITED_TO_PACKET',
} as const;

export type ItemEdgeKind = typeof ItemEdgeKind[keyof typeof ItemEdgeKind];

export interface ItemEdgeTaskAssignedTd {
  reviewedTdvId: string;
  reviewStatus: ItemEdgeTaskAssignedTdReviewStatus;
  reviewedByUserId: string;
}

export const ItemEdgeTaskAssignedTdReviewStatus = {
  UNKNOWN: 'UNKNOWN',
  PENDING: 'PENDING',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
  REVIEWED: 'REVIEWED',
} as const;

export type ItemEdgeTaskAssignedTdReviewStatus =
  typeof ItemEdgeTaskAssignedTdReviewStatus[keyof typeof ItemEdgeTaskAssignedTdReviewStatus];

export interface ItemEdgeFileReviewHasChecklistItem {
  op?: ItemFileReviewOp;
  reviewedAt: number;
  materialized: boolean;
  materializedAt: number;
}

export interface ItemEdgeTdHasOriginalTd {
  isFillable: boolean;
}

export interface ItemEdgePropertyHasFolder {
  isDefault: boolean;
}

export interface ItemEdgePropertyCreatedTd {
  propertyFolderId: string;
  index: number;
}

export interface TaskMeta {
  dueDate: string;
  order: number;
  statusText: string;
  state: TaskMetaState;
}

export const TaskMetaState = {
  INCOMPLETE: 'INCOMPLETE',
  IN_PROGRESS: 'IN_PROGRESS',
  COMPLETE: 'COMPLETE',
} as const;

export type TaskMetaState = typeof TaskMetaState[keyof typeof TaskMetaState];

export interface HoaAssociation {
  name: string;
  fee: string;
  feeFreq: string;
  includes: string[];
  name2: string;
  fee2: string;
  fee2Freq: string;
  hasAssociation: boolean;
}

export interface TrustDeed {
  amount: string;
  balance: string;
}

export interface Deposit {
  amount: string;
  date: string;
}

export interface SignatureRecord {
  uuid: string;
  userId: string;
  user?: User;
  partyId: string;
  docName: string;
  docVersion: string;
  docDisplayName: string;
  timestamp: number;
  name: string;
  ip: string;
  userAgent: string;
  lang: string;
}

export interface ChecklistItemRequired {}

export const ChecklistItemRequiredEnum = {
  IF_APPLICABLE: 'IF_APPLICABLE',
  REQUIRED: 'REQUIRED',
} as const;

export type ChecklistItemRequiredEnum =
  typeof ChecklistItemRequiredEnum[keyof typeof ChecklistItemRequiredEnum];

export interface ChecklistType {}

export const ChecklistTypeEnum = {
  LISTING: 'LISTING',
  UNDER_CONTRACT: 'UNDER_CONTRACT',
} as const;

export type ChecklistTypeEnum =
  typeof ChecklistTypeEnum[keyof typeof ChecklistTypeEnum];

export interface ChecklistItemReviewStatus {}

export const ChecklistItemReviewStatusEnum = {
  UNKNOWN: 'UNKNOWN',
  REQUIRED: 'REQUIRED',
  IF_APPLICABLE: 'IF_APPLICABLE',
  EXEMPT: 'EXEMPT',
  READY_FOR_REVIEW: 'READY_FOR_REVIEW',
  REJECTED: 'REJECTED',
  COMPLETED: 'COMPLETED',
  REVIEWED: 'REVIEWED',
} as const;

export type ChecklistItemReviewStatusEnum =
  typeof ChecklistItemReviewStatusEnum[keyof typeof ChecklistItemReviewStatusEnum];

export interface SmartRecommendationConfig {
  kind: SmartRecommendationConfigKind;
  singleTag?: SmartRecommendationConfigSingleTag;
}

export const SmartRecommendationConfigKind = {
  UNKNOWN: 'UNKNOWN',
  SINGLE_TAG: 'SINGLE_TAG',
} as const;

export type SmartRecommendationConfigKind =
  typeof SmartRecommendationConfigKind[keyof typeof SmartRecommendationConfigKind];

export interface SmartRecommendationConfigSingleTag {
  tag: string;
}

export interface Item {
  id: string;
  vers: string;
  transId: string;
  trans?: Transaction;
  thinTrans?: Transaction;
  title: string;
  clientId: string;
  can: { [key: string]: boolean };
  opStatus: string;
  effectiveFrom: number;
  effectiveTo: number;
  createdAt: number;
  kind: ItemKind;
  propertyInfo?: ItemPropertyInfo;
  listingInfo?: ItemListingInfo;
  purchaseInfo?: ItemPurchaseInfo;
  coverPhoto?: ItemCoverPhoto;
  transactionDocument?: ItemTransactionDocument;
  party?: Party;
  folder?: ItemFolder;
  timeline?: ItemTimeline;
  timelineDate?: ItemTimelineDate;
  transactionDocumentVersion?: ItemTransactionDocumentVersion;
  templateSetting?: ItemTemplateSetting;
  transactionMerge?: ItemTransactionMerge;
  packet?: ItemPacket;
  signatureRequest?: ItemSignatureRequest;
  guidedFormPacket?: ItemGuidedFormPacket;
  guidedFormPacketFlow?: ItemGuidedFormPacketFlow;
  guidedFormPreview?: ItemGuidedFormPreview;
  sharePacket?: ItemSharePacket;
  sharePacketPublication?: ItemSharePacketPublication;
  coverSheet?: ItemCoverSheet;
  nhd?: ItemNhd;
  transactionFields?: ItemTransactionFields;
  avid?: ItemAvid;
  itemLabel?: ItemItemLabel;
  task?: ItemTask;
  taskReminder?: ItemTaskReminder;
  loadSummary?: ItemLoadSummary;
  /** Packages 936 - 949 */
  transactionPackage?: ItemTransactionPackage;
  /** Shared item related kinds 700-749. */
  sharedItem?: ItemSharedItem;
  sharedItemRequest?: ItemSharedItemRequest;
  /** Chores 750 - 774 */
  chore?: ItemChore;
  /** Invoice 775 - 789 */
  invoice?: ItemInvoice;
  /** broker sync 790-799 */
  syncRecord?: ItemSyncRecord;
  /** Dpp 800-809 */
  dpp?: ItemDpp;
  /** Apps 810-899 */
  transactionApp?: ItemTransactionApp;
  /** File Review 911-919 */
  fileReview?: ItemFileReview;
  /** Pead 920-924 */
  pead?: ItemPead;
  /** Offer 925-929 */
  offer?: ItemOffer;
  /** Timeline 930-934 */
  keyDate?: KeyDate;
  /** Buyer Prospect Property 935-940 */
  buyerProspectProperty?: ItemBuyerProspectProperty;
  fieldState?: ItemFieldState;
  inEdges: ItemEdge[];
  outEdges: ItemEdge[];
  deleted: number;
}

export const ItemKind = {
  UNKNOWN: 'UNKNOWN',
  PARTY: 'PARTY',
  FOLDER: 'FOLDER',
  PROPERTY_INFO: 'PROPERTY_INFO',
  LISTING_INFO: 'LISTING_INFO',
  PURCHASE_INFO: 'PURCHASE_INFO',
  TRANSACTION_DOCUMENT: 'TRANSACTION_DOCUMENT',
  TRANSACTION_DOCUMENT_VERSION: 'TRANSACTION_DOCUMENT_VERSION',
  TEMPLATE_SETTING: 'TEMPLATE_SETTING',
  TRANSACTION_MERGE: 'TRANSACTION_MERGE',
  LOAD_SUMMARY: 'LOAD_SUMMARY',
  COVER_PHOTO: 'COVER_PHOTO',
  /** TRANSACTION_PACKAGE - Pacakges */
  TRANSACTION_PACKAGE: 'TRANSACTION_PACKAGE',
  /** PACKET - Packets */
  PACKET: 'PACKET',
  SIGNATURE_REQUEST: 'SIGNATURE_REQUEST',
  GUIDED_FORM_PACKET: 'GUIDED_FORM_PACKET',
  GUIDED_FORM_PACKET_FLOW: 'GUIDED_FORM_PACKET_FLOW',
  GUIDED_FORM_PREVIEW: 'GUIDED_FORM_PREVIEW',
  SHARE_PACKET: 'SHARE_PACKET',
  SHARE_PACKET_PUBLICATION: 'SHARE_PACKET_PUBLICATION',
  /** ITEM_LABEL - Tasks */
  ITEM_LABEL: 'ITEM_LABEL',
  TASK: 'TASK',
  TASK_REMINDER: 'TASK_REMINDER',
  /** SHARED_ITEM_REQUEST - Items shared outside a transaction. */
  SHARED_ITEM_REQUEST: 'SHARED_ITEM_REQUEST',
  SHARED_ITEM: 'SHARED_ITEM',
  COVER_SHEET: 'COVER_SHEET',
  /** CHORE - Chores */
  CHORE: 'CHORE',
  /** TIMELINE - Timeline 80 - 89 */
  TIMELINE: 'TIMELINE',
  TIMELINE_DATE: 'TIMELINE_DATE',
  /** INVOICE - Invoice 90 - 99 */
  INVOICE: 'INVOICE',
  /** SYNC_RECORD - broker sync 100 - 109 */
  SYNC_RECORD: 'SYNC_RECORD',
  /** NHD - NHD */
  NHD: 'NHD',
  /** TRANSACTION_FIELDS - Fields */
  TRANSACTION_FIELDS: 'TRANSACTION_FIELDS',
  /** DPP - DPP */
  DPP: 'DPP',
  /** AVID - AVID 135-139 */
  AVID: 'AVID',
  /** TRANSACTION_APP - Apps 140-150 */
  TRANSACTION_APP: 'TRANSACTION_APP',
  /** FILE_REVIEW - File Review 151-159 */
  FILE_REVIEW: 'FILE_REVIEW',
  /** PEAD - Pead 160-164 */
  PEAD: 'PEAD',
  /** OFFER - OFFER 165-169 */
  OFFER: 'OFFER',
  /** KEY_DATE - Timeline 170-174 */
  KEY_DATE: 'KEY_DATE',
  /** BUYER_PROSPECT_PROPERTY - Buyer Prospect Property 175-179 */
  BUYER_PROSPECT_PROPERTY: 'BUYER_PROSPECT_PROPERTY',
  /** _OBSOLETE_MILESTONE - Obsolete items follow */
  _OBSOLETE_MILESTONE: '_OBSOLETE_MILESTONE',
  _OBSOLETE_FLOW_DOCUMENT_TASK: '_OBSOLETE_FLOW_DOCUMENT_TASK',
  _OBSOLETE_INVITE_PARTIES_TASK: '_OBSOLETE_INVITE_PARTIES_TASK',
  _OBSOLETE_BOARD_TASK: '_OBSOLETE_BOARD_TASK',
  _OBSOLETE_SECTION_TASK: '_OBSOLETE_SECTION_TASK',
  _OBSOLETE_DOCUMENT_TASK: '_OBSOLETE_DOCUMENT_TASK',
} as const;

export type ItemKind = typeof ItemKind[keyof typeof ItemKind];

export interface ItemOffer {
  packageId: string;
  /**
   * Ref to offer item that was source for this one.
   * Format <transaction_id>:<offer_item_id>:<offer_item_vers>.
   */
  sourceOffer: string;
  /** Ref to listin txn id the offer is linked to */
  syncedToListingSideTxnId: string;
  submittedFromBuyerSide: boolean;
  flowId: string;
  /** Keep a copy of value to avoid requiring txn data access */
  reState: string;
  offerData?: { [key: string]: any };
  status: ItemOfferStatus;
  currentTermsId: string;
  formattedOfferData?: ItemOfferFormattedOfferData;
  submittedTs: number;
  sortIndex: number;
  favorite: boolean;
  archived: boolean;
  publicNotes: string;
  privateNotes: string;
  /**
   * Private notes and email subject left from other side's team (only relevant
   * when offer created from other side's source submitted offer).
   */
  notesFromOtherSide: string;
  emailSubjectFromOtherSide: string;
  notifications: ItemOfferNotification[];
  decisionFlowIds?: { [key: string]: any };
  cancelOfferReason: string;
  keyTerms: ItemOfferKeyTerm[];
  transactionSide: TransactionSide;
  OBSOLETEFinalContractFlow: boolean;
}

export const ItemOfferStatus = {
  DRAFT: 'DRAFT',
  READY_TO_SUBMIT: 'READY_TO_SUBMIT',
  SUBMITTED: 'SUBMITTED',
  PENDING: 'PENDING',
  ACCEPTED: 'ACCEPTED',
  REJECTED: 'REJECTED',
  EXPIRED: 'EXPIRED',
  VOIDED: 'VOIDED',
  /** COUNTERED - OFFER sepcific statuses (101-120) */
  COUNTERED: 'COUNTERED',
  COUNTER_ACCEPTED: 'COUNTER_ACCEPTED',
} as const;

export type ItemOfferStatus =
  typeof ItemOfferStatus[keyof typeof ItemOfferStatus];

export const ItemOfferNotification = {
  UNKNOWN: 'UNKNOWN',
  ON_EMAIL_OPENED: 'ON_EMAIL_OPENED',
  ON_OPENED_IN_APP: 'ON_OPENED_IN_APP',
  ON_DOCUMENTS_OPENED_OR_DOWNLOADED: 'ON_DOCUMENTS_OPENED_OR_DOWNLOADED',
} as const;

export type ItemOfferNotification =
  typeof ItemOfferNotification[keyof typeof ItemOfferNotification];

export interface ItemOfferFormattedOfferData {
  propertyInfo?: ItemOfferFormattedOfferDataPropertyInfo;
  buyerAgents: Contact[];
  buyers: Contact[];
  listingAgents: Contact[];
  sellers: Contact[];
  purchasePrice: string;
  closeOfEscrowFixedTs: number;
  closeOfEscrowDaysFromAcceptance: number;
  financingInfo?: ItemOfferFormattedOfferDataFinancingInfo;
  contingencies: ItemOfferFormattedOfferDataContingency[];
  otherTerms?: ItemOfferFormattedOfferDataOtherTerms;
  counter?: ItemOfferFormattedOfferDataCounter;
}

export interface ItemOfferFormattedOfferDataPropertyInfo {
  addressId: string;
  propertyType: ItemPropertyInfoPropertyType;
  apn: string;
}

export interface ItemOfferFormattedOfferDataFinancingInfo {
  financing: ItemOfferFormattedOfferDataFinancingInfoFinancing[];
  initialDeposit: string;
  increasedDeposit: string;
  additionalFinancingTerms: string;
}

export interface ItemOfferFormattedOfferDataFinancingInfoFinancing {
  type: ItemOfferFormattedOfferDataFinancingInfoFinancingType;
  cash?: ItemOfferFormattedOfferDataFinancingInfoFinancingCash;
  loan?: ItemOfferFormattedOfferDataFinancingInfoFinancingLoan;
}

export const ItemOfferFormattedOfferDataFinancingInfoFinancingType = {
  UNKNOWN_FINANCING: 'UNKNOWN_FINANCING',
  CASH: 'CASH',
  LOAN: 'LOAN',
} as const;

export type ItemOfferFormattedOfferDataFinancingInfoFinancingType =
  typeof ItemOfferFormattedOfferDataFinancingInfoFinancingType[keyof typeof ItemOfferFormattedOfferDataFinancingInfoFinancingType];

export interface ItemOfferFormattedOfferDataFinancingInfoFinancingCash {}

export interface ItemOfferFormattedOfferDataFinancingInfoFinancingLoan {
  type: ItemOfferFormattedOfferDataFinancingInfoFinancingLoanLoanType;
  lenderName: string;
  amount: string;
  fixedRateLimit: string;
  variableRateLimit: string;
  pointsLimit: string;
}

export const ItemOfferFormattedOfferDataFinancingInfoFinancingLoanLoanType = {
  UNKNOWN_LOAN_TYPE: 'UNKNOWN_LOAN_TYPE',
  CONVENTIONAL: 'CONVENTIONAL',
  FHA: 'FHA',
  VA: 'VA',
  SELLER_FINANCING: 'SELLER_FINANCING',
  ASSUMED_FINANCING: 'ASSUMED_FINANCING',
  OTHER: 'OTHER',
} as const;

export type ItemOfferFormattedOfferDataFinancingInfoFinancingLoanLoanType =
  typeof ItemOfferFormattedOfferDataFinancingInfoFinancingLoanLoanType[keyof typeof ItemOfferFormattedOfferDataFinancingInfoFinancingLoanLoanType];

export interface ItemOfferFormattedOfferDataContingency {
  type: ItemOfferFormattedOfferDataContingencyType;
  typeDesc: string;
  waived: boolean;
  daysFromAcceptance: number;
  fixedDate: string;
  dateType: ItemOfferFormattedOfferDataContingencyDateType;
}

export const ItemOfferFormattedOfferDataContingencyType = {
  UNKNOWN_CONTINGENCY: 'UNKNOWN_CONTINGENCY',
  APPRAISAL: 'APPRAISAL',
  LOAN: 'LOAN',
  BUYER_PROPERTY_SALE: 'BUYER_PROPERTY_SALE',
  INSPECTIONS: 'INSPECTIONS',
  DISCLOSURE_REVIEW: 'DISCLOSURE_REVIEW',
} as const;

export type ItemOfferFormattedOfferDataContingencyType =
  typeof ItemOfferFormattedOfferDataContingencyType[keyof typeof ItemOfferFormattedOfferDataContingencyType];

export const ItemOfferFormattedOfferDataContingencyDateType = {
  UNKNOWN_DATE_TYPE: 'UNKNOWN_DATE_TYPE',
  DAYS_FROM_ACCEPTANCE: 'DAYS_FROM_ACCEPTANCE',
  FIXED_DATE: 'FIXED_DATE',
} as const;

export type ItemOfferFormattedOfferDataContingencyDateType =
  typeof ItemOfferFormattedOfferDataContingencyDateType[keyof typeof ItemOfferFormattedOfferDataContingencyDateType];

export interface ItemOfferFormattedOfferDataOtherTerms {
  expirationFixedTs: number;
  expirationDaysFromSignature: number;
  otherTerms: string;
}

export interface ItemOfferFormattedOfferDataCounter {
  terms: string;
  termsHistory: string[];
}

export interface ItemOfferKeyTerm {
  kind: ItemTransactionAppOffersKeyTermsConfigSummaryTerms;
  isVisible: boolean;
}

export interface ItemTransactionPackage {
  packageId: string;
  title: string;
  packageKind: PackageKind;
  packageStatus: PackageStatus;
  setupFlowId: string;
  setupFlow?: Flow;
  membersIds: string[];
  members: Item[];
  ocrStatus: PackageOcrStatus;
  pendingAction?: ItemTransactionPackagePendingAction;
  submittedAction?: ItemTransactionPackageSubmittedAction;
  accessed: boolean;
  detachedFromPackage: boolean;
}

export interface ItemTransactionPackageSubmissionsErrors {
  errors: ItemTransactionPackageSubmissionsErrorsError[];
}

export const ItemTransactionPackageSubmissionsErrorsType = {
  UNKNOWN_ERROR: 'UNKNOWN_ERROR',
  NO_DOCUMENTS: 'NO_DOCUMENTS',
  UNSIGNED_DOCUMENTS: 'UNSIGNED_DOCUMENTS',
  OUTSTANDING_SIGNATURES: 'OUTSTANDING_SIGNATURES',
  MISSING_MANDATORY_ROLES: 'MISSING_MANDATORY_ROLES',
} as const;

export type ItemTransactionPackageSubmissionsErrorsType =
  typeof ItemTransactionPackageSubmissionsErrorsType[keyof typeof ItemTransactionPackageSubmissionsErrorsType];

export const ItemTransactionPackageSubmissionsErrorsLevel = {
  UNKNOWN_LEVEL: 'UNKNOWN_LEVEL',
  ERROR: 'ERROR',
  WARNING: 'WARNING',
} as const;

export type ItemTransactionPackageSubmissionsErrorsLevel =
  typeof ItemTransactionPackageSubmissionsErrorsLevel[keyof typeof ItemTransactionPackageSubmissionsErrorsLevel];

export interface ItemTransactionPackageSubmissionsErrorsError {
  type: ItemTransactionPackageSubmissionsErrorsType;
  level: ItemTransactionPackageSubmissionsErrorsLevel;
  details: string[];
}

export interface ItemTransactionPackagePendingAction {
  actionType: ItemActionType;
  origFields?: { [key: string]: any };
  data?: { [key: string]: any };
  setupType: ItemTransactionPackagePendingActionSetupType;
  counterKind: ItemActionCounterKind;
  uuid: string;
  detached: boolean;
  isObo: boolean;
  actionSetupFlowId: string;
  revision: number;
  revisedActionId: string;
}

export const ItemTransactionPackagePendingActionSetupType = {
  UNKNOWN: 'UNKNOWN',
  UPLOAD: 'UPLOAD',
  CREATE: 'CREATE',
} as const;

export type ItemTransactionPackagePendingActionSetupType =
  typeof ItemTransactionPackagePendingActionSetupType[keyof typeof ItemTransactionPackagePendingActionSetupType];

export interface ItemTransactionPackageSubmittedAction {
  messageSent: boolean;
  messageFailed: boolean;
  messageFailedDetails: string;
  messageFailedCode: number;
  messageId: string;
  actionId: string;
  emailApp: string;
  recipientsStatus: {
    [key: string]: ItemTransactionPackageSubmittedActionRecipientStatus;
  };
  actionType: ItemActionType;
  actionSubmittedFromOwnSide: boolean;
  counterKind: ItemActionCounterKind;
  revision: number;
}

export interface ItemTransactionPackageSubmittedActionRecipientStatus {
  status: string;
  ts: number;
}

export interface ItemTransactionPackageSubmittedActionRecipientsStatusEntry {
  key: string;
  value?: ItemTransactionPackageSubmittedActionRecipientStatus;
}

export interface ItemDpp {
  gfpId: string;
  status: ItemDppStatus;
  disqualifyReason: ItemDppDisqualifyReason;
  productId: string;
  orderedAt: number;
  agentComment: string;
  paymentType: ItemDppPaymentType;
  stripeSourceId: string;
  orderNum: string;
  orderFlowId: string;
  /** Seller flow */
  orderDppFlowId: string;
}

export const ItemDppStatus = {
  UNKNOWN: 'UNKNOWN',
  NOT_QUALIFIED: 'NOT_QUALIFIED',
  QUALIFIED: 'QUALIFIED',
  PURCHASED: 'PURCHASED',
  DECLINED: 'DECLINED',
} as const;

export type ItemDppStatus = typeof ItemDppStatus[keyof typeof ItemDppStatus];

export const ItemDppDisqualifyReason = {
  NOT_APPLICABLE: 'NOT_APPLICABLE',
  ASSOCIATION_OPTOUT: 'ASSOCIATION_OPTOUT',
  BROKERAGE_OPTOUT: 'BROKERAGE_OPTOUT',
  AGENT_OPTOUT: 'AGENT_OPTOUT',
  PRODUCT_DISABLED: 'PRODUCT_DISABLED',
  STATE_DISABLED: 'STATE_DISABLED',
  PURCHASE: 'PURCHASE',
  IN_PERSON_MODE: 'IN_PERSON_MODE',
  EXEMPT_SELLER: 'EXEMPT_SELLER',
  NO_PRIMARY_AGENT: 'NO_PRIMARY_AGENT',
  ALREADY_EXISTS: 'ALREADY_EXISTS',
  EXPIRED: 'EXPIRED',
} as const;

export type ItemDppDisqualifyReason =
  typeof ItemDppDisqualifyReason[keyof typeof ItemDppDisqualifyReason];

export const ItemDppPaymentType = {
  UNSET: 'UNSET',
  ESCROW: 'ESCROW',
  CC: 'CC',
} as const;

export type ItemDppPaymentType =
  typeof ItemDppPaymentType[keyof typeof ItemDppPaymentType];

export interface ItemPropertyInfo {
  /**
   * Materilizing the value of `is_secondary` is not striclty needed, but it is
   * a natural way of making the items dirty whenever the value changes
   * `is_secondary` is materilaized as opposed to `is_primary` to avoid the need to backfill
   * (since historically only the "primary" PROPERTY_INFO existed)
   */
  isSecondary: boolean;
  addressId: string;
  address?: Address;
  propertyType: ItemPropertyInfoPropertyType;
  yearBuilt: number;
  lot: string;
  block: string;
  subdivision: string;
  apn: string;
  hoaLikely: string;
  isMobileHome: boolean;
  bedroomCount: number;
  bathCount: number;
  roomCount: number;
  otherEquipments: string;
  taxes: string;
  legalDescription: string;
  images: string[];
  /** PAR Specific Fields */
  includesParSda: boolean;
  emailAddressLocalPart: string;
  emailAddress: string;
}

export const ItemPropertyInfoPropertyType = {
  UNKNOWN: 'UNKNOWN',
  SINGLE_FAMILY: 'SINGLE_FAMILY',
  MULTIFAMILY: 'MULTIFAMILY',
  COMMERCIAL: 'COMMERCIAL',
  LAND: 'LAND',
  OTHER: 'OTHER',
  FARM_AND_RANCH: 'FARM_AND_RANCH',
  MANUFACTURED_HOME: 'MANUFACTURED_HOME',
  CONDOMINIUM: 'CONDOMINIUM',
} as const;

export type ItemPropertyInfoPropertyType =
  typeof ItemPropertyInfoPropertyType[keyof typeof ItemPropertyInfoPropertyType];

export interface ItemListingInfo {
  mlsNumber: string;
  listingDate: string;
  expirationDate: string;
  previousPrice: string;
  listedPrice: string;
  currentPrice: string;
  includes: string;
  excludes: string;
  remarks: string;
  association?: HoaAssociation;
  terms: string;
  trustDeed1?: TrustDeed;
  trustDeed2?: TrustDeed;
  trustDeed3?: TrustDeed;
  otherLiensAmount: string;
  otherLiensDescription: string;
  otherEncumberancesAmount: string;
  otherEncumberancesDescription: string;
  sellerLegalName: string;
  avid?: ItemListingInfoAVID;
  isOccupied: boolean;
  listingAgreementDate: string;
}

export interface ItemListingInfoAVID {
  agentDisclosureType: ItemListingInfoAVIDAgentDisclosureType;
  agentDisclosedItems: string;
}

export const ItemListingInfoAVIDAgentDisclosureType = {
  NONE: 'NONE',
  HAS_AVID: 'HAS_AVID',
  NOTHING_TO_DISCLOSE: 'NOTHING_TO_DISCLOSE',
  ITEMS_DISCLOSED: 'ITEMS_DISCLOSED',
} as const;

export type ItemListingInfoAVIDAgentDisclosureType =
  typeof ItemListingInfoAVIDAgentDisclosureType[keyof typeof ItemListingInfoAVIDAgentDisclosureType];

export interface ItemPurchaseInfo {
  purchasePrice: string;
  amountFinanced: string;
  cashBalance: string;
  transferFees: string;
  deposit1?: Deposit;
  deposit2?: Deposit;
  deposit3?: Deposit;
  deposit4?: Deposit;
  loan1: string;
  loan2: string;
  loan3: string;
  loan4: string;
  sellerFinancing: string;
  otherFinancing1: string;
  otherFinancing2: string;
  otherFinancing3: string;
  otherFinancing4: string;
  acceptanceDate: string;
  closingDate: string;
  possessionDate: string;
  offerDate: string;
  contractDate: string;
  purchaseAgreementDate: string;
  inspectionContingency: boolean;
  inspectionContingencyDate: string;
  financingContingency: boolean;
  financingContingencyDate: string;
  appraisalContingency: boolean;
  appraisalContingencyDate: string;
  appraisalContingencyMin: string;
  disclosureContingency: boolean;
  disclosureContingencyDate: string;
  saleOfPropertyContingency: boolean;
  remarks: string;
  listingCommissionAmt: string;
  listingCommissionPct: string;
  saleCommissionAmt: string;
  saleCommissionPct: string;
  otherDeductions: string;
  commissionBreakdown: string;
  escrow?: ItemPurchaseInfoEscrow;
  buyerRepresentationAgreementDate: string;
  leaseAgreementDate: string;
  keyDatesIds: string[];
  keyDates: Item[];
}

export interface ItemPurchaseInfoEscrow {
  number: string;
}

export interface ItemBuyerProspectProperty {
  propertyInfo?: ItemPropertyInfo;
  peadIds: string[];
  listingAgent?: Contact;
}

export interface ItemTransactionDocument {
  kind: ItemTransactionDocumentKind;
  version: number;
  updatedAt: number;
  documentId: string;
  document?: Document;
  latestVersionId: string;
  latestVersion?: Item;
  archived: boolean;
  notes: string;
  index: number;
  signatureStatus: ItemTransactionDocumentSignatureStatus;
  usersSeen: ItemTransactionDocumentUserSeen[];
  /** Stores id of latest non-trash folder to improve performance on restore */
  lastActiveFolderId: string;
  lastActiveFolder?: Item;
  isManualEditUnlocked: boolean;
  folderKind: ItemFolderKind;
  /** Keep track of newer TD that replaced current one */
  replacedWithTdId: string;
  replacedWithTd?: Item;
  originalDocumentId: string;
  originalDocument?: Document;
  formFillMeta?: ItemTransactionDocumentFormFillMeta;
  origin: ItemTransactionDocumentOrigin;
  isDeactivatedForm: boolean;
  clientVisibilityStatus: ItemTransactionDocumentClientVisibilityStatus;
  madeVisibleByUserId: string;
  madeVisibleByUser?: User;
  clientVisibilityChangedAt: number;
  clientDocumentType: ItemTransactionDocumentClientDocumentType;
  clientDocumentProperty: string;
}

export const ItemTransactionDocumentKind = {
  UNKNOWN: 'UNKNOWN',
  DISCLOSURE: 'DISCLOSURE',
  COVER_SHEET: 'COVER_SHEET',
} as const;

export type ItemTransactionDocumentKind =
  typeof ItemTransactionDocumentKind[keyof typeof ItemTransactionDocumentKind];

export const ItemTransactionDocumentSignatureStatus = {
  SIGNATURE_DEFAULT: 'SIGNATURE_DEFAULT',
  SIGNATURE_PENDING: 'SIGNATURE_PENDING',
  SIGNATURE_COMPLETED: 'SIGNATURE_COMPLETED',
} as const;

export type ItemTransactionDocumentSignatureStatus =
  typeof ItemTransactionDocumentSignatureStatus[keyof typeof ItemTransactionDocumentSignatureStatus];

export const ItemTransactionDocumentOrigin = {
  UNKNOWN_ORIGIN: 'UNKNOWN_ORIGIN',
  COMPLIANCE: 'COMPLIANCE',
  TRANSACTION_EMAIL: 'TRANSACTION_EMAIL',
} as const;

export type ItemTransactionDocumentOrigin =
  typeof ItemTransactionDocumentOrigin[keyof typeof ItemTransactionDocumentOrigin];

export const ItemTransactionDocumentClientVisibilityStatus = {
  UNKNOWN_VISIBILITY: 'UNKNOWN_VISIBILITY',
  VISIBLE: 'VISIBLE',
  HIDDEN: 'HIDDEN',
} as const;

export type ItemTransactionDocumentClientVisibilityStatus =
  typeof ItemTransactionDocumentClientVisibilityStatus[keyof typeof ItemTransactionDocumentClientVisibilityStatus];

export const ItemTransactionDocumentClientDocumentType = {
  UNKNOWN_TYPE: 'UNKNOWN_TYPE',
  DOCUMENT: 'DOCUMENT',
  SIGNED_DOCUMENT: 'SIGNED_DOCUMENT',
} as const;

export type ItemTransactionDocumentClientDocumentType =
  typeof ItemTransactionDocumentClientDocumentType[keyof typeof ItemTransactionDocumentClientDocumentType];

export interface ItemTransactionDocumentUserSeen {
  userId: string;
  user?: User;
  versionId: string;
  version?: Item;
  timestamp: number;
}

export interface ItemTransactionDocumentFormFillMeta {
  updatedThroughVers: string;
  linkedFieldDependencies: {
    [key: string]: ItemTransactionDocumentFormFillMetaLinkedFieldDependency;
  };
  /** denorm */
  formId: string;
  formSeriesId: string;
  isSet: boolean;
  namespaceScope?: ItemTransactionDocumentFormFillMetaNamespaceScope;
}

export interface ItemTransactionDocumentFormFillMetaLinkedFieldDependency {
  itemId: string;
  fieldIds: string[];
}

export interface ItemTransactionDocumentFormFillMetaLinkedFieldDependenciesEntry {
  key: string;
  value?: ItemTransactionDocumentFormFillMetaLinkedFieldDependency;
}

export interface ItemTransactionDocumentFormFillMetaNamespaceScope {
  namespace: string;
  itemId: string;
}

/**
 * Access types allowed on a share packet.
 * Note: This message is not an item and is purely for reuse across items.
 */
export interface ItemSharePacketAccessType {}

export const ItemSharePacketAccessTypeAccessType = {
  /** SHARE_VIEWER - Can only view the share packet (Buyer). */
  SHARE_VIEWER: 'SHARE_VIEWER',
  /**
   * SHARE_ADMIN - Can view the share packet, forward it to other people
   * and get analytics on forwarded share activity (Buyer agent).
   */
  SHARE_ADMIN: 'SHARE_ADMIN',
  /** SHARE_QUICK_VIEWER - Can only view the share packet (Buyer) (link share) */
  SHARE_QUICK_VIEWER: 'SHARE_QUICK_VIEWER',
} as const;

export type ItemSharePacketAccessTypeAccessType =
  typeof ItemSharePacketAccessTypeAccessType[keyof typeof ItemSharePacketAccessTypeAccessType];

/**
 * Invite types available to share packets.
 * Note: This message is not an item and is purely for reuse across items.
 */
export interface ItemSharePacketInviteType {}

export const ItemSharePacketInviteTypeInviteType = {
  UNKNOWN: 'UNKNOWN',
  EMAIL: 'EMAIL',
  LINK: 'LINK',
} as const;

export type ItemSharePacketInviteTypeInviteType =
  typeof ItemSharePacketInviteTypeInviteType[keyof typeof ItemSharePacketInviteTypeInviteType];

export interface ItemTransactionDocumentVersion {
  source?: ItemTransactionDocumentVersionSource;
  syncedZfDocuments: ItemTransactionDocumentVersionSyncedZfDocument[];
  version: number;
  documentId: string;
  document?: Document;
  formFill?: FormFill;
  url: string;
  pdfAnnotateConfigId: string;
  pdfAnnotateConfig?: FillConfig;
  missingFillConditionsCount: number;
  createdAt: number;
}

export interface ItemTransactionDocumentVersionSource {
  label: string;
  kind: ItemTransactionDocumentVersionSourceKind;
  copyAnalysisFromDocumentId: string;
  inboxAttachment?: ItemTransactionDocumentVersionSourceInboxAttachment;
  upload?: ItemTransactionDocumentVersionSourceUpload;
  transactionMerge?: ItemTransactionDocumentVersionSourceTransactionMerge;
  empty?: ItemTransactionDocumentVersionSourceEmpty;
  split?: ItemTransactionDocumentVersionSourceSplit;
  restore?: ItemTransactionDocumentVersionSourceRestore;
  copy?: ItemTransactionDocumentVersionSourceCopy;
  recombine?: ItemTransactionDocumentVersionSourceRecombine;
  packet?: ItemTransactionDocumentVersionSourcePacket;
  merge?: ItemTransactionDocumentVersionSourceMerge;
  rename?: ItemTransactionDocumentVersionSourceRename;
  rawDocument?: ItemTransactionDocumentVersionSourceRawDocument;
  zipform?: ItemTransactionDocumentVersionSourceZipform;
  docusign?: ItemTransactionDocumentVersionSourceDocusign;
  gfpFlowAttachmentUpload?: ItemTransactionDocumentVersionSourceGfpFlowAttachmentUpload;
  coverSheet?: ItemTransactionDocumentVersionSourceCoverSheet;
  stamp?: ItemTransactionDocumentVersionSourceStamp;
  nhd?: ItemTransactionDocumentVersionSourceNhd;
  avidUpload?: ItemTransactionDocumentVersionSourceAvidUpload;
  filledForm?: ItemTransactionDocumentVersionSourceFilledForm;
  transactionEmail?: ItemTransactionDocumentVersionSourceTransactionEmail;
  template?: ItemTransactionDocumentVersionSourceTemplate;
  pead?: ItemTransactionDocumentVersionSourcePead;
  importedPead?: ItemTransactionDocumentVersionSourceImportedPead;
  signatureRequest?: ItemTransactionDocumentVersionSourceSignatureRequest;
  package?: ItemTransactionDocumentVersionSourcePackage;
  timeline?: ItemTransactionDocumentVersionSourceTimeline;
  pdfAnnotate?: ItemTransactionDocumentVersionSourcePdfAnnotate;
  OBSOLETEOffer?: ItemTransactionDocumentVersionSourceOBSOLETEOffer;
  documentUpload?: ItemTransactionDocumentVersionSourceDocumentUpload;
}

export const ItemTransactionDocumentVersionSourceKind = {
  UNKNOWN: 'UNKNOWN',
  INBOX_ATTACHMENT: 'INBOX_ATTACHMENT',
  UPLOAD: 'UPLOAD',
  TRANSACTION_MERGE: 'TRANSACTION_MERGE',
  EMPTY: 'EMPTY',
  SPLIT: 'SPLIT',
  RESTORE: 'RESTORE',
  COPY: 'COPY',
  RECOMBINE: 'RECOMBINE',
  PACKET: 'PACKET',
  MERGE: 'MERGE',
  RENAME: 'RENAME',
  RAW_DOCUMENT: 'RAW_DOCUMENT',
  ZIPFORM: 'ZIPFORM',
  DOCUSIGN: 'DOCUSIGN',
  GFP_FLOW_ATTACHMENT_UPLOAD: 'GFP_FLOW_ATTACHMENT_UPLOAD',
  COVER_SHEET: 'COVER_SHEET',
  STAMP: 'STAMP',
  NHD: 'NHD',
  AVID_UPLOAD: 'AVID_UPLOAD',
  FILLED_FORM: 'FILLED_FORM',
  TRANSACTION_EMAIL: 'TRANSACTION_EMAIL',
  TEMPLATE: 'TEMPLATE',
  PEAD: 'PEAD',
  IMPORTED_PEAD: 'IMPORTED_PEAD',
  SIGNATURE_REQUEST: 'SIGNATURE_REQUEST',
  PACKAGE: 'PACKAGE',
  TIMELINE: 'TIMELINE',
  PDF_ANNOTATE: 'PDF_ANNOTATE',
  DOCUMENT_UPLOAD: 'DOCUMENT_UPLOAD',
  _OBSOLETE_OFFER: '_OBSOLETE_OFFER',
} as const;

export type ItemTransactionDocumentVersionSourceKind =
  typeof ItemTransactionDocumentVersionSourceKind[keyof typeof ItemTransactionDocumentVersionSourceKind];

export interface ItemTransactionDocumentVersionSourceAvidUpload {
  documentUrl: string;
  documentName: string;
  taskId: string;
}

export interface ItemTransactionDocumentVersionSourceRecombine {
  transactionDocumentVersionId: string;
  splits: ItemTransactionDocumentVersionSourceRecombineRecombineSplit[];
}

export interface ItemTransactionDocumentVersionSourceRecombineRecombineSplit {
  startPage: number;
  endPage: number;
  rotation: number;
}

export interface ItemTransactionDocumentVersionSourceStamp {
  documentId: string;
}

export interface ItemTransactionDocumentVersionSourceZipform {
  zfDocId: string;
  zfTxnId: string;
  zfDocVer: string;
  documentUrl: string;
}

export interface ItemTransactionDocumentVersionSourceDocusign {
  docusignDocId: string;
  docusignEnvelopeId: string;
  docusignDocUrl: string;
  documentUrl: string;
  filename: string;
}

export interface ItemTransactionDocumentVersionSourceMerge {
  transactionDocumentVersionIds: string[];
  filename: string;
}

export interface ItemTransactionDocumentVersionSourceInboxAttachment {
  /**
   * Note: An InboxAttachment's lifecycle is independent of
   * TransactionDocumentVersions that might be based on it. Any
   * attempt to resolve this InboxAttachment should handle
   * possible references to deleted inbox attachments.
   */
  inboxAttachmentId: string;
  filename: string;
  signatureRequestId: string;
}

export interface ItemTransactionDocumentVersionSourceSignatureRequest {
  signatureRequestId: string;
  documentId: string;
}

export interface ItemTransactionDocumentVersionSourceUpload {
  url: string;
  filename: string;
  byteSize: number;
  thumbnailUrl: string;
}

export interface ItemTransactionDocumentVersionSourceTransactionMerge {
  fromTransId: string;
  transactionDocumentId: string;
}

export interface ItemTransactionDocumentVersionSourceNhd {
  documentUrl: string;
  providerName: string;
  taskId: string;
  nhdId: string;
}

export interface ItemTransactionDocumentVersionSourceEmpty {}

export interface ItemTransactionDocumentVersionSourceRename {}

export interface ItemTransactionDocumentVersionSourceRestore {
  transactionDocumentVersionId: string;
}

export interface ItemTransactionDocumentVersionSourceCopy {
  transactionDocumentVersionId: string;
}

export interface ItemTransactionDocumentVersionSourceSplit {
  transactionDocumentVersionId: string;
  startPage: number;
  endPage: number;
  filename: string;
}

export interface ItemTransactionDocumentVersionSourceTransactionEmail {
  fromEmail: string;
  documentUrl: string;
  hexdigest: string;
}

export interface ItemTransactionDocumentVersionSourceGfpFlowAttachmentUpload {
  flowId: string;
  gfpId: string;
  gfpfId: string;
  upload?: ItemTransactionDocumentVersionSourceUpload;
  fieldId: string;
  taskId: string;
}

export interface ItemTransactionDocumentVersionSourcePacket {
  packetId: string;
  documentId: string;
  relatedId: string;
  taskId: string;
  sourceKind: ItemTransactionDocumentVersionSourcePacketSourceKind;
}

export const ItemTransactionDocumentVersionSourcePacketSourceKind = {
  DEFAULT: 'DEFAULT',
  CERTIFICATE: 'CERTIFICATE',
  ATTACHMENT: 'ATTACHMENT',
  AUTO_BOILERPLATE: 'AUTO_BOILERPLATE',
  ADDENDUM: 'ADDENDUM',
} as const;

export type ItemTransactionDocumentVersionSourcePacketSourceKind =
  typeof ItemTransactionDocumentVersionSourcePacketSourceKind[keyof typeof ItemTransactionDocumentVersionSourcePacketSourceKind];

/**
 * Clients cannot provide this source.
 * This can only be used as a source
 * by configuring `CreateTdvHelper`.
 */
export interface ItemTransactionDocumentVersionSourceRawDocument {
  documentId: string;
}

/**
 * Clients cannot provide this source. This can only be used as a source
 * by configuring `CreateTdvHelper`.
 */
export interface ItemTransactionDocumentVersionSourceDocumentUpload {
  documentId: string;
}

export interface ItemTransactionDocumentVersionSourceCoverSheet {
  documentId: string;
}

export interface ItemTransactionDocumentVersionSourceTemplate {
  sourceTemplateId: string;
  sourceTdvId: string;
  documentId: string;
}

export interface ItemTransactionDocumentVersionSourcePead {
  documentId: string;
  peadId: string;
  addressId: string;
  index: number;
}

export interface ItemTransactionDocumentVersionSourceImportedPead {
  sourceTxnId: string;
  sourcePeadId: string;
  sourceTdId: string;
  documentId: string;
}

export interface ItemTransactionDocumentVersionSourceOBSOLETEOffer {
  documentUrl: string;
  documentName: string;
  offerId: string;
}

export interface ItemTransactionDocumentVersionSourcePackage {
  packageId: string;
  documentUrl: string;
  documentName: string;
}

export interface ItemTransactionDocumentVersionSourceFilledForm {
  transactionDocumentVersionId: string;
  formId: string;
  fieldValues?: { [key: string]: any };
  formFill?: FormFill;
  transactionFieldsUpdated: boolean;
  formStates: FormStateEnum[];
  updateToFormId: string;
  trigger: string;
  fieldIdsToLink: string[];
  fieldIdsToUnlink: string[];
  systemFieldIdsToUnlink: string[];
  missingFillConditionsCount: number;
  clearValues: boolean;
}

export interface ItemTransactionDocumentVersionSourcePdfAnnotate {
  referenceId: string;
  documentId: string;
  fillConfigId: string;
}

export interface ItemTransactionDocumentVersionSourceTimeline {
  documentUrl: string;
  documentName: string;
}

/**
 * When we upload a TDV to a zipform
 * this keep a record of having done so
 * in case we need this info in the future
 */
export interface ItemTransactionDocumentVersionSyncedZfDocument {
  zfDocId: string;
  zfDocTxnId: string;
}

export interface ItemFolder {
  orderIndex: number;
  folderKind: ItemFolderKind;
  /** denorm */
  tdvIds: string[];
  modifiedAt: number;
  modifiedUserId: string;
  modifiedUserName: string;
}

export const ItemFolderKind = {
  UNKNOWN: 'UNKNOWN',
  USER_CREATED: 'USER_CREATED',
  DEFAULT: 'DEFAULT',
  TRASH: 'TRASH',
  PACKET: 'PACKET',
  SYNC_TARGET: 'SYNC_TARGET',
  ATTACHMENTS: 'ATTACHMENTS',
  INBOX: 'INBOX',
  OFFER: 'OFFER',
  PACKAGE: 'PACKAGE',
  BUYER_PROSPECT_PROPERTY: 'BUYER_PROSPECT_PROPERTY',
  PROPERTY_INFO: 'PROPERTY_INFO',
} as const;

export type ItemFolderKind = typeof ItemFolderKind[keyof typeof ItemFolderKind];

export interface ItemTimeline {
  setupFlowId: string;
  setupFlow?: Flow;
  /** list of tds that were uploaded */
  sourceTdvIds: string[];
  sourceTdvs: Item[];
  /** list of tds that were created with split */
  splitTdvIds: string[];
  splitTdvs: Item[];
  ocrKeyDates: KeyDate[];
  splits: string[];
  splitStatus: ItemTimelineSplitStatus;
  highlights: ItemTimelineHighlight[];
  setupStatus: ItemTimelineSetupStatus;
  calendarSync?: ItemTimelineCalendarSync;
}

export const ItemTimelineSplitStatus = {
  NOT_STARTED: 'NOT_STARTED',
  SPLITING: 'SPLITING',
  COMPLETED: 'COMPLETED',
  FAILED: 'FAILED',
} as const;

export type ItemTimelineSplitStatus =
  typeof ItemTimelineSplitStatus[keyof typeof ItemTimelineSplitStatus];

export const ItemTimelineSetupStatus = {
  DEFAULT: 'DEFAULT',
  STARTED: 'STARTED',
  FINISHED: 'FINISHED',
} as const;

export type ItemTimelineSetupStatus =
  typeof ItemTimelineSetupStatus[keyof typeof ItemTimelineSetupStatus];

export interface ItemTimelineHighlight {
  name: string;
  pageNumber: number;
  xMin: number;
  xMax: number;
  yMin: number;
  yMax: number;
}

export interface ItemTimelineCalendarSync {
  app: string;
  tokenUuid: string;
  events: ItemTimelineCalendarSyncEvent[];
  senderId: string;
  sub: string;
}

export interface ItemTimelineCalendarSyncEvent {
  keyDate: string;
  partyIds: string[];
  eventId: string;
  updatedAt: number;
}

/** Timeline Date ----------------------------------------- */
export interface ItemTimelineDate {
  key: string;
  date: string;
  description: string;
  custom: boolean;
  visibility: ItemTimelineDateTimelineViewer;
  responsibility: ItemTimelineDateTimelineViewer;
  status: ItemTimelineDateStatus;
  labelIds: string[];
}

export const ItemTimelineDateTimelineViewer = {
  HIDDEN: 'HIDDEN',
  EVERYONE: 'EVERYONE',
  BUYER: 'BUYER',
  SELLER: 'SELLER',
  PROS: 'PROS',
} as const;

export type ItemTimelineDateTimelineViewer =
  typeof ItemTimelineDateTimelineViewer[keyof typeof ItemTimelineDateTimelineViewer];

export const ItemTimelineDateStatus = {
  NO_ACTION: 'NO_ACTION',
  COMPLETE: 'COMPLETE',
} as const;

export type ItemTimelineDateStatus =
  typeof ItemTimelineDateStatus[keyof typeof ItemTimelineDateStatus];

export interface ItemTemplateSetting {
  status: ItemTemplateSettingStatus;
}

export const ItemTemplateSettingStatus = {
  DRAFT: 'DRAFT',
  INACTIVE: 'INACTIVE',
  ACTIVE: 'ACTIVE',
} as const;

export type ItemTemplateSettingStatus =
  typeof ItemTemplateSettingStatus[keyof typeof ItemTemplateSettingStatus];

export interface ItemTransactionMerge {
  fromTransId: string;
}

export interface ItemLoadSummary {
  userId: string;
  lastLoadTime: number;
  previousLoadTime: number;
  badgeCount: number;
  page: ItemLoadSummaryPage;
}

export const ItemLoadSummaryPage = {
  UNKNOWN: 'UNKNOWN',
  DOCUMENTS: 'DOCUMENTS',
} as const;

export type ItemLoadSummaryPage =
  typeof ItemLoadSummaryPage[keyof typeof ItemLoadSummaryPage];

export interface ItemCoverPhoto {
  content?: MarketingContent;
}

export interface ItemPacket {
  childKind: ItemKind;
  childId: string;
  child?: Item;
  creatorId: string;
  creatorUserId: string;
  creatorUser?: User;
  archived: boolean;
  sessions: { [key: string]: ItemPacketSession };
  folderId: string;
  folder?: Item;
}

export interface ItemPacketSession {
  registeredAt: number;
}

export interface ItemPacketSessionsEntry {
  key: string;
  value?: ItemPacketSession;
}

export interface ItemSignatureRequest {
  status: ItemSignatureRequestStatus;
  envelopeId: string;
  hookToken: string;
  dsSub: string;
  completedAt: number;
  sentAt: number;
  replace: boolean;
  statusTimeGenTs: string;
  dsDocs: ItemSignatureRequestDSDocument[];
  dsRecipients: ItemSignatureRequestDSRecipient[];
  moveOriginalToTrash: boolean;
  senderId: string;
  archived: boolean;
  destFolderId: string;
  provider: ItemSignatureRequestProvider;
  flowId: string;
  wasPreparingStatus: boolean;
  envelopeIntId: string;
  revisionFlowId: string;
  /**
   * The signing envelope will signal this aggregate if a revision
   * was committed using this flag using the refresh_signature_request
   * intent.
   * It's only purpose as of this writing is to create the proper
   * activity kind once a revision is closed by either cancelling it
   * or submitting it.
   */
  revisionCommitted: boolean;
  revisorId: string;
  /** Make documents visible on client dashboard after signing is completed */
  makeDocumentsVisibleInCd: boolean;
}

export const ItemSignatureRequestStatus = {
  DRAFT: 'DRAFT',
  SENT: 'SENT',
  SIGNED: 'SIGNED',
  DECLINED: 'DECLINED',
  CANCELLED: 'CANCELLED',
  DELIVERED: 'DELIVERED',
  COMPLETED: 'COMPLETED',
  VOIDED: 'VOIDED',
  DELETED: 'DELETED',
  CORRECT: 'CORRECT',
  /** PREPARING - in flow stage; not sent to provider yet */
  PREPARING: 'PREPARING',
  /** REVISING - locked for revision */
  REVISING: 'REVISING',
  /** IN_PROGRESS - in progress */
  IN_PROGRESS: 'IN_PROGRESS',
} as const;

export type ItemSignatureRequestStatus =
  typeof ItemSignatureRequestStatus[keyof typeof ItemSignatureRequestStatus];

export const ItemSignatureRequestProvider = {
  DOCUSIGN: 'DOCUSIGN',
  GLIDE: 'GLIDE',
} as const;

export type ItemSignatureRequestProvider =
  typeof ItemSignatureRequestProvider[keyof typeof ItemSignatureRequestProvider];

export interface ItemSignatureRequestDSRecipient {
  /** this is a misnomer as it represents the recipient in the provider, be it DS or glide */
  id: string;
  userName: string;
  email: string;
  status: string;
  order: number;
  recipientRole: string;
}

export interface ItemSignatureRequestDSDocument {
  /** this is a misnomer as it represents the doc in the provider, be it DS or glide */
  id: string;
  name: string;
  seq: number;
}

export interface ItemCoverSheet {
  useBrokerageEditionLogo: boolean;
  instructions?: ItemCoverSheetInstructions;
  signatures?: ItemCoverSheetSignatures;
  includeEscrow: boolean;
  includeCoverPhoto: boolean;
  publishedUrlByMap: { [key: string]: string };
  lastUpdatedAt: number;
  accessToken: string;
  lastPublishedInfo?: ItemCoverSheetPublishedInfo;
  isActive: boolean;
  dirty: boolean;
  lastTdVers: string;
}

export interface ItemCoverSheetInstructions {
  include: boolean;
  body: string;
}

export interface ItemCoverSheetPublishedInfo {
  title: string;
  mapImageUrl: string;
  txnAddress?: Address;
  principalLabel: string;
  agentLabel: string;
  agentOne?: Contact;
  agentTwo?: Contact;
  instructions: string;
  includeInstructions: boolean;
  includeFooter: boolean;
  includeBrokers: boolean;
  includeEscrow: boolean;
  documents: ItemCoverSheetPublishedInfoDocument[];
  brokerage?: ItemCoverSheetPublishedInfoCompany;
  escrow?: ItemCoverSheetPublishedInfoCompany;
  buyerSignatureBody: string;
  isPhotoDefault: boolean;
  includeCoverPhoto: boolean;
}

export interface ItemCoverSheetPublishedInfoCompany {
  agentName: string;
  email: string;
  cellPhone: string;
  licenseNumber: string;
  companyName: string;
  companyAddress1: string;
  companyAddress2: string;
  companyWebsite: string;
  companyLogoUrl: string;
}

export interface ItemCoverSheetPublishedInfoDocument {
  title: string;
  pages: string;
}

export interface ItemCoverSheetSignatures {
  buyerSide: boolean;
  buyerSignatureBody: string;
}

export interface ItemCoverSheetPublishedUrlByMapEntry {
  key: string;
  value: string;
}

export interface ItemSharePacket {
  /** Following 4 fields are deprecated and will be deleted after backfill */
  oldCoverSheet?: ItemSharePacketCoverSheet;
  publishedCoversheetUrl: string;
  coverSheetToken: string;
  publishedInfo?: ItemSharePacketCoverSheetInfo;
  uuid: string;
  status: ItemSharePacketStatus;
  publicationId: string;
  publication?: Item;
  currentTdvIds: string[];
  publishedTdvIds: string[];
  hasUnpublishedChanges: boolean;
  defaultTitle: string;
  linkShareMode: ItemSharePacketLinkShareMode;
  requestAccessPath: string;
  anonymousShareCount: number;
  coverSheetId: string;
  coverSheet?: Item;
  DeprecatedNotifyVisitorAccess: boolean;
  disableNotifyVisitorAccess: boolean;
  accessStatus: ItemSharePacketAccessStatus;
}

export const ItemSharePacketLinkShareMode = {
  LINK_SHARE_NEVER_ENABLED: 'LINK_SHARE_NEVER_ENABLED',
  LINK_SHARE_MANUAL_APPROVE: 'LINK_SHARE_MANUAL_APPROVE',
  LINK_SHARE_AUTO_APPROVE: 'LINK_SHARE_AUTO_APPROVE',
  LINK_SHARE_DISABLED: 'LINK_SHARE_DISABLED',
  LINK_SHARE_QUICK_SHARE: 'LINK_SHARE_QUICK_SHARE',
} as const;

export type ItemSharePacketLinkShareMode =
  typeof ItemSharePacketLinkShareMode[keyof typeof ItemSharePacketLinkShareMode];

export const ItemSharePacketStatus = { DRAFT: 'DRAFT', SENT: 'SENT' } as const;

export type ItemSharePacketStatus =
  typeof ItemSharePacketStatus[keyof typeof ItemSharePacketStatus];

export const ItemSharePacketAccessStatus = {
  ENABLED: 'ENABLED',
  DISABLED: 'DISABLED',
} as const;

export type ItemSharePacketAccessStatus =
  typeof ItemSharePacketAccessStatus[keyof typeof ItemSharePacketAccessStatus];

export interface ItemSharePacketCoverSheetInfo {
  title: string;
  mapImageUrl: string;
  txnAddress?: Address;
  principalLabel: string;
  agentLabel: string;
  agentOne?: Contact;
  agentTwo?: Contact;
  instructions: string;
  includeInstructions: boolean;
  includeFooter: boolean;
  includeBrokers: boolean;
  includeEscrow: boolean;
  documents: ItemSharePacketCoverSheetInfoDocument[];
  brokerage?: ItemSharePacketCoverSheetInfoCompany;
  escrow?: ItemSharePacketCoverSheetInfoCompany;
  buyerSignatureBody: string;
  isPhotoDefault: boolean;
  includeCoverPhoto: boolean;
}

export interface ItemSharePacketCoverSheetInfoCompany {
  agentName: string;
  email: string;
  cellPhone: string;
  licenseNumber: string;
  companyName: string;
  companyAddress1: string;
  companyAddress2: string;
  companyWebsite: string;
  companyLogoUrl: string;
}

export interface ItemSharePacketCoverSheetInfoDocument {
  title: string;
  pages: string;
  tdvId: string;
}

export interface ItemSharePacketCoverSheet {
  useBrokerageEditionLogo: boolean;
  instructions?: ItemSharePacketCoverSheetInstructions;
  signatures?: ItemSharePacketCoverSheetSignatures;
  includeEscrow: boolean;
  includeCoverPhoto: boolean;
  lastUpdatedAt: number;
}

export interface ItemSharePacketCoverSheetInstructions {
  include: boolean;
  body: string;
}

export interface ItemSharePacketCoverSheetSignatures {
  buyerSide: boolean;
  buyerSignatureBody: string;
}

export interface ItemSharePacketPublication {
  coverSheetUrl: string;
  coverSheetInfo?: ItemCoverSheetPublishedInfo;
  allDocumentsZipId: string;
  allDocumentsPdfId: string;
}

export interface ItemGuidedFormPacket {
  ObsoleteRecipientsIds: string[];
  ObsoleteCc: string[];
  status: ItemGuidedFormPacketStatus;
  creatorId: string;
  creatorUserId: string;
  creatorUser?: User;
  emailSubject: string;
  submittedAt: number;
  emailBody: string;
  completedAt: number;
  /** The flow id that created this gfp. */
  flowId: string;
  invitesIds: string[];
  recipients: Recipient[];
  documentId: string;
  document?: Document;
  completionToken: string;
  creationMode: ItemGuidedFormPacketCreationMode;
  /** contains mapping of submit version to document_id */
  ObsoleteDocuments: { [key: string]: string };
  /** transaction version associated with the generated set of PDFs */
  generatedVers: string;
  /** transaction version associated with the generated, combined PDF document_id */
  generatedDocumentId: string;
  generatedAt: number;
  generatedDocument?: Document;
  /** version of transaction when review flow was created */
  reviewVers: string;
  /** this used to be one version */
  ObsoleteReviewFlowId: string;
  senderPartyId: string;
  tutorialFlowId: string;
  disableInPersonMode: boolean;
  didSendNotStartedReminder: boolean;
  zfTxnId: string;
  /**
   * Set to "DIA" or blank, indicates whether to show DIA tutorial or
   * the legacy T&C tutorial.
   */
  tutorialMode: string;
  signatureRecords: ItemGuidedFormPacketGfpSignatureRecord[];
  myTutorialContext?: ItemGuidedFormPacketGfpSignatureRecord;
  didSendStartEmail: boolean;
  /** last review flow used for generating the forms. */
  generatedReviewFlowId: string;
  /** version of the transaction when client submitted the packet last time */
  lastSubmittedVers: string;
  /** version of the transaction when client submitted the packet last to last time */
  penultimateSubmittedVers: string;
  unnotifiedCommentIds: string[];
  lastCommentNotifiedVers: string;
  lastAgentFlagNotifiedVers: string;
  locked: boolean;
  /** Used for versioning */
  revision: number;
  /** Audit log certificate pdf document id */
  certificateTdId: string;
  generateResult?: ItemGuidedFormPacketGfpGenerateResult;
  gfpEndTestFlowId: string;
  gfpEndTestType: string;
  psd?: ItemGuidedFormPacketPsd;
  psdSurveyResults?: ItemGuidedFormPacketSurveyResults;
  notValidateTos: boolean;
  fillAndSignConfig?: FillAndSignConfig;
  signatureRequestId: string;
}

export const ItemGuidedFormPacketStatus = {
  DRAFT: 'DRAFT',
  CREATED: 'CREATED',
  /** STARTED - TODO: rename to IN_PROGRESS */
  STARTED: 'STARTED',
  FLOWS_COMPLETED: 'FLOWS_COMPLETED',
  SUBMITTED: 'SUBMITTED',
  FLOWS_EDITED: 'FLOWS_EDITED',
  FLOWS_EDITED_INCOMPLETE: 'FLOWS_EDITED_INCOMPLETE',
  LOCKED: 'LOCKED',
  COMPLETED: 'COMPLETED',
  NOT_STARTED: 'NOT_STARTED',
  OBSOLETE_READY_TO_SIGN: 'OBSOLETE_READY_TO_SIGN',
  OBSOLETE_CANCELLED: 'OBSOLETE_CANCELLED',
  OBSOLETE_COMPLETED: 'OBSOLETE_COMPLETED',
  OBSOLETE_SUBMITTED_WITH_FLAG: 'OBSOLETE_SUBMITTED_WITH_FLAG',
  OBSOLETE_SUBMITTING: 'OBSOLETE_SUBMITTING',
} as const;

export type ItemGuidedFormPacketStatus =
  typeof ItemGuidedFormPacketStatus[keyof typeof ItemGuidedFormPacketStatus];

export const ItemGuidedFormPacketCreationMode = {
  AGENT: 'AGENT',
  CLIENT: 'CLIENT',
  AGENT_INPERSON: 'AGENT_INPERSON',
} as const;

export type ItemGuidedFormPacketCreationMode =
  typeof ItemGuidedFormPacketCreationMode[keyof typeof ItemGuidedFormPacketCreationMode];

export interface ItemGuidedFormPacketGfpSignatureRecord {
  uuid: string;
  userId: string;
  completed: boolean;
  flowId: string;
  signature?: SignatureRecord;
  tags: string[];
}

export interface ItemGuidedFormPacketGfpGenerateResult {
  gfpTdvIds: { [key: string]: string };
  certificateTdvId: string;
  mergedDocumentId: string;
  attachmentTdvIds: string[];
}

export interface ItemGuidedFormPacketGfpGenerateResultGfpTdvIdsEntry {
  key: string;
  value: string;
}

export interface ItemGuidedFormPacketSurveyResults {
  satisfaction: string;
  usefulness: string;
  extra: string;
  completed: boolean;
  declineReason: string;
}

export interface ItemGuidedFormPacketObsoleteDocumentsEntry {
  key: string;
  value: string;
}

export interface ItemGuidedFormPacketPsd {
  status: ItemGuidedFormPacketPsdStatus;
  exemptReason: ItemGuidedFormPacketPsdExemptReason;
  premiumReason: ItemGuidedFormPacketPsdPremiumReason;
  /**
   * To make it easy for now price is just the whole amount of USD and we do not
   * support cents. $59.00 is stored as 59.
   *
   * If in the future we want cents we'd need to implement a message
   * Money with separate whole units and cents.
   */
  price: number;
  agentComment: string;
  upgradeSaleId: string;
  bundlePrice: number;
  prices?: { [key: string]: any };
  payLater: boolean;
}

export const ItemGuidedFormPacketPsdStatus = {
  /** EXEMPT - User is not qualified for PSD (will never upgrade) */
  EXEMPT: 'EXEMPT',
  /** BASIC - Basic will an option to upgrade (we'll try to upsell) */
  BASIC: 'BASIC',
  /** PREMIUM - User is on premium (purchased or any other reason) */
  PREMIUM: 'PREMIUM',
  /** UPSELL_ONLY - for inital price and demand test only */
  UPSELL_ONLY: 'UPSELL_ONLY',
} as const;

export type ItemGuidedFormPacketPsdStatus =
  typeof ItemGuidedFormPacketPsdStatus[keyof typeof ItemGuidedFormPacketPsdStatus];

export const ItemGuidedFormPacketPsdExemptReason = {
  NOT_EXEMPT: 'NOT_EXEMPT',
  NO_PREMIUM_FORMS: 'NO_PREMIUM_FORMS',
  SF_TRANSACTION: 'SF_TRANSACTION',
  TC_OPTOUT: 'TC_OPTOUT',
  AGENT_OPTOUT: 'AGENT_OPTOUT',
  BROKERAGE_OPTOUT: 'BROKERAGE_OPTOUT',
  ASSOCIATION_OPTOUT: 'ASSOCIATION_OPTOUT',
  PURCHASE_SIDE: 'PURCHASE_SIDE',
  PRODUCT_BETA: 'PRODUCT_BETA',
  BETA_OPTOUT: 'BETA_OPTOUT',
  NO_PSD_FORMS: 'NO_PSD_FORMS',
} as const;

export type ItemGuidedFormPacketPsdExemptReason =
  typeof ItemGuidedFormPacketPsdExemptReason[keyof typeof ItemGuidedFormPacketPsdExemptReason];

export const ItemGuidedFormPacketPsdPremiumReason = {
  NOT_PREMIUM: 'NOT_PREMIUM',
  INITIAL_DEMO: 'INITIAL_DEMO',
  FIRST_FREE_TRANSACTION: 'FIRST_FREE_TRANSACTION',
  PRO_AGENT: 'PRO_AGENT',
  BROKERAGE_PAID: 'BROKERAGE_PAID',
  SELLER_UPGRADED: 'SELLER_UPGRADED',
} as const;

export type ItemGuidedFormPacketPsdPremiumReason =
  typeof ItemGuidedFormPacketPsdPremiumReason[keyof typeof ItemGuidedFormPacketPsdPremiumReason];

export interface ItemGuidedFormPacketFlow {
  status: ItemGuidedFormPacketFlowStatus;
  formFlowConfigId: string;
  formFlowConfig?: FormFlowConfig;
  flowId: string;
  flow?: Flow;
  documentId: string;
  document?: Document;
  tdId: string;
  td?: Item;
  tdvId: string;
  tdv?: Item;
  completedAt: number;
  ObsoleteDocuments: { [key: string]: string };
  /** The txn version from which the gfpf PDF was generated during GFP_GENERATE */
  generatedVers: string;
  /** The document ID that was generated during GFP_GENERATE */
  generatedDocumentId: string;
  generatedDocument?: Document;
  generatedFlowVers: string;
  submittedFlowVers: string;
  isPremium: boolean;
  order: number;
  archived: boolean;
  userReviews: ItemGuidedFormPacketFlowUserReview[];
  previewPdf?: ItemGuidedFormPacketFlowPreviewPdf;
}

export const ItemGuidedFormPacketFlowStatus = {
  DRAFT: 'DRAFT',
  CREATED: 'CREATED',
  STARTED: 'STARTED',
  FLOW_COMPLETED: 'FLOW_COMPLETED',
  SUBMITTED: 'SUBMITTED',
  FLOW_EDITED: 'FLOW_EDITED',
  FLOW_EDITED_INCOMPLETE: 'FLOW_EDITED_INCOMPLETE',
  OBSOLETE_CANCELLED: 'OBSOLETE_CANCELLED',
  OBSOLETE_READY_TO_SIGN: 'OBSOLETE_READY_TO_SIGN',
  OBSOLETE_COMPLETED: 'OBSOLETE_COMPLETED',
  OBSOLETE_SUBMITTED_WITH_FLAG: 'OBSOLETE_SUBMITTED_WITH_FLAG',
} as const;

export type ItemGuidedFormPacketFlowStatus =
  typeof ItemGuidedFormPacketFlowStatus[keyof typeof ItemGuidedFormPacketFlowStatus];

export interface ItemGuidedFormPacketFlowObsoleteDocumentsEntry {
  key: string;
  value: string;
}

export interface ItemGuidedFormPacketFlowUserReview {
  userId: string;
  lastReviewedTs: number;
}

export interface ItemGuidedFormPacketFlowPreviewPdf {
  filename: string;
  url: string;
  generatedAt: number;
}

export interface ItemNhd {
  status: ItemNhdStatus;
  provider: ItemNhdProvider;
  reportType: ItemNhdReportType;
  tdvIds: string[];
  orderedAt: number;
  declineReason: string;
  orderId: string;
  vendorCode: string;
  promoVersion: number;
  selectionMode: ItemNhdSelectionMode;
  /** Selection mode SELLER fields */
  setupPartyId: string;
  /** personalized message agent might add */
  setupMessage: string;
  /** seller flow */
  pickNhdProviderFlowId: string;
  orderDetails?: ItemNhdOrderDetails;
}

export const ItemNhdStatus = {
  NOT_STARTED: 'NOT_STARTED',
  ORDER_PLACED: 'ORDER_PLACED',
  ORDER_DELAYED: 'ORDER_DELAYED',
  COMPLETED: 'COMPLETED',
  NOT_INTERESTED: 'NOT_INTERESTED',
  SENT_TO_SELLER: 'SENT_TO_SELLER',
  CANCELLED: 'CANCELLED',
} as const;

export type ItemNhdStatus = typeof ItemNhdStatus[keyof typeof ItemNhdStatus];

export const ItemNhdProvider = {
  UNKNOWN: 'UNKNOWN',
  SNAP_NHD: 'SNAP_NHD',
  GLIDE: 'GLIDE',
  JCP: 'JCP',
  FIRST_AMERICAN: 'FIRST_AMERICAN',
  PROPERTY_ID: 'PROPERTY_ID',
  MY_NHD: 'MY_NHD',
  DISCLOSURE_SOURCE: 'DISCLOSURE_SOURCE',
  NHD_GO: 'NHD_GO',
  /** DISCLOSURE_SAVE - Deprecated */
  DISCLOSURE_SAVE: 'DISCLOSURE_SAVE',
} as const;

export type ItemNhdProvider =
  typeof ItemNhdProvider[keyof typeof ItemNhdProvider];

export const ItemNhdReportType = {
  UNKNOWN_REPORT_TYPE: 'UNKNOWN_REPORT_TYPE',
  STANDARD: 'STANDARD',
  PREMIUM: 'PREMIUM',
  MIDDLE: 'MIDDLE',
} as const;

export type ItemNhdReportType =
  typeof ItemNhdReportType[keyof typeof ItemNhdReportType];

export const ItemNhdSelectionMode = {
  AGENT: 'AGENT',
  SELLER: 'SELLER',
} as const;

export type ItemNhdSelectionMode =
  typeof ItemNhdSelectionMode[keyof typeof ItemNhdSelectionMode];

export interface ItemNhdOrderDetails {
  agentContact?: Contact;
  orderContact?: Contact;
  escrowContact?: Contact;
  sellerContact?: Contact;
  apn: string;
  escrowNumber: string;
  isMobileHome: boolean;
  orderedBy: string;
  /** Disclosure Save order fields */
  propertyTypeId: string;
  promoCode: string;
}

export interface ItemAvid {
  taskId: string;
  preFlowId: string;
  status: ItemAvidAvidTaskStatus;
  completionMode: ItemAvidCompletionMode;
  avidFlows: ItemAvidAvidFlow[];
  fillRole: PartyRole;
  superFlowDetails?: ItemAvidSuperFlowDetails;
  agentInviteMessage?: ItemAvidInviteMessage;
  attachmentTdvIds: string[];
}

export const ItemAvidCompletionMode = {
  FILL: 'FILL',
  MANUAL: 'MANUAL',
} as const;

export type ItemAvidCompletionMode =
  typeof ItemAvidCompletionMode[keyof typeof ItemAvidCompletionMode];

export const ItemAvidFlowStatus = {
  NOT_STARTED: 'NOT_STARTED',
  STARTED: 'STARTED',
  COMPLETED: 'COMPLETED',
  GENERATED: 'GENERATED',
  UPLOADED: 'UPLOADED',
} as const;

export type ItemAvidFlowStatus =
  typeof ItemAvidFlowStatus[keyof typeof ItemAvidFlowStatus];

export const ItemAvidAvidTaskStatus = {
  AVID_DRAFT: 'AVID_DRAFT',
  AVID_CREATED: 'AVID_CREATED',
  AVID_STARTED: 'AVID_STARTED',
  AVID_COMPLETED: 'AVID_COMPLETED',
  AVID_GENERATED: 'AVID_GENERATED',
  AVID_UPLOADED: 'AVID_UPLOADED',
} as const;

export type ItemAvidAvidTaskStatus =
  typeof ItemAvidAvidTaskStatus[keyof typeof ItemAvidAvidTaskStatus];

export const ItemAvidInviteMode = {
  EMAIL: 'EMAIL',
  SMS: 'SMS',
  EMAIL_AND_SMS: 'EMAIL_AND_SMS',
} as const;

export type ItemAvidInviteMode =
  typeof ItemAvidInviteMode[keyof typeof ItemAvidInviteMode];

export const ItemAvidSchedule = {
  SENT: 'SENT',
  PENDING: 'PENDING',
  CANCELLED: 'CANCELLED',
} as const;

export type ItemAvidSchedule =
  typeof ItemAvidSchedule[keyof typeof ItemAvidSchedule];

export interface ItemAvidAvidFlow {
  flowId: string;
  unitNumber: string;
  tdvId: string;
  status: ItemAvidFlowStatus;
  formTag: string;
  title: string;
}

export interface ItemAvidInviteMessage {
  subject: string;
  body: string;
  mode: ItemAvidInviteMode;
  schedule: ItemAvidSchedule;
  sendAt: number;
  sms: string;
  attempts: number;
}

export interface ItemAvidSuperFlowDetails {
  id: string;
  redirect?: Route;
}

/** Fields */
export interface ItemTransactionFields {
  namespace: string;
  isSnapshot: boolean;
}

/** Tasks */
export interface ItemItemLabel {
  color: string;
  label: string;
}

export interface ItemTaskReminder {
  reminder?: Reminder;
  calculatedSendDate: string;
  taskId: string;
  status: ItemTaskReminderStatus;
  isDefault: boolean;
}

export const ItemTaskReminderStatus = {
  PENDING: 'PENDING',
  SENT: 'SENT',
  FAILED: 'FAILED',
  SKIPPED: 'SKIPPED',
} as const;

export type ItemTaskReminderStatus =
  typeof ItemTaskReminderStatus[keyof typeof ItemTaskReminderStatus];

export interface ItemTask {
  notes: string;
  status: ItemTaskStatus;
  orderIndex: number;
  /**
   * Let's not show this in JSON to avoid the temptation of referencing
   * this instead of edges.
   */
  tdsIds: string[];
  tds: Item[];
  date: string;
  dateComputation?: DateComputation;
  taskKind: ItemTaskTaskKind;
  type: ItemTaskType;
  /** type fields 100 - 150 */
  syncChecklist?: ItemTaskSyncChecklist;
  syncTask?: ItemTaskSyncTask;
  pullEtms?: ItemTaskPullEtms;
  scheduleMeeting?: ItemTaskScheduleMeeting;
  completeGfp?: ItemTaskCompleteGFP;
  gfp?: ItemTaskGfp;
  signatureRequest?: ItemTaskSignatureRequest;
  sharePacket?: ItemTaskSharePacket;
  avid?: ItemTaskAvid;
  timeline?: ItemTaskTimeline;
  importZipformDocs?: ItemTaskImportZipformDocs;
  esign?: ItemTaskEsign;
  startPacketSharing?: ItemTaskStartPacketSharing;
  orderNhd?: ItemTaskOrderNhd;
  checklistTmpl?: ItemTaskChecklistTmpl;
  checklistGroupTmpl?: ItemTaskChecklistGroupTmpl;
  checklistItemTmpl?: ItemTaskChecklistItemTmpl;
  checklistBoard?: ItemTaskChecklistBoard;
  checklistGroup?: ItemTaskChecklistGroup;
  checklistItem?: ItemTaskChecklistItem;
  pead?: ItemTaskPead;
  keyDate?: KeyDate;
  OBSOLETECommissionAdvance?: ItemTaskOBSOLETECommissionAdvance;
  dependentTaskIds: string[];
  assigneeUserId: string;
  labels: Label[];
  membersIds: string[];
  members: Item[];
  /** show up on index; optimization */
  isVisibleItem: boolean;
  /** show up on client index; optimization */
  isClientVisibleItem: boolean;
  reminderIds: string[];
  isTransactionVisibleItem: boolean;
  reminderTriggers: ItemTaskReminderTriggers[];
  isArchived: boolean;
  /** while task.title will contain the verb, name will contain the noun */
  name: string;
  didComplete: boolean;
  didSkip: boolean;
  boardId: string;
}

export const ItemTaskTaskKind = {
  UNKNOWN: 'UNKNOWN',
  BOARD_TASK: 'BOARD_TASK',
  SECTION_TASK: 'SECTION_TASK',
  TASK: 'TASK',
  GROUP: 'GROUP',
} as const;

export type ItemTaskTaskKind =
  typeof ItemTaskTaskKind[keyof typeof ItemTaskTaskKind];

export const ItemTaskType = {
  DEFAULT: 'DEFAULT',
  KEY_DATE: 'KEY_DATE',
  SYNC_TASK: 'SYNC_TASK',
  OPEN_IN_ETMS: 'OPEN_IN_ETMS',
  PULL_ETMS: 'PULL_ETMS',
  SYNC_CHECKLIST: 'SYNC_CHECKLIST',
  DEFAULT_SECTION: 'DEFAULT_SECTION',
  INVOICE: 'INVOICE',
  SCHEDULE_MEETING: 'SCHEDULE_MEETING',
  COMPLETE_GFP: 'COMPLETE_GFP',
  GFP: 'GFP',
  SIGNATURE_REQUEST: 'SIGNATURE_REQUEST',
  SHARE_PACKET: 'SHARE_PACKET',
  TIMELINE: 'TIMELINE',
  AVID: 'AVID',
  COVER_SHEET: 'COVER_SHEET',
  IMPORT_ZIPFORM_DOCS: 'IMPORT_ZIPFORM_DOCS',
  ESIGN: 'ESIGN',
  START_PACKET_SHARING: 'START_PACKET_SHARING',
  ORDER_NHD: 'ORDER_NHD',
  CHECKLIST_TMPL: 'CHECKLIST_TMPL',
  CHECKLIST_GROUP_TMPL: 'CHECKLIST_GROUP_TMPL',
  CHECKLIST_ITEM_TMPL: 'CHECKLIST_ITEM_TMPL',
  CHECKLIST_BOARD: 'CHECKLIST_BOARD',
  CHECKLIST_GROUP: 'CHECKLIST_GROUP',
  CHECKLIST_ITEM: 'CHECKLIST_ITEM',
  PEAD: 'PEAD',
  OBSOLETE_COMMISSION_ADVANCE: 'OBSOLETE_COMMISSION_ADVANCE',
} as const;

export type ItemTaskType = typeof ItemTaskType[keyof typeof ItemTaskType];

export const ItemTaskStatus = {
  INVALID: 'INVALID',
  OPEN: 'OPEN',
  ARCHIVED: 'ARCHIVED',
  CLOSED: 'CLOSED',
  IN_PROGRESS: 'IN_PROGRESS',
  PRE_EXISTANT: 'PRE_EXISTANT',
  DORMANT: 'DORMANT',
  SKIPPED: 'SKIPPED',
} as const;

export type ItemTaskStatus = typeof ItemTaskStatus[keyof typeof ItemTaskStatus];

export interface ItemTaskPullEtms {
  flowId: string;
}

export interface ItemTaskOrderNhd {
  orderFlowId: string;
  nhdId: string;
}

export interface ItemTaskEsign {}

export interface ItemTaskStartPacketSharing {}

export interface ItemTaskScheduleMeeting {
  meetingType: string;
  flowId: string;
}

export interface ItemTaskCompleteGFP {
  flowId: string;
  formTitle: string;
}

export interface ItemTaskSyncTask {}

export interface ItemTaskCoverSheet {}

export interface ItemTaskImportZipformDocs {
  flowId: string;
}

export interface ItemTaskSyncChecklist {
  modifiedTaskIds: string[];
}

export interface ItemTaskGfp {
  gfpId: string;
  status: ItemGuidedFormPacketStatus;
  locked: boolean;
  creationMode: ItemGuidedFormPacketCreationMode;
  flowId: string;
}

export interface ItemTaskSignatureRequest {
  signatureRequestId: string;
  status: ItemSignatureRequestStatus;
}

export interface ItemTaskSharePacket {
  sharePacketId: string;
}

export interface ItemTaskAvid {
  flowId: string;
  stage: ItemTaskAvidStage;
  zfTxnId: string;
  agentPartyId: string;
  tcPartyId: string;
  inviteFlowId: string;
  tdvId: string;
  agentRole: PartyRole;
  /** all above fields are deprecated and will be removed after backfill */
  preFlowId: string;
  avidId: string;
  status: ItemAvidAvidTaskStatus;
  avidVersion: string;
}

export const ItemTaskAvidStage = {
  UNKNOWN: 'UNKNOWN',
  SENT: 'SENT',
  STARTED: 'STARTED',
  COMPLETED: 'COMPLETED',
  DRAFT: 'DRAFT',
  NOT_STARTED: 'NOT_STARTED',
  READY_TO_GENERATE: 'READY_TO_GENERATE',
} as const;

export type ItemTaskAvidStage =
  typeof ItemTaskAvidStage[keyof typeof ItemTaskAvidStage];

export interface ItemTaskTimeline {
  timelineId: string;
  setupFlowId: string;
}

export interface ItemTaskChecklistTmpl {
  checklistType: ChecklistTypeEnum;
  conditions: ItemTaskChecklistTmplCondition[];
  description: string;
}

export interface ItemTaskChecklistTmplCondition {
  kind: ItemTaskChecklistTmplConditionKind;
  targetIds: string[];
  isBlacklist: boolean;
}

export const ItemTaskChecklistTmplConditionKind = {
  OFFICE: 'OFFICE',
  CITY: 'CITY',
} as const;

export type ItemTaskChecklistTmplConditionKind =
  typeof ItemTaskChecklistTmplConditionKind[keyof typeof ItemTaskChecklistTmplConditionKind];

export interface ItemTaskChecklistGroupTmpl {}

export interface ItemTaskChecklistItemTmpl {
  required: ChecklistItemRequiredEnum;
  description: string;
  relatedFormIds: string[];
  relatedSeriesIds: string[];
  srConfigs: SmartRecommendationConfig[];
}

export interface ItemTaskChecklistBoard {
  appliedChecklists: ItemTaskChecklistBoardAppliedChecklist[];
}

export interface ItemTaskChecklistBoardAppliedChecklist {
  id: string;
  /** Item ID of the CHECKLIST_TMPL */
  itemId: string;
  /** Transaction ID */
  tid: string;
  /** Version */
  vers: string;
  title: string;
  partyId: string;
  userId: string;
  appliedAt: number;
}

export interface ItemTaskChecklistGroup {
  appliedChecklistIds: string[];
}

export interface ItemTaskChecklistItem {
  required: ChecklistItemRequiredEnum;
  description: string;
  isExempt: boolean;
  relatedSeriesIds: string[];
  srConfigs: SmartRecommendationConfig[];
  message: string;
  lastActivityId: string;
  tdAssignmentsChangedAt: number;
  reviewStatus: ChecklistItemReviewStatusEnum;
  label?: Label;
  appliedChecklistIds: string[];
}

export interface ItemTaskOBSOLETECommissionAdvance {
  flowId: string;
  applyStatus: ItemTaskOBSOLETECommissionAdvanceApplyStatus;
}

export const ItemTaskOBSOLETECommissionAdvanceApplyStatus = {
  NOT_STARTED: 'NOT_STARTED',
  STARTED: 'STARTED',
  APPLIED: 'APPLIED',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
} as const;

export type ItemTaskOBSOLETECommissionAdvanceApplyStatus =
  typeof ItemTaskOBSOLETECommissionAdvanceApplyStatus[keyof typeof ItemTaskOBSOLETECommissionAdvanceApplyStatus];

export interface ItemTaskPead {
  flowId: string;
  peadId: string;
  /** YYYY-MM-DD */
  date: string;
  status: ItemPeadStatus;
}

/** Trigger on which a task reminder could be sent to different parties */
export interface ItemTaskReminderTriggers {
  key: string;
  value: string;
}

export interface ItemGuidedFormPreview {
  formFlowConfigId: string;
  formFlowConfig?: FormFlowConfig;
  flowId: string;
  flow?: Flow;
}

export interface ItemSharedItem {
  uuid: string;
  itemId: string;
  item?: Item;
  /** This is deprecated. Was previously used for resolving activity feed items. */
  userContactId: string;
  userContactSource?: ContactSource;
  /** The user_id of the user the item was granted to. */
  userId: string;
  user?: User;
  accessType: ItemSharePacketAccessTypeAccessType;
  grantedByUserId: string;
  grantedByUser?: User;
  isAnonymized: boolean;
  anonymousCount: number;
  inviteType: ItemSharePacketInviteTypeInviteType;
  status: ItemSharedItemStatus;
  inviteId: string;
}

export const ItemSharedItemStatus = {
  DEFAULT: 'DEFAULT',
  REVOKED: 'REVOKED',
} as const;

export type ItemSharedItemStatus =
  typeof ItemSharedItemStatus[keyof typeof ItemSharedItemStatus];

/**
 * Represents a request to share a transaction item (example: disclosure packet)
 * with someone.
 * Note: Not all `SharedItem` instances will be created from requests - forwarding
 * shared items also results in creation of a SharedItem.
 */
export interface ItemSharedItemRequest {
  uuid: string;
  itemId: string;
  status: ItemSharedItemRequestStatus;
  userId: string;
  accessType: ItemSharePacketAccessTypeAccessType;
  requestApproveToken: string;
  requestRejectToken: string;
}

export const ItemSharedItemRequestStatus = {
  /** PENDING - Default. */
  PENDING: 'PENDING',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
} as const;

export type ItemSharedItemRequestStatus =
  typeof ItemSharedItemRequestStatus[keyof typeof ItemSharedItemRequestStatus];

export interface ItemChore {
  choreKind: ItemChoreKind;
  itemIds: string[];
  status: ItemChoreStatus;
  approveSharePacketSharedItemRequest?: ItemChoreApproveSharePacketSharedItemRequest;
  sharePacketUnpublishedChanges?: ItemChoreSharePacketUnpublishedChanges;
  reviewGfp?: ItemChoreReviewGfp;
  notifyClientGfpComments?: ItemChoreNotifyClientGfpComments;
  completeAvid?: ItemChoreCompleteAvid;
  completeSso?: ItemChoreCompleteSso;
  /** Client Chores 10100+ */
  fillGfp?: ItemChoreFillGfp;
  pickNhdProvider?: ItemChorePickNhdProvider;
  moving?: ItemChoreMoving;
  orderDpp?: ItemChoreOrderDpp;
  viewOffer?: ItemChoreViewOffer;
  /**
   * party roles who can see this chore
   * all parties can see, if set to None
   */
  allowedRoles: PartyRole[];
  /** time when status was last updated */
  statusUpdatedAt: number;
  priority: ItemChorePriority;
  didStart: boolean;
  comment?: ItemChoreComment;
  /** ids of the users for which the chore is hidden */
  hiddenForUsers: string[];
  /** Deprecated */
  unresolvedMarkup: string;
}

export const ItemChoreKind = {
  UNKNOWN: 'UNKNOWN',
  APPROVE_SHARE_PACKET_SHARED_ITEM_REQUEST:
    'APPROVE_SHARE_PACKET_SHARED_ITEM_REQUEST',
  SHARE_PACKET_UNPUBLISHED_CHANGES: 'SHARE_PACKET_UNPUBLISHED_CHANGES',
  REVIEW_GFP: 'REVIEW_GFP',
  NOTIFY_CLIENT_GFP_COMMENTS: 'NOTIFY_CLIENT_GFP_COMMENTS',
  COMPLETE_AVID: 'COMPLETE_AVID',
  COMPLETE_SSO: 'COMPLETE_SSO',
  /** FILL_GFP - Client chores 10000+ */
  FILL_GFP: 'FILL_GFP',
  PICK_NHD_PROVIDER: 'PICK_NHD_PROVIDER',
  MOVING: 'MOVING',
  ORDER_DPP: 'ORDER_DPP',
  VIEW_OFFER: 'VIEW_OFFER',
} as const;

export type ItemChoreKind = typeof ItemChoreKind[keyof typeof ItemChoreKind];

export const ItemChorePriority = {
  HIGH: 'HIGH',
  LOW: 'LOW',
  SUGGESTED: 'SUGGESTED',
} as const;

export type ItemChorePriority =
  typeof ItemChorePriority[keyof typeof ItemChorePriority];

export const ItemChoreStatus = {
  /** NO_ACTION - Default */
  NO_ACTION: 'NO_ACTION',
  ACTIVE: 'ACTIVE',
  FAILED: 'FAILED',
  INACTIVE: 'INACTIVE',
  COMPLETE: 'COMPLETE',
  COMPLETE_FINAL: 'COMPLETE_FINAL',
} as const;

export type ItemChoreStatus =
  typeof ItemChoreStatus[keyof typeof ItemChoreStatus];

export interface ItemChoreApproveSharePacketSharedItemRequest {
  sharedItemRequestId: string;
  /** Denorm */
  userId: string;
  sharePacketId: string;
  user?: User;
}

/** DEPRECATED */
export interface ItemChoreSharePacketUnpublishedChanges {
  sharePacketId: string;
  taskId: string;
}

export interface ItemChoreReviewGfp {
  packetId: string;
  gfpId: string;
  /** Deprecated */
  reviewVers: string;
  flowId: string;
  taskId: string;
  transactionId: string;
  lastSubmittedVers: string;
}

export interface ItemChoreNotifyClientGfpComments {
  gfpId: string;
  taskId: string;
  unnotifiedCommentIds: string[];
}

export interface ItemChoreCompleteAvid {
  taskId: string;
  flowId: string;
}

export interface ItemChoreFillGfp {
  taskId: string;
  gfpId: string;
  packetId: string;
}

export interface ItemChorePickNhdProvider {
  nhdId: string;
  pickNhdProviderFlowId: string;
}

export interface ItemChoreMoving {}

export interface ItemChoreOrderDpp {
  dppId: string;
  orderDppFlowId: string;
  gfpId: string;
}

export interface ItemChoreCompleteSso {
  transactionId: string;
  provider: LinkedAccountKind;
}

export interface ItemChoreViewOffer {
  transactionId: string;
  offerPackageIds: string[];
  newOfferCount: number;
  targetOfferPackageId: string;
}

export interface ItemChoreComment {
  text: string;
  partyId: string;
}

/** Represents a connection between the Transaction and a Quickbook invoice. */
export interface ItemInvoice {
  mode: ItemInvoiceMode;
  status: ItemInvoiceStatus;
  customerFirstName: string;
  customerLastName: string;
  /** overrides other names */
  customerName: string;
  customerEmail: string;
  sentDate: string;
  receivedDate: string;
  invoiceNum: string;
  amount: string;
  externalId: string;
  externalCustomerId: string;
  tdId: string;
  td?: Item;
}

export const ItemInvoiceMode = { MANUAL: 'MANUAL', AUTO: 'AUTO' } as const;

export type ItemInvoiceMode =
  typeof ItemInvoiceMode[keyof typeof ItemInvoiceMode];

export const ItemInvoiceStatus = {
  /** REQUESTED - Hasn't been sent to the customer yet. */
  REQUESTED: 'REQUESTED',
  /** GENERATED - PDF generated. */
  GENERATED: 'GENERATED',
  /** SENT - Sent to customer (ie. via email) */
  SENT: 'SENT',
  /**
   * PAID - Received full payment from customer.
   * `received_date` should be populated and `amount`.
   */
  PAID: 'PAID',
  /** VOID - Voided and no longer applicable. */
  VOID: 'VOID',
} as const;

export type ItemInvoiceStatus =
  typeof ItemInvoiceStatus[keyof typeof ItemInvoiceStatus];

export interface ItemSyncRecord {}

export interface ItemTransactionApp {
  guid: string;
  version: number;
  status: ItemTransactionAppStatus;
  type: ItemTransactionAppType;
  sellerDisclosures?: ItemTransactionAppSellerDisclosures;
  offers?: ItemTransactionAppOffers;
  timeline?: ItemTransactionAppTimeline;
  propertyPortal?: ItemTransactionAppOBSOLETEPropertyPortal;
}

export const ItemTransactionAppType = {
  UNKNOWN: 'UNKNOWN',
  SELLER_DISCLOSURES: 'SELLER_DISCLOSURES',
  OFFERS: 'OFFERS',
  TIMELINE: 'TIMELINE',
  _OBSOLETE_PROPERTY_PORTAL: '_OBSOLETE_PROPERTY_PORTAL',
} as const;

export type ItemTransactionAppType =
  typeof ItemTransactionAppType[keyof typeof ItemTransactionAppType];

export const ItemTransactionAppStatus = {
  UNINSTALLED: 'UNINSTALLED',
  _OBSOLETE_SETUP: '_OBSOLETE_SETUP',
  OK: 'OK',
} as const;

export type ItemTransactionAppStatus =
  typeof ItemTransactionAppStatus[keyof typeof ItemTransactionAppStatus];

export interface ItemTransactionAppSellerDisclosures {
  currentStepId: string;
  lastStepId: string;
  discPacketId: string;
  workflowsSflowId: string;
  OBSOLETEShowHero: boolean;
}

export interface ItemTransactionAppOBSOLETEPropertyPortal {
  uuid: string;
  mlsKind: string;
  mlsNumber: string;
}

export interface ItemTransactionAppOffers {
  offerPackageId: string;
  submissionPreferences?: ItemTransactionAppOffersSubmissionPreferences;
  clientWorkspace?: ItemTransactionAppOffersClientWorkspace;
  sourceTransactionId: string;
  /**
   * When creating a purchase transaction from a listing transaction's offer link, we need to specify which property
   * in particular that linkage applies to, to prevent any offer created outside this property to be linked to that
   * listing side transaction too
   */
  sourceTransactionPropertyInfoId: string;
  /**
   * Map of <transaction_package_id>:<OfferSummary>, used to keep track
   * of the offers inside a txn and their status to show in the UI
   */
  offersSummary: { [key: string]: ItemTransactionAppOffersOfferSummary };
  keyTermsConfigInternal?: ItemTransactionAppOffersKeyTermsConfig;
  keyTermsConfig?: ItemTransactionAppOffersKeyTermsConfig;
}

export interface ItemTransactionAppOffersSubmissionPreferences {
  buyerSideInstructions: string;
  deadlineTs: number;
  smsPhoneNumbers: string[];
  disabled: boolean;
}

export interface ItemTransactionAppOffersClientWorkspace {
  enabled: boolean;
  clientsSeen: ItemTransactionAppOffersClientWorkspaceClientSeen[];
  hideBuyersInfo: boolean;
}

export interface ItemTransactionAppOffersClientWorkspaceClientSeen {
  userId: string;
  /** List of "<offer_id>:<offer_vers>"" formatted pairs */
  seenOfferIds: string[];
  firstSeenTs: number;
  lastSeenTs: number;
}

export interface ItemTransactionAppOffersOfferSummary {
  offerPackageId: string;
  isAwaitingResponse: boolean;
  isPreparing: boolean;
  hasSignatures: boolean;
  pendingSrIds: string[];
}

export interface ItemTransactionAppOffersOffersSummaryEntry {
  key: string;
  value?: ItemTransactionAppOffersOfferSummary;
}

export interface ItemTransactionAppOffersKeyTermsConfig {
  keyTerms: ItemTransactionAppOffersKeyTermsConfigKeyTerm[];
}

export const ItemTransactionAppOffersKeyTermsConfigSummaryTerms = {
  UNKNOWN: 'UNKNOWN',
  OFFER_PRICE: 'OFFER_PRICE',
  NOTES: 'NOTES',
  FINANCING: 'FINANCING',
  CONTINGENCIES: 'CONTINGENCIES',
  DATE_SUBMITTED: 'DATE_SUBMITTED',
  DOWN_PAYMENT: 'DOWN_PAYMENT',
  INSPECTIONS_CONTINGENCY: 'INSPECTIONS_CONTINGENCY',
  APPRAISAL_CONTINGENCY: 'APPRAISAL_CONTINGENCY',
  LOAN_CONTINGENCY: 'LOAN_CONTINGENCY',
  DISCLOSURE_REVIEW_CONTINGENCY: 'DISCLOSURE_REVIEW_CONTINGENCY',
  BUYER_PROPERTY_SALE_CONTINGENCY: 'BUYER_PROPERTY_SALE_CONTINGENCY',
  CLOSE_DATE: 'CLOSE_DATE',
  EXPIRATION_DATE: 'EXPIRATION_DATE',
  /**
   * CASH - SummaryTerms for buy side term customization
   * This should be considered temporary and not finalized
   * Explanation in web/transactions/offers/logic/term_config.py
   */
  CASH: 'CASH',
  INITIAL_DEPOSIT: 'INITIAL_DEPOSIT',
  INCREASED_DEPOSIT: 'INCREASED_DEPOSIT',
  LOAN_AMOUNT: 'LOAN_AMOUNT',
  OTHER_FINANCING: 'OTHER_FINANCING',
  OTHER: 'OTHER',
} as const;

export type ItemTransactionAppOffersKeyTermsConfigSummaryTerms =
  typeof ItemTransactionAppOffersKeyTermsConfigSummaryTerms[keyof typeof ItemTransactionAppOffersKeyTermsConfigSummaryTerms];

export interface ItemTransactionAppOffersKeyTermsConfigKeyTerm {
  kind: ItemTransactionAppOffersKeyTermsConfigSummaryTerms;
  label: string;
  isVisible: boolean;
  subKeyTerms: ItemTransactionAppOffersKeyTermsConfigKeyTerm[];
}

export interface ItemTransactionAppTimeline {
  timelineItemId: string;
}

/** File Review ----------------------------------------------- */
export interface ItemFileReview {
  userId: string;
  user?: User;
  status: ItemFileReviewStatus;
  startedAt: number;
  finishedAt: number;
  message: string;
}

export const ItemFileReviewStatus = {
  UNKNOWN: 'UNKNOWN',
  IN_PROGRESS: 'IN_PROGRESS',
  FINISHED: 'FINISHED',
  CANCELLED: 'CANCELLED',
} as const;

export type ItemFileReviewStatus =
  typeof ItemFileReviewStatus[keyof typeof ItemFileReviewStatus];

export interface ItemFileReviewOp {
  kind: ItemFileReviewOpKind;
  /** only relevant to Op.REVIEW */
  status: ItemEdgeTaskAssignedTdReviewStatus;
  /** only relevant to Op.CHANGE_STATUS */
  filingStatus: ChecklistItemReviewStatusEnum;
  message: string;
  clientId: string;
  label?: Label;
}

export const ItemFileReviewOpKind = {
  UNKNOWN: 'UNKNOWN',
  REVIEW: 'REVIEW',
  CHANGE_STATUS: 'CHANGE_STATUS',
  REMOVE_FROM_REVIEW: 'REMOVE_FROM_REVIEW',
} as const;

export type ItemFileReviewOpKind =
  typeof ItemFileReviewOpKind[keyof typeof ItemFileReviewOpKind];

/** Pead ------------------------------------------------------ */
export interface ItemPead {
  /** YYYY-MM-DD */
  date: string;
  creatorId: string;
  flowId: string;
  taskId: string;
  status: ItemPeadStatus;
  isGenerated: boolean;
  addresses: ItemPeadAddress[];
  entrants: ItemPeadEntrant[];
  fields?: ItemPeadFields;
  /**
   * is_uploaded is set to True if PEAD forms are uploaded manually, and not
   * generated using Glide.
   */
  isUploaded: boolean;
  signatureRequestIds: string[];
  signatureRequests: Item[];
  sourcePeadKind: ItemPeadSourcePeadKind;
  sourcePead: string;
  completedAt: number;
  formType: ItemPeadFormType;
  splitEntrants: boolean;
}

export const ItemPeadStatus = {
  NOT_STARTED: 'NOT_STARTED',
  STARTED: 'STARTED',
  READY_TO_GENERATE: 'READY_TO_GENERATE',
  GENERATED: 'GENERATED',
  OUT_FOR_SIGNATURE: 'OUT_FOR_SIGNATURE',
  SIGNED: 'SIGNED',
  UPLOADED_FORMS: 'UPLOADED_FORMS',
} as const;

export type ItemPeadStatus = typeof ItemPeadStatus[keyof typeof ItemPeadStatus];

export const ItemPeadFormType = {
  PEAD_V: 'PEAD_V',
  PEAD_ALL: 'PEAD_ALL',
} as const;

export type ItemPeadFormType =
  typeof ItemPeadFormType[keyof typeof ItemPeadFormType];

/**
 * Ref to PEAD item that was source for this one.
 * Format <transaction_id>:<pead_item_id>:<addresses IDs> (addresses IDs is a "-"
 * joint list of address ids).
 */
export const ItemPeadSourcePeadKind = {
  UNKNOWN: 'UNKNOWN',
  /** FROM_BUYER_SIDE - PEAD copied into listing from buyer-side */
  FROM_BUYER_SIDE: 'FROM_BUYER_SIDE',
  /** FROM_CONVERTED_BUYER_PROSPECT - PEAD copied into purchase txn wehn converting prospect */
  FROM_CONVERTED_BUYER_PROSPECT: 'FROM_CONVERTED_BUYER_PROSPECT',
} as const;

export type ItemPeadSourcePeadKind =
  typeof ItemPeadSourcePeadKind[keyof typeof ItemPeadSourcePeadKind];

export interface ItemPeadAddress {
  uuid: string;
  addressId: string;
  address?: Address;
  emailListingAgent: boolean;
  listingAgent?: Contact;
  tdIds: string[];
  tds: Item[];
  tdvIds: string[];
  tdvs: Item[];
  listingTxnId: string;
}

export interface ItemPeadEntrant {
  entrantId: string;
  partyId: string;
  partyVers: string;
  party?: Party;
  contact?: Contact;
  userContactId: string;
  userContactSource?: ContactSource;
  role: ItemPeadEntrantEntrantRole;
  isPrimaryAgent: boolean;
}

export const ItemPeadEntrantEntrantRole = {
  UNKNOWN: 'UNKNOWN',
  BUYER: 'BUYER',
  TENANT_LESSEE: 'TENANT_LESSEE',
  BROKER_AGENT: 'BROKER_AGENT',
  OTHER_ENTRANT: 'OTHER_ENTRANT',
  SELLER: 'SELLER',
  LANDLORD_LESSOR: 'LANDLORD_LESSOR',
  OCCUPANT: 'OCCUPANT',
} as const;

export type ItemPeadEntrantEntrantRole =
  typeof ItemPeadEntrantEntrantRole[keyof typeof ItemPeadEntrantEntrantRole];

export interface ItemPeadFields {
  exceptionsToRepresentations: string;
}

export interface ItemCanEntry {
  key: string;
  value: boolean;
}

export interface ItemFieldStateValueRecord {
  value?: any;
  updatedTs: number;
  updatedVers: string;
}

/** Fields 900-910; */
export interface ItemFieldState {
  fieldValues: { [key: string]: ItemFieldStateValueRecord };
}

export interface ItemFieldStateFieldValuesEntry {
  key: string;
  value?: ItemFieldStateValueRecord;
}

export interface Activity {
  id: string;
  /** access sources */
  transId: string;
  flowId: string;
  itemIds: string[];
  partyId: string;
  party?: Item;
  level: ActivityLevel;
  userId: string;
  user?: User;
  kind: ActivityKind;
  log?: ActivityLogDetail;
  comment?: ActivityComment;
  diff?: ActivityDiff;
  clientId: string;
  contact?: Contact;
  avatarKind: ActivityAvatarKind;
  app: string;
  transVers: string;
  usersSeen: ActivityUserSeen[];
  createdAt: number;
  updatedAt: number;
}

export const ActivityKind = {
  UNKNOWN: 'UNKNOWN',
  CREATE: 'CREATE',
  TASK_START: 'TASK_START',
  TASK_COMPLETE: 'TASK_COMPLETE',
  FIRST_LOAD: 'FIRST_LOAD',
  INVITE_PARTIES: 'INVITE_PARTIES',
  UPDATE_PARTY_CONTACT_DETAILS: 'UPDATE_PARTY_CONTACT_DETAILS',
  PARTY_REMOVES: 'PARTY_REMOVES',
  UPDATED_TD_ARCHIVE_STATUS: 'UPDATED_TD_ARCHIVE_STATUS',
  UPDATED_TD_NOTES: 'UPDATED_TD_NOTES',
  MOVED_TD_FOLDER: 'MOVED_TD_FOLDER',
  RESTORED_TD_VERSION: 'RESTORED_TD_VERSION',
  UPLOADED_NEW_TD_VERSION: 'UPLOADED_NEW_TD_VERSION',
  CREATED_TD: 'CREATED_TD',
  SPLIT_TD: 'SPLIT_TD',
  RENAMED_TD: 'RENAMED_TD',
  /** CREATED_TASK - Tasks 100-200 */
  CREATED_TASK: 'CREATED_TASK',
  ATTACHED_TD_TO_TASK: 'ATTACHED_TD_TO_TASK',
  RENAMED_TASK: 'RENAMED_TASK',
  EDITED_TASK_NOTES: 'EDITED_TASK_NOTES',
  TASK_ASSIGNED_PARTY: 'TASK_ASSIGNED_PARTY',
  TASK_COMPLETED: 'TASK_COMPLETED',
  _OBSOLETE_TASK_SYNCED: '_OBSOLETE_TASK_SYNCED',
  /** GFP_ACCOUNT_CREATED - GFP 200-279 */
  GFP_ACCOUNT_CREATED: 'GFP_ACCOUNT_CREATED',
  GFPF_FORM_STARTED: 'GFPF_FORM_STARTED',
  GFPF_FORM_COMPLETED: 'GFPF_FORM_COMPLETED',
  GFPF_FORM_UPDATED: 'GFPF_FORM_UPDATED',
  GFP_SUBMITTED: 'GFP_SUBMITTED',
  GFP_GENERATED: 'GFP_GENERATED',
  GFP_RESUBMITTED: 'GFP_RESUBMITTED',
  GFP_UNLOCKED: 'GFP_UNLOCKED',
  PACKET_STARTED: 'PACKET_STARTED',
  GFP_RESEND_INVITE: 'GFP_RESEND_INVITE',
  GFP_SIGNED_AGREEMENT: 'GFP_SIGNED_AGREEMENT',
  GFPF_FORM_REMOVED: 'GFPF_FORM_REMOVED',
  GFP_FORMS_ADDED: 'GFP_FORMS_ADDED',
  GFP_IN_PERSON_CODE_CONFIRMED: 'GFP_IN_PERSON_CODE_CONFIRMED',
  GFP_START_INPERSON: 'GFP_START_INPERSON',
  GFP_PSD_SELLER_UPGRADED: 'GFP_PSD_SELLER_UPGRADED',
  /** DPP_PURCHASED - DPP */
  DPP_PURCHASED: 'DPP_PURCHASED',
  DPP_DECLINED: 'DPP_DECLINED',
  /** SHARED_ITEM_VIEWED - SHARE ITEMS 300-399 */
  SHARED_ITEM_VIEWED: 'SHARED_ITEM_VIEWED',
  SHARED_ITEM_SHARE_PACKET_TDV_VIEWED: 'SHARED_ITEM_SHARE_PACKET_TDV_VIEWED',
  SHARED_ITEM_SHARE_PACKET_DOWNLOADED: 'SHARED_ITEM_SHARE_PACKET_DOWNLOADED',
  SHARED_ITEM_SHARE_PACKET_SHARED: 'SHARED_ITEM_SHARE_PACKET_SHARED',
  SHARED_ITEM_SHARE_PACKET_REQUESTED_ACCESS:
    'SHARED_ITEM_SHARE_PACKET_REQUESTED_ACCESS',
  SHARED_ITEM_SHARE_PACKET_REQUEST_APPROVED:
    'SHARED_ITEM_SHARE_PACKET_REQUEST_APPROVED',
  SHARED_ITEM_SHARE_PACKET_REQUEST_REJECTED:
    'SHARED_ITEM_SHARE_PACKET_REQUEST_REJECTED',
  SHARED_ITEM_SHARE_PACKET_REMOVED_ACCESS:
    'SHARED_ITEM_SHARE_PACKET_REMOVED_ACCESS',
  SHARED_ITEM_SHARE_PACKET_LEFT_ACCESS: 'SHARED_ITEM_SHARE_PACKET_LEFT_ACCESS',
  /** EMAIL_EVENT - EMAIL EVENTS 400-449 */
  EMAIL_EVENT: 'EMAIL_EVENT',
  /** SYNC_TASK_STATUS_CHANGED - Broker Sync 450 - 499 */
  SYNC_TASK_STATUS_CHANGED: 'SYNC_TASK_STATUS_CHANGED',
  SYNC_CHECKLIST_STATUS_CHANGED: 'SYNC_CHECKLIST_STATUS_CHANGED',
  /** AVID_STARTED - AVID 500 - 549 */
  AVID_STARTED: 'AVID_STARTED',
  AVID_TC_INVITE: 'AVID_TC_INVITE',
  AVID_AGENT_INVITE: 'AVID_AGENT_INVITE',
  AVID_CREATED: 'AVID_CREATED',
  AVID_COMPLETED: 'AVID_COMPLETED',
  AVID_SCHEDULED: 'AVID_SCHEDULED',
  /** COMMENT - Flow Comments 600 - 699 */
  COMMENT: 'COMMENT',
  DIFF: 'DIFF',
  FLAG: 'FLAG',
  RESOLVED: 'RESOLVED',
  /** SIGNATURE_REQUEST_COMPLETED - Signature Request 700 - 749 */
  SIGNATURE_REQUEST_COMPLETED: 'SIGNATURE_REQUEST_COMPLETED',
  SIGNATURE_REQUEST_SENT: 'SIGNATURE_REQUEST_SENT',
  SIGNATURE_REQUEST_ARCHIVED: 'SIGNATURE_REQUEST_ARCHIVED',
  STARTED_SIGNATURE_REQUEST_REVISION: 'STARTED_SIGNATURE_REQUEST_REVISION',
  CANCELLED_SIGNATURE_REQUEST_REVISION: 'CANCELLED_SIGNATURE_REQUEST_REVISION',
  FINISHED_SIGNATURE_REQUEST_REVISION: 'FINISHED_SIGNATURE_REQUEST_REVISION',
  /** ADDED_DOCUMENTS - Documents 800 - 849 */
  ADDED_DOCUMENTS: 'ADDED_DOCUMENTS',
  /** NHD_ORDER_PLACED - MISC 850-899 */
  NHD_ORDER_PLACED: 'NHD_ORDER_PLACED',
  PICK_NHD_ORDER_SENT: 'PICK_NHD_ORDER_SENT',
  NHD_ORDER_CANCELLED: 'NHD_ORDER_CANCELLED',
  CHANGE_TRANSACTION_STATE: 'CHANGE_TRANSACTION_STATE',
  /** TOGGLE_CHECKLISTS - CHECKLISTS 900-959 */
  TOGGLE_CHECKLISTS: 'TOGGLE_CHECKLISTS',
  FINISH_FILE_REVIEW: 'FINISH_FILE_REVIEW',
  /** APPLY_TEMPLATES - TEMPLATES 960 - 979 */
  APPLY_TEMPLATES: 'APPLY_TEMPLATES',
  /** TIMELINE_STARTED - TIMELIME 990 - 1000 */
  TIMELINE_STARTED: 'TIMELINE_STARTED',
  TIMELINE_COMPLETED: 'TIMELINE_COMPLETED',
  /** TRANSACTION_PACKAGE_ACCESSED - TRANSACTION_PACKAGE 985 - 989 */
  TRANSACTION_PACKAGE_ACCESSED: 'TRANSACTION_PACKAGE_ACCESSED',
  TRANSACTION_PACKAGE_ACTION_EXECUTED: 'TRANSACTION_PACKAGE_ACTION_EXECUTED',
  TRANSACTION_PACKAGE_SENT: 'TRANSACTION_PACKAGE_SENT',
  /** COMPASS_TOS_ACCEPTED - COMPASS TOS ACCEPTANCE */
  COMPASS_TOS_ACCEPTED: 'COMPASS_TOS_ACCEPTED',
  COMPASS_TOS_DECLINED: 'COMPASS_TOS_DECLINED',
  /** CHANGE_DOCUMENT_CLIENT_VISIBILITY - CLIENT DASHBOARD */
  CHANGE_DOCUMENT_CLIENT_VISIBILITY: 'CHANGE_DOCUMENT_CLIENT_VISIBILITY',
  /** _OBSOLETE_UPDATED_TD_EXEMPT_STATUS - Obsolete activity kinds follow. */
  _OBSOLETE_UPDATED_TD_EXEMPT_STATUS: '_OBSOLETE_UPDATED_TD_EXEMPT_STATUS',
} as const;

export type ActivityKind = typeof ActivityKind[keyof typeof ActivityKind];

export const ActivityAvatarKind = {
  PARTY_AVATAR: 'PARTY_AVATAR',
  APP_AVATAR: 'APP_AVATAR',
} as const;

export type ActivityAvatarKind =
  typeof ActivityAvatarKind[keyof typeof ActivityAvatarKind];

export const ActivityLevel = {
  UNKNOWN_: 'UNKNOWN_',
  HIDDEN: 'HIDDEN',
  LOG: 'LOG',
  NOTE: 'NOTE',
} as const;

export type ActivityLevel = typeof ActivityLevel[keyof typeof ActivityLevel];

export interface ActivityLogDetail {
  unresolvedMarkup: string;
  markup: string;
  /**
   * This is deprecated. Don't change the field name because
   * we store `Activity` as JSON right now (sadly).
   */
  message: string;
}

export interface ActivityComment {
  message: string;
  unresolvedMarkup: string;
  markup: string;
  mentions: ActivityCommentMention[];
  edited: number;
  softDeleted: boolean;
}

export interface ActivityCommentMention {
  kind: ActivityCommentMentionKind;
  itemId: string;
}

export const ActivityCommentMentionKind = {
  UNKNOWN: 'UNKNOWN',
  PARTY: 'PARTY',
} as const;

export type ActivityCommentMentionKind =
  typeof ActivityCommentMentionKind[keyof typeof ActivityCommentMentionKind];

export interface ActivityDiff {
  value: string;
  prev: string;
}

export interface ActivityUserSeen {
  userId: string;
  timestamp: number;
}

export interface DispatchResponse {
  transaction?: Transaction;
  mutatedItemsById: { [key: string]: Item };
  result?: { [key: string]: any };
  clientId: string;
  isDelayed: boolean;
  /**
   * has the txn search data changed (needed for knowing if caches of querys
   * for txns is invalidated)
   */
  isTransactionReindexed: boolean;
  mutatedItemIds: string[];
  transactionId: string;
  vers: string;
  isReindexed: boolean;
}

export interface DispatchResponseMutatedItemsByIdEntry {
  key: string;
  value?: Item;
}

export interface TransactionList {
  data: Transaction[];
  cursor: string;
  total: number;
  hasMore: boolean;
}

export interface ItemList {
  data: Item[];
  cursor: string;
  total: number;
  hasMore: boolean;
}

export interface ActivityList {
  data: Activity[];
  cursor: string;
  total: number;
  hasMore: boolean;
}

export interface ActivitySummaryList {
  entries: ActivitySummaryListSummaryEntry[];
}

export interface ActivitySummaryListSummaryEntry {
  itemId: string;
  kind: ActivityKind;
  activities: ActivitySummaryListSummaryEntryActivitySeen[];
}

export interface ActivitySummaryListSummaryEntryActivitySeen {
  id: string;
  seen: boolean;
}

export interface TemplateSuggestion {
  id: string;
  title: string;
  kind: TemplateSuggestionKind;
}

export const TemplateSuggestionKind = {
  STANDARD_TEMPLATE: 'STANDARD_TEMPLATE',
  RECENT_TRANSACTION: 'RECENT_TRANSACTION',
} as const;

export type TemplateSuggestionKind =
  typeof TemplateSuggestionKind[keyof typeof TemplateSuggestionKind];

export interface TemplateSuggestionList {
  data: TemplateSuggestion[];
}

export interface Overview {
  /** 1-9 reserved for overview base attributes */
  title: string;
  /**
   * 10-29 reserved for overview sections
   * ChoreGroup required_chores = 10;
   */
  suggestedChores?: OverviewChoreGroup;
  suggestedActions?: OverviewSuggestedActionGroup;
  quickCards?: OverviewQuickAccessGroup;
  choresSummary?: OverviewChoresSummary;
  timeline?: OverviewTimeline;
}

/** chore and choreGroup are for backwards compatibility */
export interface OverviewChore {
  title: string;
  subtitle: string;
  label: string;
  iconName: string;
  actions: OverviewChoreChoreAction[];
}

export interface OverviewChoreChoreAction {
  actionName: string;
  actionArgs: { [key: string]: any };
  /** it can be full, title, primary or secondary */
  actionType: string;
  actionLabel: string;
}

export interface OverviewChoreChoreActionActionArgsEntry {
  key: string;
  value?: any;
}

export interface OverviewChoreGroup {
  title: string;
  subtitle: string;
  chores: OverviewChore[];
}

export interface OverviewSuggestedAction {
  title: string;
  subtitle: string;
  kind: string;
  image: string;
  actionName: string;
  actionArgs: { [key: string]: any };
}

export interface OverviewSuggestedActionActionArgsEntry {
  key: string;
  value?: any;
}

export interface OverviewSuggestedActionGroup {
  title: string;
  actions: OverviewSuggestedAction[];
}

export interface OverviewQuickAccessCard {
  title: string;
  details: string;
  kind: string;
  status: string;
  linkText: string;
  icon: string;
  image: string;
  actionName: string;
  actionArgs: { [key: string]: any };
}

export interface OverviewQuickAccessCardActionArgsEntry {
  key: string;
  value?: any;
}

export interface OverviewQuickAccessGroup {
  title: string;
  cards: OverviewQuickAccessCard[];
}

export interface OverviewChoresSummary {
  title: string;
  action: string;
  actionArgs: { [key: string]: any };
}

export interface OverviewChoresSummaryActionArgsEntry {
  key: string;
  value?: any;
}

export interface OverviewTimeline {
  canSetup: boolean;
  daysToClosing: number;
  detailRoute?: RouteLink;
}

export interface TransactionAccessType {}

export const TransactionAccessTypeEnum = {
  UNKNOWN: 'UNKNOWN',
  PARTY: 'PARTY',
  TEAM: 'TEAM',
  API_APP: 'API_APP',
} as const;

export type TransactionAccessTypeEnum =
  typeof TransactionAccessTypeEnum[keyof typeof TransactionAccessTypeEnum];

export interface PublicOfferSummary {
  offer?: Item;
  address?: Address;
  coverPhotoUrl: string;
  fromCompassIntegration: boolean;
}

export interface TdvFormTagsResponse {
  tdvsFormTags: TdvFormTagsResponseTdvFormTags[];
}

export interface TdvFormTagsResponseTdvFormTags {
  tdvId: string;
  formTags: string[];
}

/** Configuration for fill and sign experience */
export interface FillAndSignConfig {
  createSignatureRequest: boolean;
  fillConfigSpecifications: FillAndSignConfigFillConfigSpecification[];
  recipients: Recipient[];
  message?: FillAndSignConfigMessage;
}

export interface FillAndSignConfigFillConfigSpecification {
  formId: string;
  fillConfigId: string;
  placedTabIds: string[];
}

export interface FillAndSignConfigMessage {
  subject: string;
  body: string;
}

export interface AllowedStates {
  states: StateEnum[];
}
