import type {
  EmailList,
  EmailMessage,
  EmailPreview,
  EmailPreviewRequest,
} from 'src/types/proto/emails';
import type {
  GetEmailsByTransactionRequest,
  GetTransactionEmailRequest,
} from 'src/types/proto/services/mailer_public_service';
import type { GetTransactionCommsFileRequest } from 'src/types/proto/services/transaction_public_service';
import BaseApi from '../base-api';

export default class Comms extends BaseApi {
  fetchEmail({ transactionId, emailId }: GetTransactionEmailRequest) {
    return this.get<EmailMessage>(
      `/mailer/emails/transaction/${transactionId}/${emailId}`
    );
  }

  fetchEmails(
    transactionId: string,
    params: Omit<GetEmailsByTransactionRequest, 'transactionId'>
  ) {
    return this.get<EmailList>(`/mailer/emails/transaction/${transactionId}`, {
      params,
    });
  }

  fetchEmailPreview(data: EmailPreviewRequest) {
    return this.post<EmailPreview>('/mailer/emails/email_preview', data);
  }

  fetchFile(
    transactionId: string,
    params: Omit<GetTransactionCommsFileRequest, 'transactionId'>
  ) {
    return this.get<Blob>(`/transactions/${transactionId}/comms_file`, {
      params,
      responseType: 'blob',
    });
  }
}
